import React from 'react';
import { ParentSize } from '@visx/responsive';
import { AreaClosed, LinePath, Line } from '@visx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { AnimatedAxis } from '@visx/react-spring';
import { bisector, max, extent } from 'd3-array';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { LinearGradient } from '@visx/gradient';
import CircularProgress from '@mui/material/CircularProgress';
import { GridRows, GridColumns } from '@visx/grid';
import moment from 'moment';

const PlayerChart = ({ data, timeframe, dataKey, keyName, loading, data2, playerNames }) => {
  const [yItem, setYItem] = React.useState(0);
  const {
    tooltipOpen,
    tooltipData,
    tooltipTop,
    tooltipLeft,
    showTooltip,
    hideTooltip
  } = useTooltip();

  if (!data?.length && !loading) {
    return <p>No data available for this timeframe</p>;
  }
  const getGradientColor = (start, end, key) => {
    if(key === '_int' || key === 'sk' || key === 'inc' || key === 'incPercent'   || key === 'ltt' || key === 'lttPercent' || key === 'ckdn' || key === 'ckdnPercent' || key==="skPercent" || key==="intPercent" ) {
      return  start > end ? 'green' : start < end ? 'red' : 'grey';
    } else {
      return  end > start ? 'green' : end < start ? 'red' : 'grey';
    }
  }
  const isGame = !!data?.[0]?.index;
  return (
    <ParentSize>
      {({ width, height }) => {
        height += ((width < 1000) ? 20 : 0);
        const fontSize = width < 1000 ? width < 525 ? '12px' : '16px' : '20px';
        const isMobile = width < 1000;
        if(loading) {
          return (
            <div style={{ position: 'relative' }}>
            <div className="chart_loading_container" style={{width, height: height+ (isMobile ? 24 : 5)}}>
            <CircularProgress style={{ color: '#1A283A' }} size={'100px'} />
          </div>
          </div>
          )
        }

        const margin = { top: 20, right: width < 500 ? 10 : 50, bottom:( width < 500 ? 29 : 50), left: width < 500 ? 52 : 80 };
        const xMax = width - margin.left - margin.right;
        const yMax = height - margin.top - margin.bottom;

        const maxY1 = max(data ?? [], d => d?.[dataKey]) || 0;
        const maxY2 = max(data2 ?? [], d => d?.[dataKey]) || 0;
        const combinedMaxY = Math.max(maxY1, maxY2);

        const xScale = isGame
        ? scaleLinear({
            range: [0, xMax],
            domain: [1, Math.max(data.length, data2?.length ?? 0)], 
          })
        : scaleTime({
            range: [0, xMax],
            domain: [
              new Date(Math.min(...data.map(d => new Date(d.date)), ...(data2 ?? []).map(d => new Date(d.date)))),
              new Date(Math.max(...data.map(d => new Date(d.date)), ...(data2 ?? []).map(d => new Date(d.date))))
            ]
          });
            
        const yScale = scaleLinear({
          range: [yMax, 0],
          domain: [0, combinedMaxY]
        });

        const startScore = data?.[0]?.[dataKey];
        const endScore = data?.[data.length - 1]?.[dataKey];
        const gradientId = getGradientColor(startScore, endScore, dataKey);

        const startScore1 = data2?.[0]?.[dataKey];
        const endScore1 = data2?.[data.length - 1]?.[dataKey];
        const gradientId1 = getGradientColor(startScore1, endScore1, dataKey);

        const handleTooltip = (event) => {
          let { x, y } = localPoint(event) || { x: 0, y: 0 };
          x -= margin.left;
          y -= margin.top;
          const x0 = xScale.invert(x);
        
          let d1, d2;
          if (isGame) {
            d1 = data[Math.round(x0 - 1)];
            d2 = data2?.[Math.round(x0 - 1)];
          } else {
            const index1 = bisector(d => new Date(d.date)).left(data, x0, 1);
            const index2 = data2?.length ? bisector(d => new Date(d.date)).left(data2 ?? [], x0, 1) : -1;
        
            d1 = index1 > 0 ? data[index1 - 1] : data[0];
            d2 = data2?.length ? (index2 > 0 ? data2?.[index2 - 1] : data2?.[0]) : null;
          }
        
          if (!d1 && !d2) return;
          setYItem(y);
          showTooltip({
            tooltipData: { data1: d1, data2: d2 },
            tooltipLeft: xScale(isGame ? (d1?.index ?? d2?.index) : new Date(d1.date ?? d2?.date)),
            tooltipTop: yScale(d1?.[dataKey] ?? d2?.[dataKey]), 
          });
        };
        

        const greenFrom = '#76c89373';
        const redFrom = '#ff6f6973';
        const greyFrom = '#cccccc73';

        const green = '#11ab00'
        const red = '#c41d0e'
        const grey = '#6e6e6e'

        return (
          <div style={{ position: 'relative',  userSelect: 'none' }}>
            <svg width={width} height={height}>
              <defs>
                <LinearGradient id="green" from={greenFrom} to="#76c893" toOpacity={0.01} />
                <LinearGradient id="red" from={redFrom} to="#ff6f69" toOpacity={0.01} />
                <LinearGradient id="grey" from={greyFrom} to="#cccccc73" toOpacity={0.01} />
              </defs>
              <defs>
                <LinearGradient id="green1" from={greenFrom} to="#76c893" toOpacity={0.01} />
                <LinearGradient id="red1" from={redFrom} to="#ff6f69" toOpacity={0.01} />
                <LinearGradient id="grey1" from={greyFrom} to="#cccccc73" toOpacity={0.01} />
              </defs>
              <g transform={`translate(${margin.left}, ${margin.top})`}>
                <GridRows scale={yScale} width={xMax} strokeDasharray="2,2" stroke="#878686" />
                <GridColumns scale={xScale} height={yMax} strokeDasharray="2,2" stroke="#878686" />
                <AreaClosed
                  data={data}
                  x={d => isGame ? xScale(d?.index) : xScale(new Date(d.date))}
                  y={d => yScale(d?.[dataKey])}
                  yScale={yScale}
                  strokeWidth={2}
                  stroke={`url(#${gradientId})`}
                  fill={`url(#${gradientId})`}
                  curve={curveMonotoneX}
                />
                <LinePath
                  data={data}
                  x={d => isGame ? xScale(d?.index) : xScale(new Date(d.date))}
                  y={d => yScale(d?.[dataKey])}
                  stroke={gradientId === 'green' ? green : gradientId === 'red' ? red : grey}
                  strokeWidth={width < 525 ? 2 : 4}
                  curve={curveMonotoneX}
                />
                {data2?.length && <>
                  <LinePath
                  data={data2}
                  x={d => isGame ? xScale(d?.index) : xScale(new Date(d.date))}
                  y={d => yScale(d?.[dataKey])}
                  stroke={(gradientId1 === 'green' ? green  : gradientId1 === 'red' ? red : grey) + 'B5'}
                  strokeWidth={width < 525 ? 2 : 3}
                  strokeDasharray={width < 525 ? "3,3" : "3,5"}
                  curve={curveMonotoneX}
                />
                </>}
                <AnimatedAxis
                  numTicks={width > 1000 ? 8 : width < 525 ? 3 : 5}
                  orientation="bottom"
                  scale={xScale}
                  top={yMax}
                  tickLabelProps={() => ({ fontSize: width < 525 ? 12 :16, dx: '-1.25em' })}
                />
                <AnimatedAxis
                  numTicks={height > 1000 ? 8 : height < 525 ? 3 : 5}
                  orientation="left"
                  scale={yScale}
                  tickLabelProps={() => ({ fontSize:width < 525 ? 12 : 16, dx: '-3em', dy: '0.25em' })}
                />
               {tooltipOpen && (
                  <g>
                    {/* Vertical dashed line */}
                    <Line
                      from={{ x: tooltipLeft, y: 0 }}
                      to={{ x: tooltipLeft, y: yMax }}
                      stroke="rgba(0, 0, 0, 1)"
                      strokeWidth={2}
                      pointerEvents="none"
                      strokeDasharray="3,3"
                    />

                    {/* Circle for the first dataset */}
                   {tooltipData?.data1 && <circle
                      cx={tooltipLeft}
                      cy={tooltipData?.data1 ? yScale(tooltipData.data1[dataKey]) : null}
                      r={5}
                      fill={gradientId === 'green' ? green : gradientId === 'red' ? red : grey}
                      stroke="white"
                      strokeWidth={2}
                    />}

                    {/* Circle for the second dataset if available */}
                    {tooltipData?.data2 && (
                      <circle
                        cx={tooltipLeft}
                        cy={yScale(tooltipData.data2[dataKey])}
                        r={5}
                        fill={gradientId1 === 'green' ? green : gradientId1 === 'red' ? red : grey}
                        stroke="white"
                        strokeWidth={2}
                      />
                    )}
                  </g>
                )}

              </g>
              <rect
                x={margin.left}
                y={margin.top}
                width={xMax}
                height={yMax}
                fill="transparent"
                onMouseMove={handleTooltip}
                onMouseLeave={hideTooltip}
                onTouchMove={handleTooltip}
              />
            </svg>
            {tooltipOpen && (
              <TooltipWithBounds
              key={Math.random()}
              top={isMobile ? 0 : yItem - 12}
              left={tooltipLeft + margin.left}
              style={{
                ...defaultStyles,
                backgroundColor: 'rgba(30, 30, 30, 0.85)',
                borderRadius: '8px',
                border: '1px solid rgba(255,255,255,.7)',
                color: 'white',
                padding: '10px 15px',
                fontSize: fontSize,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {/* Title */}
                <div style={{ fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}>
                  Player Stats
                </div>
            
                {/* Divider */}
                <div style={{ height: '1px', backgroundColor: 'rgba(255,255,255,0.5)', margin: '5px 0' }} />
            
                {/* First dataset (Player 1) - Solid Line */}
                {tooltipData?.data1 && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {/* Solid line */}
                    <svg width="16" height="4">
                      <line
                        x1="0"
                        y1="2"
                        x2="16"
                        y2="2"
                        stroke={gradientId === 'green' ? green : gradientId === 'red' ? red : grey}
                        strokeWidth="3"
                      />
                    </svg>
                    <div style={{ fontSize: '12px', fontWeight: 'bold', width: '150px' }}>{playerNames[0]} ( {keyName} ):</div>
                    <div style={{ fontSize: '12px', textAlign: 'right' }}>
                      {Math.round((tooltipData?.data1?.[dataKey] ?? 0) * 100) / 100}
                    </div>
                  </div>
                )}
            
                {/* Second dataset (Player 2) - Dashed Line */}
                {tooltipData?.data2 && (
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    {/* Dashed line */}
                    <svg width="16" height="4">
                      <line
                        x1="0"
                        y1="2"
                        x2="16"
                        y2="2"
                        stroke={gradientId1 === 'green' ? green : gradientId1 === 'red' ? red : grey}
                        strokeWidth="3"
                        strokeDasharray="4,4"
                      />
                    </svg>
                    <div style={{ fontSize: '12px', fontWeight: 'bold', width: '150px'  }}>{playerNames[1]} ( {keyName} ):</div>
                    <div style={{ fontSize: '12px', textAlign: 'right' }}>
                      {Math.round((tooltipData?.data2?.[dataKey] ?? 0) * 100) / 100}
                    </div>
                  </div>
                )}
                 {/* X-Axis Value - Hovered Point */}
                <div style={{ height: '1px', backgroundColor: 'rgba(255,255,255,0.5)', margin: '5px 0' }} />
                <div style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', color: 'lightgray' }}>
                  {isGame ? `Game #${tooltipData?.data1?.index || tooltipData?.data2?.index}` : moment(tooltipData?.data1?.date || tooltipData?.data2?.date).format('MMM DD, YYYY')}
                </div>
              </div>
            </TooltipWithBounds>
            
            
            )}

          </div>
        );
      }}
    </ParentSize>
  );
};

export default PlayerChart;
