import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ConnectionCursor: { input: any; output: any; }
  DateTime: { input: any; output: any; }
};

export type AddFootlingoLessonsToFootlingoCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddLessonsToLessonCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddPlayVariantsToCoverageTypeInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddPlaysToFormationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AddRouteStencilsToRouteStencilFolderInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type AdditionalCopy = {
  __typename?: 'AdditionalCopy';
  bannerButtonText?: Maybe<Scalars['String']['output']>;
  detailButtonText?: Maybe<Scalars['String']['output']>;
  finalMessage?: Maybe<Scalars['String']['output']>;
};

export type Announcement = {
  __typename?: 'Announcement';
  additionalCopy: AdditionalCopy;
  announcementContextId?: Maybe<Scalars['ID']['output']>;
  announcementType: AnnouncementType;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  bannerImageAssetKey?: Maybe<Scalars['String']['output']>;
  bannerImageUrl?: Maybe<Scalars['String']['output']>;
  bannerSubtitle: Scalars['String']['output'];
  bannerTitle: Scalars['String']['output'];
  canAttemptDropSession: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  detailedMessage?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  drop?: Maybe<Drop>;
  dropSession?: Maybe<DropSession>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  featureImageAssetKey?: Maybe<Scalars['String']['output']>;
  featureImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  level: PlayerLevel;
  startDate: Scalars['DateTime']['output'];
  status: ContentStatus;
  subtype?: Maybe<AnnouncementSubtype>;
  subtypeContextId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnnouncementConnection = {
  __typename?: 'AnnouncementConnection';
  /** Array of edges. */
  edges: Array<AnnouncementEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AnnouncementCreateInput = {
  /** The record to create */
  announcement: AnnouncementCreateInputType;
};

export type AnnouncementCreateInputType = {
  announcementType: AnnouncementType;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  bannerImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  bannerSubtitle: Scalars['String']['input'];
  bannerTitle: Scalars['String']['input'];
  detailedMessage?: InputMaybe<Scalars['String']['input']>;
  disabled: Scalars['Boolean']['input'];
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  featureImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  level: PlayerLevel;
  startDate: Scalars['DateTime']['input'];
};

export type AnnouncementDeleteResponse = {
  __typename?: 'AnnouncementDeleteResponse';
  announcementContextId?: Maybe<Scalars['ID']['output']>;
  announcementType?: Maybe<AnnouncementType>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  bannerImageAssetKey?: Maybe<Scalars['String']['output']>;
  bannerSubtitle?: Maybe<Scalars['String']['output']>;
  bannerTitle?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  detailedMessage?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  featureImageAssetKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  level?: Maybe<PlayerLevel>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ContentStatus>;
  subtype?: Maybe<AnnouncementSubtype>;
  subtypeContextId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type AnnouncementEdge = {
  __typename?: 'AnnouncementEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Announcement */
  node: Announcement;
};

export type AnnouncementFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<AnnouncementFilter>>;
  announcementType?: InputMaybe<AnnouncementTypeFilterComparison>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  bannerSubtitle?: InputMaybe<StringFieldComparison>;
  bannerTitle?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  endDate?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  level?: InputMaybe<PlayerLevelFilterComparison>;
  or?: InputMaybe<Array<AnnouncementFilter>>;
  startDate?: InputMaybe<DateFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  subtype?: InputMaybe<AnnouncementSubtypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

/** Input for creating OR updating an Announcement.  Supply exactly one property: either `create` or `update`, never both. */
export type AnnouncementInput = {
  create?: InputMaybe<AnnouncementCreateInput>;
  update?: InputMaybe<AnnouncementUpdateInput>;
};

export type AnnouncementSort = {
  direction: SortDirection;
  field: AnnouncementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AnnouncementSortFields {
  Id = '_id',
  AnnouncementType = 'announcementType',
  ArchivedAt = 'archivedAt',
  BannerSubtitle = 'bannerSubtitle',
  BannerTitle = 'bannerTitle',
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  LastPublishedAt = 'lastPublishedAt',
  Level = 'level',
  StartDate = 'startDate',
  Status = 'status',
  Subtype = 'subtype',
  UpdatedAt = 'updatedAt'
}

/** Additional subcategory of an announcement type */
export enum AnnouncementSubtype {
  /** A general subtype */
  General = 'General',
  /** A leaderboard subtype for final results */
  Leaderboard = 'Leaderboard',
  /** A practice subtype for learning a play or coverage */
  Practice = 'Practice',
  /** A quiz subtype for scoring */
  Quiz = 'Quiz'
}

export type AnnouncementSubtypeFilterComparison = {
  eq?: InputMaybe<AnnouncementSubtype>;
  gt?: InputMaybe<AnnouncementSubtype>;
  gte?: InputMaybe<AnnouncementSubtype>;
  iLike?: InputMaybe<AnnouncementSubtype>;
  in?: InputMaybe<Array<AnnouncementSubtype>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AnnouncementSubtype>;
  lt?: InputMaybe<AnnouncementSubtype>;
  lte?: InputMaybe<AnnouncementSubtype>;
  neq?: InputMaybe<AnnouncementSubtype>;
  notILike?: InputMaybe<AnnouncementSubtype>;
  notIn?: InputMaybe<Array<AnnouncementSubtype>>;
  notLike?: InputMaybe<AnnouncementSubtype>;
};

/** Type of data associated with an announcement */
export enum AnnouncementType {
  /** An announcement associated with a single coverage type */
  Coverage = 'Coverage',
  /** An announcement associated with a lesson */
  Learning = 'Learning',
  /** An announcement associated with a single play */
  Play = 'Play',
  /** An announcement associated with a script of plays */
  Script = 'Script',
  /** A general announcement */
  Unattached = 'Unattached'
}

export type AnnouncementTypeFilterComparison = {
  eq?: InputMaybe<AnnouncementType>;
  gt?: InputMaybe<AnnouncementType>;
  gte?: InputMaybe<AnnouncementType>;
  iLike?: InputMaybe<AnnouncementType>;
  in?: InputMaybe<Array<AnnouncementType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AnnouncementType>;
  lt?: InputMaybe<AnnouncementType>;
  lte?: InputMaybe<AnnouncementType>;
  neq?: InputMaybe<AnnouncementType>;
  notILike?: InputMaybe<AnnouncementType>;
  notIn?: InputMaybe<Array<AnnouncementType>>;
  notLike?: InputMaybe<AnnouncementType>;
};

export type AnnouncementUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: AnnouncementUpdateInputType;
};

export type AnnouncementUpdateInputType = {
  announcementType?: InputMaybe<AnnouncementType>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  bannerImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  bannerSubtitle?: InputMaybe<Scalars['String']['input']>;
  bannerTitle?: InputMaybe<Scalars['String']['input']>;
  detailedMessage?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  /**
   *
   * Image asset key returned from a call to `fileUploadCreate`.
   *
   * The image should already have been uploaded via POST request to the `url` returned by `fileUploadCreate`
   */
  featureImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<PlayerLevel>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AuthRefreshTokenResponse = {
  __typename?: 'AuthRefreshTokenResponse';
  /** A JWT token issued upon successful authentication that grants access to the application. The token is valid until the expiresAt time has passed. */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** When present, indicates that an additional authentication challenge is required before the user can be authenticated */
  challengeType?: Maybe<Scalars['String']['output']>;
  /** The reason a user account has been disabled */
  disabledReason?: Maybe<UserAccountDisabledReason>;
  /** The unix timestamp, in seconds, when the issued tokens will expire. */
  expiresAt?: Maybe<Scalars['Int']['output']>;
  /** A user-friendly message describing the outcome of the authentication attempt */
  message?: Maybe<Scalars['String']['output']>;
  /** A session value required to respond to some challenge types.  If given, include in any challenge response. */
  session?: Maybe<Scalars['String']['output']>;
  status: AuthenticationResult;
};

export type AuthSignInResponse = {
  __typename?: 'AuthSignInResponse';
  /** A JWT token issued upon successful authentication that grants access to the application. The token is valid until the expiresAt time has passed. */
  accessToken?: Maybe<Scalars['String']['output']>;
  /** When present, indicates that an additional authentication challenge is required before the user can be authenticated */
  challengeType?: Maybe<Scalars['String']['output']>;
  /** The reason a user account has been disabled */
  disabledReason?: Maybe<UserAccountDisabledReason>;
  /** The users email. */
  email?: Maybe<Scalars['String']['output']>;
  /** The unix timestamp, in seconds, when the issued tokens will expire. */
  expiresAt?: Maybe<Scalars['Float']['output']>;
  /** A user-friendly message describing the outcome of the authentication attempt */
  message?: Maybe<Scalars['String']['output']>;
  /** A JWT token issued upon successful authentication that can be used to generate a new access token after the original has expired.  This token will generally have a longer lifetime than the access token.  Decode and read the `exp` property to get the exact expiration timestamp. */
  refreshToken?: Maybe<Scalars['String']['output']>;
  /** A session value required to respond to some challenge types.  If given, include in any challenge response. */
  session?: Maybe<Scalars['String']['output']>;
  status: AuthenticationResult;
  /** The users username. */
  username?: Maybe<Scalars['String']['output']>;
};

/** Authorization user groups */
export enum AuthUserGroups {
  Admins = 'ADMINS',
  Coachadmin = 'COACHADMIN',
  Coaches = 'COACHES',
  ContentEditors = 'CONTENT_EDITORS',
  Guests = 'GUESTS',
  Gurus = 'GURUS',
  Managers = 'MANAGERS',
  Players = 'PLAYERS'
}

/** A status describing the outcome for an authentication attempt */
export enum AuthenticationResult {
  /** The authentication attempt failed because the user account is disabled.  Check the disabledReason field for more details. */
  AccountDisabled = 'ACCOUNT_DISABLED',
  /** A challenge has been issued before authentication can be completed. Refer to the challenge type on how to proceed. */
  AuthChallenge = 'AUTH_CHALLENGE',
  /** The authentication attempt has failed.  Check credentials and try again. */
  AuthFail = 'AUTH_FAIL',
  /** The authentication attempt was successful and tokens have been issued. */
  AuthSuccess = 'AUTH_SUCCESS'
}

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientRequirements = {
  __typename?: 'ClientRequirements';
  passwordPolicy?: Maybe<PasswordPolicy>;
  platforms: Array<PlatformRequirement>;
  usernamePolicy?: Maybe<UsernamePolicy>;
};

export type ClientVersion = {
  __typename?: 'ClientVersion';
  buildNumber: Scalars['Int']['output'];
};

export type CoachInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  teamDifficulty?: InputMaybe<Scalars['Float']['input']>;
  teamName?: InputMaybe<Scalars['String']['input']>;
};

export type CoachOutput = {
  __typename?: 'CoachOutput';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  invitationCode: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  teamDifficulty?: Maybe<Scalars['Float']['output']>;
  username: Scalars['String']['output'];
};

/** Describes completion statuses. */
export enum CompletionStatus {
  /** The user failed to pass all the requirements. */
  Failed = 'Failed',
  /** Some required items have not been completed yet. */
  Incomplete = 'Incomplete',
  /** The user passed all the requirements. */
  Passed = 'Passed',
  /** There was no attempt by the user. */
  Unattempted = 'Unattempted'
}

export type CompositeFootlingoModuleInput = {
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  questions: Array<FootlingoLessonModuleQuestion>;
  randomizedQuestions?: InputMaybe<Scalars['Boolean']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type CompositeImageModuleInput = {
  backgroundImageAssetKey: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type CompositeModuleInput = {
  description: Scalars['String']['input'];
  imageModule?: InputMaybe<CompositeImageModuleInput>;
  moduleType: LessonModuleType;
  order?: InputMaybe<Scalars['Int']['input']>;
  playbackModule?: InputMaybe<CompositePlaybackModuleInput>;
  title: Scalars['String']['input'];
};

export type CompositePlaybackModuleInput = {
  interactive?: InputMaybe<Scalars['Boolean']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  playId?: InputMaybe<Scalars['ID']['input']>;
  sandboxPlayId: Scalars['ID']['input'];
  variantName?: InputMaybe<Scalars['String']['input']>;
};

/** Describes the status of content with respect to publishing it for user consumption. */
export enum ContentStatus {
  /** Content has been removed, is not visible to users, and is not available for editing. */
  Archived = 'ARCHIVED',
  /** Content is created but not visible to users. */
  Draft = 'DRAFT',
  /** AKA "Live" - Content is created and visible to users. */
  Published = 'PUBLISHED',
  /** Content has been validated and is ready for publishing */
  Staged = 'STAGED'
}

export type ContentStatusFilterComparison = {
  eq?: InputMaybe<ContentStatus>;
  gt?: InputMaybe<ContentStatus>;
  gte?: InputMaybe<ContentStatus>;
  iLike?: InputMaybe<ContentStatus>;
  in?: InputMaybe<Array<ContentStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ContentStatus>;
  lt?: InputMaybe<ContentStatus>;
  lte?: InputMaybe<ContentStatus>;
  neq?: InputMaybe<ContentStatus>;
  notILike?: InputMaybe<ContentStatus>;
  notIn?: InputMaybe<Array<ContentStatus>>;
  notLike?: InputMaybe<ContentStatus>;
};

export type Coverage = {
  __typename?: 'Coverage';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  players: Array<FormationPosition>;
  /** Coverage used as a reference for editing this Coverage */
  referenceCoverage?: Maybe<Coverage>;
  /** ID for a Coverage used as a reference when editing this Coverage */
  referenceCoverageId?: Maybe<Scalars['ID']['output']>;
  /** Formation used as a reference for editing this Coverage */
  referenceFormation?: Maybe<Formation>;
  /** ID for another Formation used as a reference when editing this Coverage */
  referenceFormationId?: Maybe<Scalars['ID']['output']>;
  status: ContentStatus;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<CoverageAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CoverageAggregateFilter>>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CoverageAggregateGroupBy = {
  __typename?: 'CoverageAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageAggregateResponse = {
  __typename?: 'CoverageAggregateResponse';
  count?: Maybe<CoverageCountAggregate>;
  groupBy?: Maybe<CoverageAggregateGroupBy>;
  max?: Maybe<CoverageMaxAggregate>;
  min?: Maybe<CoverageMinAggregate>;
};

export type CoverageConnection = {
  __typename?: 'CoverageConnection';
  /** Array of edges. */
  edges: Array<CoverageEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type CoverageCountAggregate = {
  __typename?: 'CoverageCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CoverageCreateInput = {
  /** The record to create */
  coverage: CoverageCreateInputType;
};

export type CoverageCreateInputType = {
  name: Scalars['String']['input'];
  players: Array<FormationPositionInput>;
  /** ID for a Coverage used as a reference when editing this Coverage */
  referenceCoverageId?: InputMaybe<Scalars['ID']['input']>;
  /** ID for another Formation used as a reference when editing this Coverage */
  referenceFormationId?: InputMaybe<Scalars['ID']['input']>;
  status: ContentStatus;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type CoverageDeleteResponse = {
  __typename?: 'CoverageDeleteResponse';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  players?: Maybe<Array<FormationPosition>>;
  /** ID for a Coverage used as a reference when editing this Coverage */
  referenceCoverageId?: Maybe<Scalars['ID']['output']>;
  /** ID for another Formation used as a reference when editing this Coverage */
  referenceFormationId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ContentStatus>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageEdge = {
  __typename?: 'CoverageEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Coverage */
  node: Coverage;
};

export type CoverageFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<CoverageFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CoverageFilter>>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CoverageMaxAggregate = {
  __typename?: 'CoverageMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageMinAggregate = {
  __typename?: 'CoverageMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageSort = {
  direction: SortDirection;
  field: CoverageSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CoverageSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CoverageType = {
  __typename?: 'CoverageType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageDrops?: Maybe<CoverageTypeCoverageDropsConnection>;
  coverageDropsAggregate: Array<CoverageTypeCoverageDropsAggregateResponse>;
  createdAt: Scalars['DateTime']['output'];
  diagramImageAssetKey?: Maybe<Scalars['String']['output']>;
  diagramImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  livePlays?: Maybe<Array<Play>>;
  name: Scalars['String']['output'];
  playVariants: CoverageTypePlayVariantsConnection;
  playVariantsAggregate: Array<CoverageTypePlayVariantsAggregateResponse>;
  playbookSlides?: Maybe<Array<PlaybookSlide>>;
  playerLevel: PlayerLevel;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  whiteboardImageAssetKey?: Maybe<Scalars['String']['output']>;
  whiteboardImageUrl?: Maybe<Scalars['String']['output']>;
};


export type CoverageTypeCoverageDropsArgs = {
  filter?: DropFilter;
  paging?: CursorPaging;
  sorting?: Array<DropSort>;
};


export type CoverageTypeCoverageDropsAggregateArgs = {
  filter?: InputMaybe<DropAggregateFilter>;
};


export type CoverageTypePlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};


export type CoverageTypePlayVariantsAggregateArgs = {
  filter?: InputMaybe<PlayVariantAggregateFilter>;
};

export type CoverageTypeAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<CoverageTypeAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CoverageTypeAggregateFilter>>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CoverageTypeAggregateGroupBy = {
  __typename?: 'CoverageTypeAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypeAggregateResponse = {
  __typename?: 'CoverageTypeAggregateResponse';
  count?: Maybe<CoverageTypeCountAggregate>;
  groupBy?: Maybe<CoverageTypeAggregateGroupBy>;
  max?: Maybe<CoverageTypeMaxAggregate>;
  min?: Maybe<CoverageTypeMinAggregate>;
};

export type CoverageTypeConnection = {
  __typename?: 'CoverageTypeConnection';
  /** Array of edges. */
  edges: Array<CoverageTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CoverageTypeCountAggregate = {
  __typename?: 'CoverageTypeCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  playerLevel?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypeCoverageDropsAggregateGroupBy = {
  __typename?: 'CoverageTypeCoverageDropsAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypeCoverageDropsAggregateResponse = {
  __typename?: 'CoverageTypeCoverageDropsAggregateResponse';
  count?: Maybe<CoverageTypeCoverageDropsCountAggregate>;
  groupBy?: Maybe<CoverageTypeCoverageDropsAggregateGroupBy>;
  max?: Maybe<CoverageTypeCoverageDropsMaxAggregate>;
  min?: Maybe<CoverageTypeCoverageDropsMinAggregate>;
};

export type CoverageTypeCoverageDropsConnection = {
  __typename?: 'CoverageTypeCoverageDropsConnection';
  /** Array of edges. */
  edges: Array<DropEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CoverageTypeCoverageDropsCountAggregate = {
  __typename?: 'CoverageTypeCoverageDropsCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  dropType?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  launchDate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  playerLevel?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypeCoverageDropsMaxAggregate = {
  __typename?: 'CoverageTypeCoverageDropsMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypeCoverageDropsMinAggregate = {
  __typename?: 'CoverageTypeCoverageDropsMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypeCreateInput = {
  /** The record to create */
  coverageType: CoverageTypeCreateInputType;
};

export type CoverageTypeCreateInputType = {
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  playbookSlides?: InputMaybe<Array<PlaybookSlideCreateInput>>;
  playerLevel: PlayerLevel;
  whiteboardImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type CoverageTypeDeleteOneInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type CoverageTypeEdge = {
  __typename?: 'CoverageTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CoverageType */
  node: CoverageType;
};

export type CoverageTypeFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<CoverageTypeFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CoverageTypeFilter>>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type CoverageTypeMaxAggregate = {
  __typename?: 'CoverageTypeMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypeMinAggregate = {
  __typename?: 'CoverageTypeMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CoverageTypePlayVariantsAggregateGroupBy = {
  __typename?: 'CoverageTypePlayVariantsAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypePlayVariantsAggregateResponse = {
  __typename?: 'CoverageTypePlayVariantsAggregateResponse';
  avg?: Maybe<CoverageTypePlayVariantsAvgAggregate>;
  count?: Maybe<CoverageTypePlayVariantsCountAggregate>;
  groupBy?: Maybe<CoverageTypePlayVariantsAggregateGroupBy>;
  max?: Maybe<CoverageTypePlayVariantsMaxAggregate>;
  min?: Maybe<CoverageTypePlayVariantsMinAggregate>;
  sum?: Maybe<CoverageTypePlayVariantsSumAggregate>;
};

export type CoverageTypePlayVariantsAvgAggregate = {
  __typename?: 'CoverageTypePlayVariantsAvgAggregate';
  variantNumber?: Maybe<Scalars['Float']['output']>;
};

export type CoverageTypePlayVariantsConnection = {
  __typename?: 'CoverageTypePlayVariantsConnection';
  /** Array of edges. */
  edges: Array<PlayVariantEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CoverageTypePlayVariantsCountAggregate = {
  __typename?: 'CoverageTypePlayVariantsCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  coverageTypeId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  playId?: Maybe<Scalars['Int']['output']>;
  predefinedTags?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  variantName?: Maybe<Scalars['Int']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypePlayVariantsMaxAggregate = {
  __typename?: 'CoverageTypePlayVariantsMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypePlayVariantsMinAggregate = {
  __typename?: 'CoverageTypePlayVariantsMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type CoverageTypePlayVariantsSumAggregate = {
  __typename?: 'CoverageTypePlayVariantsSumAggregate';
  variantNumber?: Maybe<Scalars['Float']['output']>;
};

export type CoverageTypePublishOperationMessage = {
  __typename?: 'CoverageTypePublishOperationMessage';
  code?: Maybe<PublishOperationMessageCode>;
  coverageType: CoverageType;
  coverageTypeId: Scalars['ID']['output'];
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
};

export type CoverageTypeSort = {
  direction: SortDirection;
  field: CoverageTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CoverageTypeSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  PlayerLevel = 'playerLevel',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type CoverageTypeUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: CoverageTypeUpdateInputType;
};

export type CoverageTypeUpdateInputType = {
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  playbookSlides?: InputMaybe<Array<PlaybookSlideCreateInput>>;
  playerLevel?: InputMaybe<PlayerLevel>;
  whiteboardImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type CoverageUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: CoverageUpdateInputType;
};

export type CoverageUpdateInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  players?: InputMaybe<Array<FormationPositionInput>>;
  /** ID for a Coverage used as a reference when editing this Coverage */
  referenceCoverageId?: InputMaybe<Scalars['ID']['input']>;
  /** ID for another Formation used as a reference when editing this Coverage */
  referenceFormationId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<ContentStatus>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOnePlayVariantInput = {
  /** The record to create */
  playVariant: PlayVariantCreateInput;
};

export type CreateOneSandboxPlayInput = {
  /** The record to create */
  sandboxPlay: CreateSandboxPlay;
};

export type CreateOneUserInvitationCodeInput = {
  /** The record to create */
  userInvitationCode: UserInvitationCodeCreateInputType;
};

export type CreateSandboxPlay = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  catchTimeMS?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  decisionTimeMS?: InputMaybe<Scalars['Int']['input']>;
  defensivePlayers?: InputMaybe<Array<DefensivePlayerInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether or not the QB can be sacked in the Sandbox Play */
  enableSacks?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** A field indicating whether or not this Sandbox Play can be interacted with or is playback-only */
  interactive?: InputMaybe<Scalars['Boolean']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offensivePlayers?: InputMaybe<Array<OffensivePlayerInput>>;
  playerRoutes?: InputMaybe<Array<PlayerRouteInput>>;
  /** Settings for the Players in the Sandbox Play */
  playerSettings?: InputMaybe<Array<PlayerSettingsInput>>;
  qbDrop?: InputMaybe<QbDropType>;
  qbPlacement?: InputMaybe<QbAlignmentType>;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results?: InputMaybe<Array<ReceiverOptionInput>>;
  sackTimeMS?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentStatus>;
  tags?: InputMaybe<Array<MetadataTagInput>>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateSubscriptionInput = {
  /** Callback URL */
  callBackUrl: Scalars['String']['input'];
  /** Email */
  email: Scalars['String']['input'];
  /** First Name */
  firstName: Scalars['String']['input'];
  /** Last Name */
  lastName: Scalars['String']['input'];
  /** Phone Number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Selected Plan */
  plan: Scalars['String']['input'];
  /** Platform */
  platform?: InputMaybe<Scalars['String']['input']>;
  /** Registration Code */
  registrationCode?: InputMaybe<Scalars['String']['input']>;
  /** Team Code */
  teamCode?: InputMaybe<Scalars['String']['input']>;
  /** Subscription length */
  timeframe: Scalars['String']['input'];
};

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  /** Checkout Url */
  checkoutUrl?: Maybe<Scalars['String']['output']>;
};

export type CreateTeamResponse = {
  __typename?: 'CreateTeamResponse';
  id: Scalars['String']['output'];
  /** Team name */
  name?: Maybe<Scalars['String']['output']>;
};

export type CursorPaging = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['ConnectionCursor']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Int']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** A player for the defense */
export type DefensivePlayer = PlayPlayer & {
  __typename?: 'DefensivePlayer';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  pos: Point;
  positionType: PlayerPositionType;
  rot: Rotation;
};

export type DefensivePlayerInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  pos: PointInput;
  positionType: PlayerPositionType;
  rot: RotationInput;
};

export type DeleteManyFootlingoLessonsInput = {
  /** Filter to find records to delete */
  filter: FootlingoLessonDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int']['output'];
};

export type DeleteOneAnnouncementInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneCoverageInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneEmailTemplateInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneFootlingoCategoryInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneFootlingoLessonInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneFormationInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneFormationTypeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneLessonCategoryInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneLessonInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneRouteStencilFolderInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneRouteStencilInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeleteOneUserInvitationCodeInput = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type DeviceRegistrationInput = {
  deviceId: Scalars['String']['input'];
  deviceToken: Scalars['String']['input'];
  deviceType: PlatformType;
  endpointArn?: InputMaybe<Scalars['String']['input']>;
};

export enum DownAndDistanceType {
  FirstDownAndTen = 'FIRST_DOWN_AND_TEN',
  SecondDownAndLong = 'SECOND_DOWN_AND_LONG',
  SecondDownAndMedium = 'SECOND_DOWN_AND_MEDIUM',
  SecondDownAndShort = 'SECOND_DOWN_AND_SHORT',
  ThirdDownAndLong = 'THIRD_DOWN_AND_LONG',
  ThirdDownAndMedium = 'THIRD_DOWN_AND_MEDIUM',
  ThirdDownAndMediumLong = 'THIRD_DOWN_AND_MEDIUM_LONG',
  ThirdDownAndMediumShort = 'THIRD_DOWN_AND_MEDIUM_SHORT',
  ThirdDownAndShort = 'THIRD_DOWN_AND_SHORT'
}

export type Drop = {
  __typename?: 'Drop';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dropSessions?: Maybe<Array<DropSession>>;
  dropType: DropType;
  focusCoverageType?: Maybe<CoverageType>;
  /** The focus coverage type is used for the 'Coverage' drop type. */
  focusCoverageTypeId?: Maybe<Scalars['ID']['output']>;
  focusPlay?: Maybe<Play>;
  /** The focus play is used for the 'Play' drop type. */
  focusPlayId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  playerLevel: PlayerLevel;
  postAnnouncement?: Maybe<Announcement>;
  postAnnouncementId?: Maybe<Scalars['ID']['output']>;
  preAnnouncement?: Maybe<Announcement>;
  preAnnouncementId?: Maybe<Scalars['ID']['output']>;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<DropAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dropType?: InputMaybe<DropTypeFilterComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  launchDate?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DropAggregateFilter>>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type DropAggregateGroupBy = {
  __typename?: 'DropAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropAggregateResponse = {
  __typename?: 'DropAggregateResponse';
  count?: Maybe<DropCountAggregate>;
  groupBy?: Maybe<DropAggregateGroupBy>;
  max?: Maybe<DropMaxAggregate>;
  min?: Maybe<DropMinAggregate>;
};

export type DropConnection = {
  __typename?: 'DropConnection';
  /** Array of edges. */
  edges: Array<DropEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DropCountAggregate = {
  __typename?: 'DropCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  dropType?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  launchDate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  playerLevel?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type DropCreateInput = {
  dropSessions?: InputMaybe<Array<DropSessionCreateInput>>;
  dropType: DropType;
  /** The focus coverage type is used for the 'Coverage' drop type. */
  focusCoverageTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** The focus play is used for the 'Play' drop type. */
  focusPlayId?: InputMaybe<Scalars['ID']['input']>;
  launchDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  playerLevel: PlayerLevel;
  postAnnouncement?: InputMaybe<AnnouncementCreateInputType>;
  preAnnouncement?: InputMaybe<AnnouncementCreateInputType>;
};

export type DropEdge = {
  __typename?: 'DropEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Drop */
  node: Drop;
};

export type DropFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<DropFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dropType?: InputMaybe<DropTypeFilterComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  launchDate?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DropFilter>>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type DropMaxAggregate = {
  __typename?: 'DropMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropMinAggregate = {
  __typename?: 'DropMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropPlay = {
  __typename?: 'DropPlay';
  coverageTypeName?: Maybe<Scalars['String']['output']>;
  hasMirror: Scalars['Boolean']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  playId: Scalars['ID']['output'];
  variantDescription?: Maybe<Scalars['String']['output']>;
  variantId?: Maybe<Scalars['ID']['output']>;
  variantName: Scalars['String']['output'];
  variantStatus?: Maybe<ContentStatus>;
};

export type DropPlayInput = {
  coverageTypeName?: InputMaybe<Scalars['String']['input']>;
  hasMirror: Scalars['Boolean']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  playId: Scalars['ID']['input'];
  variantDescription?: InputMaybe<Scalars['String']['input']>;
  variantId?: InputMaybe<Scalars['ID']['input']>;
  variantName: Scalars['String']['input'];
  variantStatus?: InputMaybe<ContentStatus>;
};

export type DropSession = {
  __typename?: 'DropSession';
  announcement?: Maybe<Announcement>;
  announcementId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['DateTime']['output'];
  dropSessionType: DropSessionType;
  id: Scalars['ID']['output'];
  /** Matches the number of plays in the session to designate which plays will be mirrored. */
  mirrorLayout?: Maybe<Array<Scalars['Boolean']['output']>>;
  order?: Maybe<Scalars['Int']['output']>;
  playSelectionCount?: Maybe<Scalars['Float']['output']>;
  plays?: Maybe<Array<DropPlay>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DropSessionCreateInput = {
  /** The record to create */
  dropSession: DropSessionCreateInputType;
};

export type DropSessionCreateInputType = {
  announcement?: InputMaybe<AnnouncementCreateInput>;
  dropSessionType: DropSessionType;
  order?: InputMaybe<Scalars['Int']['input']>;
  playSelectionCount?: InputMaybe<Scalars['Float']['input']>;
  plays?: InputMaybe<Array<DropPlayInput>>;
};

/** Input for creating OR updating a DropSession.  Supply exactly one property: either `create` or `update`, never both. */
export type DropSessionInput = {
  create?: InputMaybe<DropSessionCreateInput>;
  update?: InputMaybe<DropSessionUpdateInput>;
};

/** Describes all the session types that can be associated with a drop. */
export enum DropSessionType {
  /** A scripted practice session. */
  Practice = 'Practice',
  /** A scripted quiz session. */
  Quiz = 'Quiz'
}

export type DropSessionUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: DropSessionUpdateInputType;
};

export type DropSessionUpdateInputType = {
  announcement?: InputMaybe<AnnouncementInput>;
  dropSessionType?: InputMaybe<DropSessionType>;
  order?: InputMaybe<Scalars['Int']['input']>;
  playSelectionCount?: InputMaybe<Scalars['Float']['input']>;
  plays?: InputMaybe<Array<DropPlayInput>>;
};

export type DropSort = {
  direction: SortDirection;
  field: DropSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DropSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  DropType = 'dropType',
  LastPublishedAt = 'lastPublishedAt',
  LaunchDate = 'launchDate',
  Name = 'name',
  PlayerLevel = 'playerLevel',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

/** Describes all the drop types that can be created. */
export enum DropType {
  /** An indeterminate, stepped learning module explaining the fundamentals of the coverage. */
  Coverage = 'Coverage',
  /** An indeterminate, stepped learning module explaining the fundamentals of the play. */
  Play = 'Play',
  /** A scripted session with specified focus areas. */
  Script = 'Script'
}

export type DropTypeFilterComparison = {
  eq?: InputMaybe<DropType>;
  gt?: InputMaybe<DropType>;
  gte?: InputMaybe<DropType>;
  iLike?: InputMaybe<DropType>;
  in?: InputMaybe<Array<DropType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<DropType>;
  lt?: InputMaybe<DropType>;
  lte?: InputMaybe<DropType>;
  neq?: InputMaybe<DropType>;
  notILike?: InputMaybe<DropType>;
  notIn?: InputMaybe<Array<DropType>>;
  notLike?: InputMaybe<DropType>;
};

export type DropUpdateInput = {
  dropSessions?: InputMaybe<Array<DropSessionInput>>;
  dropType: DropType;
  /** The focus coverage type is used for the 'Coverage' drop type. */
  focusCoverageTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** The focus play is used for the 'Play' drop type. */
  focusPlayId?: InputMaybe<Scalars['ID']['input']>;
  launchDate?: InputMaybe<Scalars['DateTime']['input']>;
  name: Scalars['String']['input'];
  playerLevel: PlayerLevel;
  postAnnouncement?: InputMaybe<AnnouncementInput>;
  preAnnouncement?: InputMaybe<AnnouncementInput>;
};

export type EmailTemplate = {
  __typename?: 'EmailTemplate';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  templateBody: Scalars['String']['output'];
  templateSubject: Scalars['String']['output'];
  templateVariables: Array<TemplateVariable>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTemplateConnection = {
  __typename?: 'EmailTemplateConnection';
  /** Array of edges. */
  edges: Array<EmailTemplateEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type EmailTemplateCreateInput = {
  /** The record to create */
  emailTemplate: EmailTemplateCreateInputType;
};

export type EmailTemplateCreateInputType = {
  name: Scalars['String']['input'];
  templateBody: Scalars['String']['input'];
  templateSubject: Scalars['String']['input'];
  templateVariables: Array<TemplateVariableInput>;
};

export type EmailTemplateDeleteResponse = {
  __typename?: 'EmailTemplateDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  templateBody?: Maybe<Scalars['String']['output']>;
  templateSubject?: Maybe<Scalars['String']['output']>;
  templateVariables?: Maybe<Array<TemplateVariable>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type EmailTemplateEdge = {
  __typename?: 'EmailTemplateEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the EmailTemplate */
  node: EmailTemplate;
};

export type EmailTemplateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<EmailTemplateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EmailTemplateFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type EmailTemplateSort = {
  direction: SortDirection;
  field: EmailTemplateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EmailTemplateSortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type EmailTemplateUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: EmailTemplateUpdateInputType;
};

export type EmailTemplateUpdateInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  templateBody?: InputMaybe<Scalars['String']['input']>;
  templateSubject?: InputMaybe<Scalars['String']['input']>;
  templateVariables?: InputMaybe<Array<TemplateVariableInput>>;
};

export type FileUpload = {
  __typename?: 'FileUpload';
  fields: Array<FormField>;
  url: Scalars['String']['output'];
};

/** The category of a file being uploaded */
export enum FileUploadCategory {
  Audio = 'Audio',
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export type FootlingoCategory = {
  __typename?: 'FootlingoCategory';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  footlingoLessons: FootlingoCategoryFootlingoLessonsConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type FootlingoCategoryFootlingoLessonsArgs = {
  filter?: FootlingoLessonFilter;
  paging?: CursorPaging;
  sorting?: Array<FootlingoLessonSort>;
};

export type FootlingoCategoryConnection = {
  __typename?: 'FootlingoCategoryConnection';
  /** Array of edges. */
  edges: Array<FootlingoCategoryEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FootlingoCategoryDeleteResponse = {
  __typename?: 'FootlingoCategoryDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FootlingoCategoryEdge = {
  __typename?: 'FootlingoCategoryEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the FootlingoCategory */
  node: FootlingoCategory;
};

export type FootlingoCategoryFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FootlingoCategoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<FootlingoCategoryFilter>>;
  sortOrder?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FootlingoCategoryFootlingoLessonsConnection = {
  __typename?: 'FootlingoCategoryFootlingoLessonsConnection';
  /** Array of edges. */
  edges: Array<FootlingoLessonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FootlingoCategorySort = {
  direction: SortDirection;
  field: FootlingoCategorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FootlingoCategorySortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  SortOrder = 'sortOrder',
  UpdatedAt = 'updatedAt'
}

export type FootlingoLesson = {
  __typename?: 'FootlingoLesson';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  footlingoLessonProgress?: Maybe<FootlingoProgress>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  lessonCategory?: Maybe<Scalars['ID']['output']>;
  lessonLevels: Array<LessonLevel>;
  modules: Array<FootlingoLessonModule>;
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FootlingoLessonConnection = {
  __typename?: 'FootlingoLessonConnection';
  /** Array of edges. */
  edges: Array<FootlingoLessonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FootlingoLessonCreateInput = {
  /** The record to create */
  footlingo: FootlingoLessonInput;
};

export type FootlingoLessonDeleteFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FootlingoLessonDeleteFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<FootlingoLessonDeleteFilter>>;
  sortOrder?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FootlingoLessonDeleteResponse = {
  __typename?: 'FootlingoLessonDeleteResponse';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  lessonCategory?: Maybe<Scalars['ID']['output']>;
  lessonLevels?: Maybe<Array<LessonLevel>>;
  modules?: Maybe<Array<FootlingoLessonModule>>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FootlingoLessonEdge = {
  __typename?: 'FootlingoLessonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the FootlingoLesson */
  node: FootlingoLesson;
};

export type FootlingoLessonFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FootlingoLessonFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<FootlingoLessonFilter>>;
  sortOrder?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FootlingoLessonInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lessonCategory?: InputMaybe<Scalars['ID']['input']>;
  lessonLevels: Array<LessonLevel>;
  modules?: InputMaybe<Array<CompositeFootlingoModuleInput>>;
  name: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  status: ContentStatus;
};

export type FootlingoLessonModule = {
  __typename?: 'FootlingoLessonModule';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  questions: Array<FootlingoLessonModuleQuestionOutput>;
  randomizedQuestions?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FootlingoLessonModuleCompletedInput = {
  moduleId: Scalars['ID']['input'];
  result: FootlingoLessonModuleResult;
};

export type FootlingoLessonModuleQuestion = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  matchingPairs?: InputMaybe<Array<LessonModuleQuestionMatchingPairsInput>>;
  multipleChoice?: InputMaybe<LessonModuleQuestionMultipleChoiceInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  questionCount?: InputMaybe<LessonModuleQuestionCountType>;
  questionType: LessonModuleQuestionType;
  title: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type FootlingoLessonModuleQuestionMatchingPairsCreateInput = {
  backgroundImageAssetKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  lesson: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type FootlingoLessonModuleQuestionMultipleChoiceCreateInput = {
  description: Scalars['String']['input'];
  lesson: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  questionImageAssetKey: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type FootlingoLessonModuleQuestionOutput = {
  __typename?: 'FootlingoLessonModuleQuestionOutput';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  matchingPairs?: Maybe<Array<LessonModuleQuestionMatchingPairs>>;
  multipleChoice?: Maybe<LessonModuleQuestionMultipleChoice>;
  order?: Maybe<Scalars['Int']['output']>;
  questionCount?: Maybe<LessonModuleQuestionCountType>;
  questionType: LessonModuleQuestionType;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FootlingoLessonModuleQuestionResponse = {
  __typename?: 'FootlingoLessonModuleQuestionResponse';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  matchingPairs?: Maybe<Array<LessonModuleQuestionMatchingPairs>>;
  multipleChoice?: Maybe<LessonModuleQuestionMultipleChoice>;
  order?: Maybe<Scalars['Int']['output']>;
  questionCount?: Maybe<LessonModuleQuestionCountType>;
  questionType: LessonModuleQuestionType;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FootlingoLessonModuleResponse = {
  __typename?: 'FootlingoLessonModuleResponse';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  questions: Array<FootlingoLessonModuleQuestionResponse>;
  randomizedQuestions?: Maybe<Scalars['Boolean']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

/** The result for a Lesson Module completed by a Player */
export enum FootlingoLessonModuleResult {
  /** The module was completed with too many incorrect answer(s) */
  Failed = 'FAILED',
  /** The module was completed with a sufficient number of correct answer(s) */
  Passed = 'PASSED'
}

export type FootlingoLessonResponse = {
  __typename?: 'FootlingoLessonResponse';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lessonCategory: Scalars['String']['output'];
  lessonLevels: Array<LessonLevel>;
  modules: Array<FootlingoLessonModuleResponse>;
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FootlingoLessonSort = {
  direction: SortDirection;
  field: FootlingoLessonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FootlingoLessonSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  SortOrder = 'sortOrder',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type FootlingoLessonUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: FootlingoLessonUpdateInputType;
};

export type FootlingoLessonUpdateInputType = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lessonCategory?: InputMaybe<Scalars['ID']['input']>;
  lessonLevels?: InputMaybe<Array<LessonLevel>>;
  modules?: InputMaybe<Array<CompositeFootlingoModuleInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentStatus>;
};

export type FootlingoProgress = {
  __typename?: 'FootlingoProgress';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lessonId: Scalars['ID']['output'];
  materialsCompletion: CompletionStatus;
  materialsFailed: Array<Scalars['ID']['output']>;
  materialsPassed: Array<Scalars['ID']['output']>;
  overallCompletion: CompletionStatus;
  quizMaxDailyAttemptsAllowed: Scalars['Int']['output'];
  quizScoringCriteria: FootlingoQuizScoringCriteria;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type FootlingoQuizScoringCriteria = {
  __typename?: 'FootlingoQuizScoringCriteria';
  starOneRequiredOptimalDecisions: Scalars['Int']['output'];
  starThreeRequiredOptimalDecisions: Scalars['Int']['output'];
  starThreeRequiredPerfectDecisions: Scalars['Int']['output'];
  starTwoRequiredOptimalDecisions: Scalars['Int']['output'];
  starTwoRequiredPerfectDecisions: Scalars['Int']['output'];
};

export type FootlingoTreeResponse = {
  __typename?: 'FootlingoTreeResponse';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lessons: Array<FootlingoLessonResponse>;
  name: Scalars['String']['output'];
};

export type ForgotPassword = {
  __typename?: 'ForgotPassword';
  code: Scalars['String']['output'];
  expiresAt: Scalars['DateTime']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Formation = {
  __typename?: 'Formation';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  players: Array<FormationPosition>;
  qbAlignment: QbAlignmentType;
  qbDrop: QbDropType;
  /** Coverage used as a reference for editing this Formation */
  referenceCoverage?: Maybe<Coverage>;
  /** ID for a Coverage used as a reference when editing this Formation */
  referenceCoverageId?: Maybe<Scalars['ID']['output']>;
  /** Formation used as a reference for editing this Formation */
  referenceFormation?: Maybe<Formation>;
  /** ID for another Formation used as a reference when editing this Formation */
  referenceFormationId?: Maybe<Scalars['ID']['output']>;
  status: ContentStatus;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FormationAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FormationAggregateFilter>>;
  qbAlignment?: InputMaybe<QbAlignmentTypeFilterComparison>;
  qbDrop?: InputMaybe<QbDropTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FormationAggregateGroupBy = {
  __typename?: 'FormationAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbAlignment?: Maybe<QbAlignmentType>;
  qbDrop?: Maybe<QbDropType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationAggregateResponse = {
  __typename?: 'FormationAggregateResponse';
  count?: Maybe<FormationCountAggregate>;
  groupBy?: Maybe<FormationAggregateGroupBy>;
  max?: Maybe<FormationMaxAggregate>;
  min?: Maybe<FormationMinAggregate>;
};

export type FormationConnection = {
  __typename?: 'FormationConnection';
  /** Array of edges. */
  edges: Array<FormationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type FormationCountAggregate = {
  __typename?: 'FormationCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  qbAlignment?: Maybe<Scalars['Int']['output']>;
  qbDrop?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type FormationCreateInput = {
  /** The record to create */
  formation: FormationCreateInputType;
};

export type FormationCreateInputType = {
  name: Scalars['String']['input'];
  players: Array<FormationPositionInput>;
  qbAlignment: QbAlignmentType;
  qbDrop: QbDropType;
  /** ID for a Coverage used as a reference when editing this Formation */
  referenceCoverageId?: InputMaybe<Scalars['ID']['input']>;
  /** ID for another Formation used as a reference when editing this Formation */
  referenceFormationId?: InputMaybe<Scalars['ID']['input']>;
  status: ContentStatus;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type FormationDeleteResponse = {
  __typename?: 'FormationDeleteResponse';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  players?: Maybe<Array<FormationPosition>>;
  qbAlignment?: Maybe<QbAlignmentType>;
  qbDrop?: Maybe<QbDropType>;
  /** ID for a Coverage used as a reference when editing this Formation */
  referenceCoverageId?: Maybe<Scalars['ID']['output']>;
  /** ID for another Formation used as a reference when editing this Formation */
  referenceFormationId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<ContentStatus>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationEdge = {
  __typename?: 'FormationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Formation */
  node: Formation;
};

export type FormationFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FormationFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FormationFilter>>;
  qbAlignment?: InputMaybe<QbAlignmentTypeFilterComparison>;
  qbDrop?: InputMaybe<QbDropTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FormationMaxAggregate = {
  __typename?: 'FormationMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbAlignment?: Maybe<QbAlignmentType>;
  qbDrop?: Maybe<QbDropType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationMinAggregate = {
  __typename?: 'FormationMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbAlignment?: Maybe<QbAlignmentType>;
  qbDrop?: Maybe<QbDropType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationPosition = {
  __typename?: 'FormationPosition';
  pos: Point;
  positionType: PlayerPositionType;
  rot: Rotation;
};

export type FormationPositionInput = {
  pos: PointInput;
  positionType: PlayerPositionType;
  rot: RotationInput;
};

export type FormationSort = {
  direction: SortDirection;
  field: FormationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FormationSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  QbAlignment = 'qbAlignment',
  QbDrop = 'qbDrop',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type FormationType = {
  __typename?: 'FormationType';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  diagramImageAssetKey?: Maybe<Scalars['String']['output']>;
  diagramImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  leftName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  plays: FormationTypePlaysConnection;
  status: ContentStatus;
  totalPlayVariants: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type FormationTypePlaysArgs = {
  filter?: PlayFilter;
  paging?: CursorPaging;
  sorting?: Array<PlaySort>;
};

export type FormationTypeConnection = {
  __typename?: 'FormationTypeConnection';
  /** Array of edges. */
  edges: Array<FormationTypeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FormationTypeCreateInput = {
  /** The record to create */
  formationType: FormationTypeCreateInputType;
};

export type FormationTypeCreateInputType = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  leftName?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  status: ContentStatus;
};

export type FormationTypeDeleteResponse = {
  __typename?: 'FormationTypeDeleteResponse';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  diagramImageAssetKey?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  leftName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FormationTypeEdge = {
  __typename?: 'FormationTypeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the FormationType */
  node: FormationType;
};

export type FormationTypeFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<FormationTypeFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  leftName?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FormationTypeFilter>>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type FormationTypePlaysConnection = {
  __typename?: 'FormationTypePlaysConnection';
  /** Array of edges. */
  edges: Array<PlayEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FormationTypePublishOperationMessage = {
  __typename?: 'FormationTypePublishOperationMessage';
  code?: Maybe<PublishOperationMessageCode>;
  formationType: FormationType;
  formationTypeId: Scalars['ID']['output'];
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
};

export type FormationTypeSort = {
  direction: SortDirection;
  field: FormationTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FormationTypeSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  LeftName = 'leftName',
  Name = 'name',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type FormationTypeUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: FormationTypeUpdateInputType;
};

export type FormationTypeUpdateInputType = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  leftName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContentStatus>;
};

export type FormationUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: FormationUpdateInputType;
};

export type FormationUpdateInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
  players?: InputMaybe<Array<FormationPositionInput>>;
  qbAlignment?: InputMaybe<QbAlignmentType>;
  qbDrop?: InputMaybe<QbDropType>;
  /** ID for a Coverage used as a reference when editing this Formation */
  referenceCoverageId?: InputMaybe<Scalars['ID']['input']>;
  /** ID for another Formation used as a reference when editing this Formation */
  referenceFormationId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<ContentStatus>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type GameModeArgs = {
  __typename?: 'GameModeArgs';
  /** The ID of a specific script, play, or coverage drop. */
  dropId?: Maybe<Scalars['ID']['output']>;
  /** The ID of a specific drop session to create the game session plays from. */
  dropSessionId?: Maybe<Scalars['ID']['output']>;
  /** The ID of a specific lesson. */
  lessonId?: Maybe<Scalars['ID']['output']>;
  /** The ID of a specific coverage type to practice. */
  practiceCoverageTypeId?: Maybe<Scalars['ID']['output']>;
  /** The ID of a specific play to practice. */
  practicePlayId?: Maybe<Scalars['ID']['output']>;
};

export type GameModeArgsInput = {
  /** The ID of a specific script, play, or coverage drop. */
  dropId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of a specific drop session to create the game session plays from. */
  dropSessionId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of a specific lesson. */
  lessonId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of a specific coverage type to practice. */
  practiceCoverageTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** The ID of a specific play to practice. */
  practicePlayId?: InputMaybe<Scalars['ID']['input']>;
};

/** Mode of play for a game session */
export enum GamePlayMode {
  College = 'College',
  /** Play against a new coverage type "dropped" by the game administrator */
  CoverageDrop = 'CoverageDrop',
  HighSchool = 'HighSchool',
  /** Play through a series of plays generated by a lesson quiz */
  LessonQuiz = 'LessonQuiz',
  /** Play against a new play type "dropped" by the game administrator */
  PlayDrop = 'PlayDrop',
  /** Game play mode where play performance does not count against a player's ranking score */
  Practice = 'Practice',
  Pro = 'Pro',
  /** Play against a curated set of plays "dropped" by the game administrator */
  ScriptDrop = 'ScriptDrop',
  /** Standard game play mode */
  Standard = 'Standard',
  /** Endless gameplay mode where player is required to make correct decisions to continue playing */
  Survivor = 'Survivor',
  Youth = 'Youth'
}

export type GameSession = {
  __typename?: 'GameSession';
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  extraDecisionResults?: Maybe<Array<GameSessionPlayDecision>>;
  id: Scalars['ID']['output'];
  mode: GamePlayMode;
  modeArgs?: Maybe<GameModeArgs>;
  plays: Array<GameSessionPlay>;
  ranking: RankSnapshot;
  score?: Maybe<Scalars['Float']['output']>;
  scoringModifiers: ScoringModifiers;
  speedDifficulty?: Maybe<Scalars['Float']['output']>;
  status: GameSessionStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type GameSessionCreateInput = {
  mode: GamePlayMode;
  modeArgs?: InputMaybe<GameModeArgsInput>;
  speedDifficulty?: InputMaybe<Scalars['Float']['input']>;
};

export type GameSessionEndInput = {
  extraDecisions?: InputMaybe<Array<GameSessionPlayDecisionInput>>;
  gameSessionId: Scalars['String']['input'];
  status: GameSessionStatus;
};

export type GameSessionExtendResponse = {
  __typename?: 'GameSessionExtendResponse';
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  mode: GamePlayMode;
  modeArgs?: Maybe<GameModeArgs>;
  newPlays: Array<GameSessionPlay>;
  plays: Array<GameSessionPlay>;
  score?: Maybe<Scalars['Float']['output']>;
  speedDifficulty?: Maybe<Scalars['Float']['output']>;
  status: GameSessionStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type GameSessionPlay = {
  __typename?: 'GameSessionPlay';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  decision?: Maybe<GameSessionPlayDecision>;
  /** Identifies which down-and-distance situations this play is applicable for */
  downAndDistance?: Maybe<PlayDownAndDistanceTags>;
  drop: QbDropType;
  formationType?: Maybe<FormationType>;
  formationTypeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  offensivePlayers: Array<OffensivePlayer>;
  playId: Scalars['String']['output'];
  playName: Scalars['String']['output'];
  protection?: Maybe<ProtectionType>;
  qbPlacement: QbAlignmentType;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variant: GameSessionPlayVariant;
  version: Scalars['Int']['output'];
};

export type GameSessionPlayDecision = {
  __typename?: 'GameSessionPlayDecision';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  decisionTimeMS?: Maybe<Scalars['Int']['output']>;
  outcome: PlayDecisionOutcome;
  score: Scalars['Float']['output'];
  selectedReceiverId?: Maybe<Scalars['String']['output']>;
};

export type GameSessionPlayDecisionInput = {
  decisionTimeMS?: InputMaybe<Scalars['Int']['input']>;
  gameSessionPlayId: Scalars['String']['input'];
  optimalStreak?: InputMaybe<Scalars['Int']['input']>;
  sacked?: InputMaybe<Scalars['Boolean']['input']>;
  selectedReceiverId?: InputMaybe<Scalars['String']['input']>;
  sessionId: Scalars['String']['input'];
};

export type GameSessionPlayVariant = {
  __typename?: 'GameSessionPlayVariant';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  catchTimeMS: Scalars['Int']['output'];
  coverageType?: Maybe<CoverageType>;
  coverageTypeId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  decisionTimeMS: Scalars['Int']['output'];
  defensivePlayers: Array<DefensivePlayer>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  mirrored: Scalars['Boolean']['output'];
  playId: Scalars['ID']['output'];
  playVersion: Scalars['Int']['output'];
  playerRoutes: Array<PlayerRoute>;
  /** Variant-specific settings for the Players in the variant */
  playerSettings: Array<PlayerSettings>;
  predefinedTags: PlayVariantPredefinedTags;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results: Array<ReceiverOption>;
  sackTimeMS: Scalars['Int']['output'];
  status: ContentStatus;
  tags: Array<MetadataTag>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantDescription: Scalars['String']['output'];
  variantName: Scalars['String']['output'];
  variantNumber: Scalars['Int']['output'];
};

/** Status of a Game Session */
export enum GameSessionStatus {
  /** Play has ended before all required plays were completed */
  Abandoned = 'Abandoned',
  /** Play has concluded normally for this session */
  Ended = 'Ended',
  /** Game Session has been created and at least one result has been recorded from session play */
  InProgress = 'InProgress',
  /** Game Session has been created, but no results have been recorded yet. */
  New = 'New',
  /** Play has concluded automatically due to a lack of new results being recorded within a configured time period for this session. */
  TimedOut = 'TimedOut'
}

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type ImageLessonModule = LessonModule & {
  __typename?: 'ImageLessonModule';
  backgroundImageAssetKey: Scalars['String']['output'];
  backgroundImageUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  moduleType: LessonModuleType;
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ImageLessonModuleCreateInput = {
  backgroundImageAssetKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  lesson: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

/** Color combinations for jerseys in player customization */
export enum JerseyColor {
  BlackBluestripe = 'BLACK_BLUESTRIPE',
  BlueYellowstripe = 'BLUE_YELLOWSTRIPE',
  GreenBluestripe = 'GREEN_BLUESTRIPE',
  OrangeBluestripe = 'ORANGE_BLUESTRIPE',
  PurpleGraystripe = 'PURPLE_GRAYSTRIPE',
  RedBlackstripe = 'RED_BLACKSTRIPE',
  YellowPurplestripe = 'YELLOW_PURPLESTRIPE'
}

export type Leaderboard = {
  __typename?: 'Leaderboard';
  defaultRankOffsetInfo: RankOffsetInfo;
  entries: LeaderboardEntryOffsetConnection;
  rankSnapshot: RankSnapshot;
};


export type LeaderboardEntriesArgs = {
  paging?: OffsetPaging;
};

export type LeaderboardEntry = {
  __typename?: 'LeaderboardEntry';
  boardVersion: Scalars['DateTime']['output'];
  createdAt: Scalars['DateTime']['output'];
  displayName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  playerCustomization?: Maybe<PlayerCustomization>;
  rank: Scalars['Int']['output'];
  score?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LeaderboardEntryOffsetConnection = {
  __typename?: 'LeaderboardEntryOffsetConnection';
  /** Array of nodes. */
  nodes: Array<LeaderboardEntry>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type LeaderboardMetrics = {
  __typename?: 'LeaderboardMetrics';
  cumulativeDecisionTime?: Maybe<Scalars['Int']['output']>;
  standardScore?: Maybe<Scalars['Int']['output']>;
  survivorStreak?: Maybe<Scalars['Int']['output']>;
};

export type LeaderboardSummary = {
  __typename?: 'LeaderboardSummary';
  entries: Array<LeaderboardEntry>;
  leaderboardStandings: Array<UserLeaderboardStanding>;
  leaders: Array<UserRankDetails>;
};

export type Lesson = {
  __typename?: 'Lesson';
  announcementId?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  lessonCategory?: Maybe<LessonCategory>;
  lessonProgress?: Maybe<LessonProgress>;
  level: PlayerLevel;
  modules: Array<LessonModule>;
  name: Scalars['String']['output'];
  quiz?: Maybe<LessonQuiz>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonCategory = {
  __typename?: 'LessonCategory';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lessons: LessonCategoryLessonsConnection;
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type LessonCategoryLessonsArgs = {
  filter?: LessonFilter;
  paging?: CursorPaging;
  sorting?: Array<LessonSort>;
};

export type LessonCategoryConnection = {
  __typename?: 'LessonCategoryConnection';
  /** Array of edges. */
  edges: Array<LessonCategoryEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LessonCategoryCreateInput = {
  /** The record to create */
  lessonCategory: LessonCategoryCreateInputType;
};

export type LessonCategoryCreateInputType = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type LessonCategoryDeleteResponse = {
  __typename?: 'LessonCategoryDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonCategoryEdge = {
  __typename?: 'LessonCategoryEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the LessonCategory */
  node: LessonCategory;
};

export type LessonCategoryFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<LessonCategoryFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<LessonCategoryFilter>>;
  sortOrder?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type LessonCategoryLessonsConnection = {
  __typename?: 'LessonCategoryLessonsConnection';
  /** Array of edges. */
  edges: Array<LessonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LessonCategorySort = {
  direction: SortDirection;
  field: LessonCategorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LessonCategorySortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  SortOrder = 'sortOrder',
  UpdatedAt = 'updatedAt'
}

export type LessonCategoryUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: LessonCategoryUpdateInputType;
};

export type LessonCategoryUpdateInputType = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
};

export type LessonConnection = {
  __typename?: 'LessonConnection';
  /** Array of edges. */
  edges: Array<LessonEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LessonCreateInput = {
  /** The record to create */
  lesson: LessonInput;
};

export type LessonDeleteResponse = {
  __typename?: 'LessonDeleteResponse';
  announcementId?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  lessonCategory?: Maybe<Scalars['ID']['output']>;
  level?: Maybe<PlayerLevel>;
  modules?: Maybe<Array<LessonModule>>;
  name?: Maybe<Scalars['String']['output']>;
  quiz?: Maybe<LessonQuiz>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonEdge = {
  __typename?: 'LessonEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Lesson */
  node: Lesson;
};

export type LessonFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<LessonFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<LessonFilter>>;
  sortOrder?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type LessonInput = {
  announcementId?: InputMaybe<Scalars['ID']['input']>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  launchDate?: InputMaybe<Scalars['DateTime']['input']>;
  lessonCategory: Scalars['ID']['input'];
  level: PlayerLevel;
  modules?: InputMaybe<Array<CompositeModuleInput>>;
  name: Scalars['String']['input'];
  quiz?: InputMaybe<LessonQuizCreateInput>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  status: ContentStatus;
};

/** Player difficulty level for lesson play */
export enum LessonLevel {
  /** College */
  College = 'COLLEGE',
  /** High School */
  Highschool = 'HIGHSCHOOL',
  /** PRO */
  Pro = 'PRO',
  /** YOUTH */
  Youth = 'YOUTH'
}

export type LessonModule = {
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  moduleType: LessonModuleType;
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type LessonModuleCompletedInput = {
  moduleId: Scalars['ID']['input'];
  result: LessonModuleResult;
};

/** Number of answers in a lesson module question, which could be 1, 2, 3, or 4. */
export enum LessonModuleQuestionCountType {
  /** A lesson module with 4 answers */
  Four = 'FOUR',
  /** A lesson module with 1 answer */
  One = 'ONE',
  /** A lesson module with 3 answers */
  Three = 'THREE',
  /** A lesson module with 2 answers */
  Two = 'TWO'
}

export type LessonModuleQuestionMatchingPairs = {
  __typename?: 'LessonModuleQuestionMatchingPairs';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageLocation?: Maybe<Scalars['String']['output']>;
  imageText: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonModuleQuestionMatchingPairsInput = {
  imageLocation?: InputMaybe<Scalars['String']['input']>;
  imageText: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LessonModuleQuestionMultipleChoice = {
  __typename?: 'LessonModuleQuestionMultipleChoice';
  answer?: Maybe<Scalars['String']['output']>;
  imageLocation?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  mistakeOne?: Maybe<Scalars['String']['output']>;
  mistakeThree?: Maybe<Scalars['String']['output']>;
  mistakeTwo?: Maybe<Scalars['String']['output']>;
};

export type LessonModuleQuestionMultipleChoiceInput = {
  answer?: InputMaybe<Scalars['String']['input']>;
  imageLocation?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  mistakeOne?: InputMaybe<Scalars['String']['input']>;
  mistakeThree?: InputMaybe<Scalars['String']['input']>;
  mistakeTwo?: InputMaybe<Scalars['String']['input']>;
};

/** Type of lesson module, which dictates the type of visuals that will be used as a part of the module. */
export enum LessonModuleQuestionType {
  /** A lesson module that uses images for visuals */
  MatchingPairs = 'Matching_Pairs',
  /** A lesson module that uses non-interactive game play as a visual */
  MultipleChoice = 'Multiple_Choice'
}

/** The result for a Lesson Module completed by a Player */
export enum LessonModuleResult {
  /** The module was completed with too many incorrect answer(s) */
  Failed = 'FAILED',
  /** The module was completed with a sufficient number of correct answer(s) */
  Passed = 'PASSED'
}

/** Type of lesson module, which dictates the type of visuals that will be used as a part of the module. */
export enum LessonModuleType {
  /** A lesson module that uses images for visuals */
  Image = 'Image',
  /** A lesson module that uses interactive game play as a visual */
  Interactive = 'Interactive',
  /** A lesson module that uses non-interactive game play as a visual */
  Playback = 'Playback'
}

export type LessonProgress = {
  __typename?: 'LessonProgress';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  lessonId: Scalars['ID']['output'];
  materialsCompletion: CompletionStatus;
  materialsFailed: Array<Scalars['ID']['output']>;
  materialsPassed: Array<Scalars['ID']['output']>;
  overallCompletion: CompletionStatus;
  quizAvailability: QuizAvailability;
  quizBestScoreDetails?: Maybe<LessonQuizScore>;
  quizBestSessionId?: Maybe<Scalars['ID']['output']>;
  quizCompletion: CompletionStatus;
  quizDailyAttempts: Scalars['Int']['output'];
  quizLastAttemptedAt?: Maybe<Scalars['DateTime']['output']>;
  quizMaxDailyAttemptsAllowed: Scalars['Int']['output'];
  quizScoreDetails?: Maybe<LessonQuizScore>;
  quizScoringCriteria: QuizScoringCriteria;
  quizSession?: Maybe<QuizSession>;
  quizSessionId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type LessonPublishOperationMessage = {
  __typename?: 'LessonPublishOperationMessage';
  code?: Maybe<PublishOperationMessageCode>;
  lessonId: Scalars['ID']['output'];
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
};

export type LessonQuiz = {
  __typename?: 'LessonQuiz';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  playSelectionCount?: Maybe<Scalars['Float']['output']>;
  playSelectionCriteria?: Maybe<PlaySelectionCriteria>;
  playSelectionMode: LessonQuizPlaySelectionMode;
  preselectedPlays?: Maybe<Array<PlayReference>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonQuizCreateByLessonIdInput = {
  lesson: Scalars['ID']['input'];
  playSelectionCount?: InputMaybe<Scalars['Float']['input']>;
  playSelectionCriteria?: InputMaybe<PlaySelectionCriteriaInput>;
  playSelectionMode: LessonQuizPlaySelectionMode;
  preselectedPlays?: InputMaybe<Array<PlayReferenceInput>>;
};

export type LessonQuizCreateInput = {
  playSelectionCount?: InputMaybe<Scalars['Float']['input']>;
  playSelectionCriteria?: InputMaybe<PlaySelectionCriteriaInput>;
  playSelectionMode: LessonQuizPlaySelectionMode;
  preselectedPlays?: InputMaybe<Array<PlayReferenceInput>>;
};

export enum LessonQuizPlaySelectionMode {
  /** Plays are selected manually at the time of lesson creation */
  Preselected = 'Preselected',
  /** Plays for the quiz are chosen at random from a pool matching the selection criteria */
  Random = 'Random'
}

export type LessonQuizScore = {
  __typename?: 'LessonQuizScore';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  maxScore: Scalars['Int']['output'];
  optimalDecisionCount: Scalars['Int']['output'];
  perfectDecisionCount: Scalars['Int']['output'];
  playCount: Scalars['Int']['output'];
  score: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LessonSort = {
  direction: SortDirection;
  field: LessonSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LessonSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  SortOrder = 'sortOrder',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type LessonUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: LessonUpdateInputType;
};

export type LessonUpdateInputType = {
  announcementId?: InputMaybe<Scalars['ID']['input']>;
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  launchDate?: InputMaybe<Scalars['DateTime']['input']>;
  lessonCategory?: InputMaybe<Scalars['ID']['input']>;
  level?: InputMaybe<PlayerLevel>;
  modules?: InputMaybe<Array<CompositeModuleInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  quiz?: InputMaybe<LessonQuizCreateInput>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentStatus>;
};

export type MetaDataObject = {
  __typename?: 'MetaDataObject';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  plan: Scalars['String']['output'];
  platform: Scalars['String']['output'];
  registrationCode: Scalars['String']['output'];
  teamCode: Scalars['String']['output'];
};

export type MetadataTag = {
  __typename?: 'MetadataTag';
  /** The display name for the tag */
  tagName: Scalars['String']['output'];
};

export type MetadataTagInput = {
  /** The display name for the tag */
  tagName: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addFootlingoLessonsToFootlingoCategory: FootlingoCategory;
  addLessonsToLessonCategory: LessonCategory;
  addPlayVariantsToCoverageType: CoverageType;
  addPlaysToFormationType: FormationType;
  addRouteStencilsToRouteStencilFolder: RouteStencilFolder;
  /** Generate a new access token by passing a valid refresh token in the Authorization header of the request. */
  adminUserTokenRefresh: AuthRefreshTokenResponse;
  announcementCreate: Announcement;
  announcementDelete: AnnouncementDeleteResponse;
  announcementDisable: Announcement;
  announcementUpdate: Announcement;
  /** Register a new coach & team */
  coachRegister: CoachOutput;
  /** Request forgot password for coach */
  coachRequestForgotPassword: ResultResponse;
  /** Set a new password for a coach account */
  coachSetNewPassword: ResultResponse;
  /** Provide a registration code to retrieve authentication tokens */
  coachSignIn: AuthSignInResponse;
  coverageCreate: Coverage;
  coverageDelete: CoverageDeleteResponse;
  coverageTypeCreate: CoverageType;
  coverageTypeDelete: CoverageType;
  coverageTypePublish: PublishOperation;
  coverageTypeUpdate: CoverageType;
  coverageUpdate: Coverage;
  /** Create a new user account and generate a stripe checkout url. */
  createSubscription: CreateSubscriptionResponse;
  /** A way to create a team. */
  createTeam: CreateTeamResponse;
  deleteManyFootlingoLessons: DeleteManyResponse;
  deleteOneFootlingoLesson: FootlingoLessonDeleteResponse;
  /** A way to delete a team. */
  deleteTeam: ResultResponse;
  /** A way to delete a team. */
  deleteUserFromTeam: ResultResponse;
  dropCreate: Drop;
  dropDelete: Drop;
  dropFinalize: Drop;
  dropPublish: PublishOperation;
  dropUnPublish: PublishOperation;
  dropUpdate: Drop;
  /** A way to edit the player contacts. */
  editPlayerContacts: ResultResponse;
  /** A way to edit the team name. */
  editTeamName: ResultResponse;
  emailTemplateCreate: EmailTemplate;
  emailTemplateDelete: EmailTemplateDeleteResponse;
  emailTemplateUpdate: EmailTemplate;
  /**
   *
   *   Deletes a file from the specified category with the provided file name.
   *
   */
  fileDelete: Scalars['Boolean']['output'];
  /**
   *
   * Creates a new file upload with a signed url that can be used to upload file contents.
   *
   * Call this endpoint to generate a url and fields that grant access to upload a file using
   * an HTTP Form POST.  All fields returned by this endpoint must be included in the form
   * POST parameters with values exactly as given; otherwise, the request will be denied
   * with a 403 (Forbidden) status code.
   *
   * A Content-Type parameter should also be provided in the form POST with a mime-type
   * that identifies the file format.
   *
   * For more details, see: https://github.com/aws/aws-sdk-js-v3/tree/main/packages/s3-presigned-post#post-file-using-formdata-in-nodejs
   *
   */
  fileUploadCreate: FileUpload;
  /**
   *
   *   Overwrites an existing file in the specified category with the provided file name.
   *   Generates a signed URL that can be used to upload file contents.
   *
   */
  fileUploadOverwrite: FileUpload;
  footlingoAddImageModule: FootlingoLesson;
  footlingoAddPlaybackModule: FootlingoLesson;
  footlingoCategoryCreate: FootlingoCategory;
  footlingoCategoryDelete: FootlingoCategoryDeleteResponse;
  footlingoCategoryUpdate: FootlingoCategory;
  footlingoCreate: FootlingoLesson;
  footlingoDelete: FootlingoLesson;
  footlingoDeleteModule: FootlingoLesson;
  footlingoDeleteQuestion: FootlingoLesson;
  footlingoLessonProgressMaterialsUpdate: FootlingoProgress;
  footlingoUpdate: FootlingoLesson;
  formationCreate: Formation;
  formationDelete: FormationDeleteResponse;
  formationTypeCreate: FormationType;
  formationTypeDelete: FormationTypeDeleteResponse;
  formationTypeUpdate: FormationType;
  formationUpdate: Formation;
  /** Creates a new game play session */
  gameSessionCreate: GameSession;
  gameSessionEnd: GameSession;
  /** Extends the given session by adding another series of plays. */
  gameSessionExtend: GameSessionExtendResponse;
  gameSessionPlaySetDecision: GameSessionPlayDecision;
  getAllLessons: Array<Lesson>;
  getFootlingoLesson: FootlingoLessonResponse;
  /** Get an individual user's stats. */
  getHistoryByUsername: UserStatsHistory;
  /** A way for the stats page to populate stats. */
  getStats: StatsResponse;
  /** Get an individual users stats. */
  getStatsByUsername: PlayerStatsItem;
  /** A way for the stats page to populate team stats. */
  getTeamStatsByID: TeamStatsResponse;
  /** A way for the stats page to populate stats. */
  getTeams: TeamResponse;
  hydrateUserAccounts: ResultResponse;
  lessonAddImageModule: Lesson;
  lessonAddPlaybackModule: Lesson;
  lessonAddQuiz: Lesson;
  lessonCategoryCreate: LessonCategory;
  lessonCategoryDelete: LessonCategoryDeleteResponse;
  lessonCategoryUpdate: LessonCategory;
  lessonCreate: Lesson;
  lessonDelete: LessonDeleteResponse;
  lessonDeleteModule: Lesson;
  lessonDeleteQuiz: Lesson;
  lessonProgressMaterialsUpdate: LessonProgress;
  lessonPublish: PublishOperation;
  lessonQuizSessionCreate: LessonProgress;
  lessonUpdate: Lesson;
  playCreate: Play;
  playDelete: Play;
  /** Provide a username and password to retrieve authentication tokens */
  playEditorSignIn: AuthSignInResponse;
  playFinalize: Play;
  playUpdate: Play;
  playUpdateSimple: Play;
  playVariantCreate: PlayVariant;
  playVariantUpdate: PlayVariant;
  /** Publishes multiple play variants at once */
  playVariantsPublish: PublishOperation;
  /** Demotes the given Play Variants to STAGED from the PUBLISHED status and cascades that demotion to related entities as necessary */
  playVariantsUnPublish: PublishOperation;
  /** Register a new player */
  playerRegister: User;
  playerUpdateCustomization: PlayerCustomization;
  /** Recalculates and updates the leaderboards without deleting any player data. */
  rebalanceAllLeaderboards: ResultResponse;
  reportPlayer: ResultResponse;
  /** Retrieve Stripe session metadata and subscription details. */
  retrieveStripeSession: StripeSessionResponse;
  routeStencilCreate: RouteStencil;
  routeStencilDelete: RouteStencilDeleteResponse;
  routeStencilFolderCreate: RouteStencilFolder;
  routeStencilFolderDelete: RouteStencilFolderDeleteResponse;
  routeStencilFolderUpdate: RouteStencilFolder;
  routeStencilUpdate: RouteStencil;
  sandboxPlayCreate: SandboxPlay;
  /** Creates a new Sandbox Play by copying players, routes, and settings from the given Play Variant */
  sandboxPlayCreateFromPlayVariant: SandboxPlay;
  sandboxPlayDelete: SandboxPlay;
  /** Creates a duplicate SandboxPlay from the given SandboxPlay */
  sandboxPlayDuplicate: SandboxPlay;
  sandboxPlayUpdate: SandboxPlay;
  /** Send an email using SendGrid */
  sendMail: ResultResponse;
  setFolderOnRouteStencil: RouteStencil;
  setFootlingoLessonsOnFootlingoCategory: FootlingoCategory;
  /** Recalculates and updates the leaderboards without deleting any player data. */
  setGameSessionCorrectMode: ResultResponse;
  setLessonCategoryOnLesson: Lesson;
  setLessonsOnLessonCategory: LessonCategory;
  setPassword: ResultResponse;
  setPlayVariantsOnCoverageType: CoverageType;
  setPlaysOnFormationType: FormationType;
  setReferenceCoverageOnCoverage: Coverage;
  setReferenceCoverageOnFormation: Formation;
  setReferenceFormationOnCoverage: Coverage;
  setReferenceFormationOnFormation: Formation;
  setRouteStencilsOnRouteStencilFolder: RouteStencilFolder;
  userChangeTempPassword: AuthSignInResponse;
  userCompleteOnboardingStep: ResultResponse;
  userConfirmForgotPassword: ResultResponse;
  userCreate: User;
  /** Disables the given user account, which prevents them from logging in and immediately revokes any previously issued access tokens. */
  userDisable: ResultResponse;
  /** Re-enables the given user account so they can log in again.  Previously revoked access tokens remain revoked. */
  userEnable: ResultResponse;
  userForgotPassword: ResultResponse;
  userInvitationCodeCreate: UserInvitationCode;
  userInvitationCodeDelete: UserInvitationCodeDeleteResponse;
  userInvitationCodeUpdate: UserInvitationCode;
  userRegisterDevice: ResultResponse;
  userRequestAccountDeletion: ResultResponse;
  userSetDisplayNamePreference: ResultResponse;
  userSetEmailAddress: User;
  userSetPreferredUserName: User;
  userSetSpeedDifficulty: ResultResponse;
  /** Provide a registration code to retrieve authentication tokens */
  userSignIn: AuthSignInResponse;
  /** Generate a new access token by passing a valid refresh token in the Authorization header of the request. */
  userTokenRefresh: AuthRefreshTokenResponse;
  userVerifyEmailAddress: User;
  validateSubscription: ValidateSubscriptionOutput;
  variantDelete: PlayVariant;
};


export type MutationAddFootlingoLessonsToFootlingoCategoryArgs = {
  input: AddFootlingoLessonsToFootlingoCategoryInput;
};


export type MutationAddLessonsToLessonCategoryArgs = {
  input: AddLessonsToLessonCategoryInput;
};


export type MutationAddPlayVariantsToCoverageTypeArgs = {
  input: AddPlayVariantsToCoverageTypeInput;
};


export type MutationAddPlaysToFormationTypeArgs = {
  input: AddPlaysToFormationTypeInput;
};


export type MutationAddRouteStencilsToRouteStencilFolderArgs = {
  input: AddRouteStencilsToRouteStencilFolderInput;
};


export type MutationAnnouncementCreateArgs = {
  input: AnnouncementCreateInput;
};


export type MutationAnnouncementDeleteArgs = {
  input: DeleteOneAnnouncementInput;
};


export type MutationAnnouncementDisableArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAnnouncementUpdateArgs = {
  input: AnnouncementUpdateInput;
};


export type MutationCoachRegisterArgs = {
  input: CoachInput;
};


export type MutationCoachRequestForgotPasswordArgs = {
  email: Scalars['String']['input'];
};


export type MutationCoachSetNewPasswordArgs = {
  code: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCoachSignInArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCoverageCreateArgs = {
  input: CoverageCreateInput;
};


export type MutationCoverageDeleteArgs = {
  input: DeleteOneCoverageInput;
};


export type MutationCoverageTypeCreateArgs = {
  input: CoverageTypeCreateInput;
};


export type MutationCoverageTypeDeleteArgs = {
  input: CoverageTypeDeleteOneInput;
};


export type MutationCoverageTypePublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  playVariantIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationCoverageTypeUpdateArgs = {
  input: CoverageTypeUpdateInput;
};


export type MutationCoverageUpdateArgs = {
  input: CoverageUpdateInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateTeamArgs = {
  teamDifficulty?: InputMaybe<Scalars['Float']['input']>;
  teamName: Scalars['String']['input'];
};


export type MutationDeleteManyFootlingoLessonsArgs = {
  input: DeleteManyFootlingoLessonsInput;
};


export type MutationDeleteOneFootlingoLessonArgs = {
  input: DeleteOneFootlingoLessonInput;
};


export type MutationDeleteTeamArgs = {
  teamID: Scalars['String']['input'];
};


export type MutationDeleteUserFromTeamArgs = {
  teamID: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationDropCreateArgs = {
  input: DropCreateInput;
};


export type MutationDropDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDropFinalizeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDropPublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationDropUnPublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationDropUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DropUpdateInput;
};


export type MutationEditPlayerContactsArgs = {
  contacts: Array<UserContactsInput>;
  teamID: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationEditTeamNameArgs = {
  teamID: Scalars['String']['input'];
  teamName: Scalars['String']['input'];
};


export type MutationEmailTemplateCreateArgs = {
  input: EmailTemplateCreateInput;
};


export type MutationEmailTemplateDeleteArgs = {
  input: DeleteOneEmailTemplateInput;
};


export type MutationEmailTemplateUpdateArgs = {
  input: EmailTemplateUpdateInput;
};


export type MutationFileDeleteArgs = {
  category: FileUploadCategory;
  fileNameWithExtension: Scalars['String']['input'];
};


export type MutationFileUploadCreateArgs = {
  category: FileUploadCategory;
  contentType: Scalars['String']['input'];
  fileNameWithExtension: Scalars['String']['input'];
};


export type MutationFileUploadOverwriteArgs = {
  category: FileUploadCategory;
  contentType: Scalars['String']['input'];
  fileNameWithExtension: Scalars['String']['input'];
};


export type MutationFootlingoAddImageModuleArgs = {
  input: FootlingoLessonModuleQuestionMultipleChoiceCreateInput;
};


export type MutationFootlingoAddPlaybackModuleArgs = {
  input: FootlingoLessonModuleQuestionMatchingPairsCreateInput;
};


export type MutationFootlingoCategoryCreateArgs = {
  input: LessonCategoryCreateInput;
};


export type MutationFootlingoCategoryDeleteArgs = {
  input: DeleteOneFootlingoCategoryInput;
};


export type MutationFootlingoCategoryUpdateArgs = {
  input: LessonCategoryUpdateInput;
};


export type MutationFootlingoCreateArgs = {
  input: FootlingoLessonCreateInput;
};


export type MutationFootlingoDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationFootlingoDeleteModuleArgs = {
  lesson: Scalars['String']['input'];
  lessonModule: Scalars['String']['input'];
};


export type MutationFootlingoDeleteQuestionArgs = {
  lesson: Scalars['String']['input'];
  lessonModule: Scalars['String']['input'];
  question: Scalars['String']['input'];
};


export type MutationFootlingoLessonProgressMaterialsUpdateArgs = {
  completedModules: Array<FootlingoLessonModuleCompletedInput>;
  lessonId: Scalars['String']['input'];
};


export type MutationFootlingoUpdateArgs = {
  input: FootlingoLessonUpdateInput;
};


export type MutationFormationCreateArgs = {
  input: FormationCreateInput;
};


export type MutationFormationDeleteArgs = {
  input: DeleteOneFormationInput;
};


export type MutationFormationTypeCreateArgs = {
  input: FormationTypeCreateInput;
};


export type MutationFormationTypeDeleteArgs = {
  input: DeleteOneFormationTypeInput;
};


export type MutationFormationTypeUpdateArgs = {
  input: FormationTypeUpdateInput;
};


export type MutationFormationUpdateArgs = {
  input: FormationUpdateInput;
};


export type MutationGameSessionCreateArgs = {
  input: GameSessionCreateInput;
};


export type MutationGameSessionEndArgs = {
  input: GameSessionEndInput;
};


export type MutationGameSessionExtendArgs = {
  gameModeArgs?: InputMaybe<GameModeArgsInput>;
  sessionId: Scalars['ID']['input'];
  updateModeArgs?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationGameSessionPlaySetDecisionArgs = {
  input: GameSessionPlayDecisionInput;
};


export type MutationGetFootlingoLessonArgs = {
  id: Scalars['String']['input'];
};


export type MutationGetHistoryByUsernameArgs = {
  difficulty: Scalars['Int']['input'];
  stat: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationGetStatsArgs = {
  difficulty: Scalars['Int']['input'];
  timeframe: StatPlayType;
};


export type MutationGetStatsByUsernameArgs = {
  difficulty?: InputMaybe<Scalars['Int']['input']>;
  timeframe?: InputMaybe<StatPlayType>;
  username: Scalars['String']['input'];
};


export type MutationGetTeamStatsByIdArgs = {
  teamID: Scalars['String']['input'];
};


export type MutationLessonAddImageModuleArgs = {
  input: ImageLessonModuleCreateInput;
};


export type MutationLessonAddPlaybackModuleArgs = {
  input: PlaybackLessonModuleCreateInput;
};


export type MutationLessonAddQuizArgs = {
  input: LessonQuizCreateByLessonIdInput;
};


export type MutationLessonCategoryCreateArgs = {
  input: LessonCategoryCreateInput;
};


export type MutationLessonCategoryDeleteArgs = {
  input: DeleteOneLessonCategoryInput;
};


export type MutationLessonCategoryUpdateArgs = {
  input: LessonCategoryUpdateInput;
};


export type MutationLessonCreateArgs = {
  input: LessonCreateInput;
};


export type MutationLessonDeleteArgs = {
  input: DeleteOneLessonInput;
};


export type MutationLessonDeleteModuleArgs = {
  lesson: Scalars['String']['input'];
  lessonModule: Scalars['String']['input'];
};


export type MutationLessonDeleteQuizArgs = {
  lesson: Scalars['ID']['input'];
};


export type MutationLessonProgressMaterialsUpdateArgs = {
  completedModules: Array<LessonModuleCompletedInput>;
  lessonId: Scalars['String']['input'];
};


export type MutationLessonPublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationLessonQuizSessionCreateArgs = {
  lessonId: Scalars['ID']['input'];
};


export type MutationLessonUpdateArgs = {
  input: LessonUpdateInput;
};


export type MutationPlayCreateArgs = {
  input: PlayCreateInput;
};


export type MutationPlayDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPlayEditorSignInArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationPlayFinalizeArgs = {
  id: Scalars['ID']['input'];
  input: PlayFinalizeInput;
};


export type MutationPlayUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PlayUpdateInput;
};


export type MutationPlayUpdateSimpleArgs = {
  id: Scalars['ID']['input'];
  input: PlayUpdateBaseInput;
};


export type MutationPlayVariantCreateArgs = {
  input: CreateOnePlayVariantInput;
};


export type MutationPlayVariantUpdateArgs = {
  input: UpdateOnePlayVariantInput;
};


export type MutationPlayVariantsPublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  playVariantIds: Array<Scalars['ID']['input']>;
};


export type MutationPlayVariantsUnPublishArgs = {
  dryRun?: InputMaybe<Scalars['Boolean']['input']>;
  playVariantIds: Array<Scalars['ID']['input']>;
};


export type MutationPlayerRegisterArgs = {
  input: UserRegisterPlayerInput;
};


export type MutationPlayerUpdateCustomizationArgs = {
  input: PlayerCustomizationInput;
};


export type MutationReportPlayerArgs = {
  input: ReportPlayerInput;
};


export type MutationRetrieveStripeSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type MutationRouteStencilCreateArgs = {
  input: RouteStencilCreateInput;
};


export type MutationRouteStencilDeleteArgs = {
  input: DeleteOneRouteStencilInput;
};


export type MutationRouteStencilFolderCreateArgs = {
  input: RouteStencilFolderCreateInput;
};


export type MutationRouteStencilFolderDeleteArgs = {
  input: DeleteOneRouteStencilFolderInput;
};


export type MutationRouteStencilFolderUpdateArgs = {
  input: RouteStencilFolderUpdateInput;
};


export type MutationRouteStencilUpdateArgs = {
  input: RouteStencilUpdateInput;
};


export type MutationSandboxPlayCreateArgs = {
  input: CreateOneSandboxPlayInput;
};


export type MutationSandboxPlayCreateFromPlayVariantArgs = {
  name: Scalars['String']['input'];
  playVariantId: Scalars['ID']['input'];
};


export type MutationSandboxPlayDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSandboxPlayDuplicateArgs = {
  asDraft?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  sandboxPlayId: Scalars['ID']['input'];
};


export type MutationSandboxPlayUpdateArgs = {
  input: UpdateOneSandboxPlayInput;
};


export type MutationSendMailArgs = {
  input: SendMailInput;
};


export type MutationSetFolderOnRouteStencilArgs = {
  input: SetFolderOnRouteStencilInput;
};


export type MutationSetFootlingoLessonsOnFootlingoCategoryArgs = {
  input: SetFootlingoLessonsOnFootlingoCategoryInput;
};


export type MutationSetLessonCategoryOnLessonArgs = {
  input: SetLessonCategoryOnLessonInput;
};


export type MutationSetLessonsOnLessonCategoryArgs = {
  input: SetLessonsOnLessonCategoryInput;
};


export type MutationSetPasswordArgs = {
  password: Scalars['String']['input'];
};


export type MutationSetPlayVariantsOnCoverageTypeArgs = {
  input: SetPlayVariantsOnCoverageTypeInput;
};


export type MutationSetPlaysOnFormationTypeArgs = {
  input: SetPlaysOnFormationTypeInput;
};


export type MutationSetReferenceCoverageOnCoverageArgs = {
  input: SetReferenceCoverageOnCoverageInput;
};


export type MutationSetReferenceCoverageOnFormationArgs = {
  input: SetReferenceCoverageOnFormationInput;
};


export type MutationSetReferenceFormationOnCoverageArgs = {
  input: SetReferenceFormationOnCoverageInput;
};


export type MutationSetReferenceFormationOnFormationArgs = {
  input: SetReferenceFormationOnFormationInput;
};


export type MutationSetRouteStencilsOnRouteStencilFolderArgs = {
  input: SetRouteStencilsOnRouteStencilFolderInput;
};


export type MutationUserChangeTempPasswordArgs = {
  newPassword: Scalars['String']['input'];
  session: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUserCompleteOnboardingStepArgs = {
  step: OnboardingStep;
};


export type MutationUserConfirmForgotPasswordArgs = {
  code: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationUserCreateArgs = {
  input: UserCreateInput;
};


export type MutationUserDisableArgs = {
  reason: UserAccountDisabledReason;
  username: Scalars['String']['input'];
};


export type MutationUserEnableArgs = {
  username: Scalars['String']['input'];
};


export type MutationUserForgotPasswordArgs = {
  username: Scalars['String']['input'];
};


export type MutationUserInvitationCodeCreateArgs = {
  input: CreateOneUserInvitationCodeInput;
};


export type MutationUserInvitationCodeDeleteArgs = {
  input: DeleteOneUserInvitationCodeInput;
};


export type MutationUserInvitationCodeUpdateArgs = {
  input: UpdateOneUserInvitationCodeInput;
};


export type MutationUserRegisterDeviceArgs = {
  input: DeviceRegistrationInput;
};


export type MutationUserSetDisplayNamePreferenceArgs = {
  option: Scalars['Float']['input'];
};


export type MutationUserSetEmailAddressArgs = {
  email: Scalars['String']['input'];
};


export type MutationUserSetPreferredUserNameArgs = {
  username: Scalars['String']['input'];
};


export type MutationUserSetSpeedDifficultyArgs = {
  option: Scalars['Float']['input'];
};


export type MutationUserSignInArgs = {
  registrationCode: Scalars['String']['input'];
};


export type MutationUserVerifyEmailAddressArgs = {
  code: Scalars['String']['input'];
};


export type MutationValidateSubscriptionArgs = {
  input: ValidateSubscriptionInput;
};


export type MutationVariantDeleteArgs = {
  id: Scalars['ID']['input'];
};

/** A player for the offense */
export type OffensivePlayer = PlayPlayer & {
  __typename?: 'OffensivePlayer';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  pos: Point;
  positionType: PlayerPositionType;
  rot: Rotation;
};

export type OffensivePlayerInput = {
  id: Scalars['String']['input'];
  label: Scalars['String']['input'];
  pos: PointInput;
  positionType: PlayerPositionType;
  rot: RotationInput;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OnboardingStatus = {
  __typename?: 'OnboardingStatus';
  completedSteps: Array<OnboardingStep>;
};

/** Steps to be completed as a part of new player onboarding */
export enum OnboardingStep {
  /** Onboarding step where players provide their own names for defensive Coverages */
  CoverageTerminologyCustomization = 'COVERAGE_TERMINOLOGY_CUSTOMIZATION',
  /** Onboarding step where players provide their own names for Formations */
  FormationTerminologyCustomization = 'FORMATION_TERMINOLOGY_CUSTOMIZATION',
  /** Onboarding step where players complete a tutorial on game play */
  GameTutorial = 'GAME_TUTORIAL',
  /** Onboarding step where players choose their player customization options */
  PlayerCustomization = 'PLAYER_CUSTOMIZATION',
  /** Onboarding step where players provide their own names for Plays */
  PlayTerminologyCustomization = 'PLAY_TERMINOLOGY_CUSTOMIZATION',
  /** Onboarding step where players set their preferred account username */
  PreferredUsername = 'PREFERRED_USERNAME'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type PasswordPolicy = {
  __typename?: 'PasswordPolicy';
  minimumLength?: Maybe<Scalars['Int']['output']>;
  requireLowercase?: Maybe<Scalars['Boolean']['output']>;
  requireNumbers?: Maybe<Scalars['Boolean']['output']>;
  requireSymbols?: Maybe<Scalars['Boolean']['output']>;
  requireUppercase?: Maybe<Scalars['Boolean']['output']>;
  temporaryPasswordValidityDays?: Maybe<Scalars['Int']['output']>;
};

/** The subscription plan available for users */
export enum Plan {
  /** A free plan with basic features. */
  Free = 'FREE',
  /** Our premium subscription plan. */
  Premium = 'PREMIUM',
  /** Our premium plus subscription plan. */
  Premiumplus = 'PREMIUMPLUS'
}

export type PlatformRequirement = {
  __typename?: 'PlatformRequirement';
  latestVersion: ClientVersion;
  minVersion: ClientVersion;
  newVersionAvailableMessage: Scalars['String']['output'];
  platform: PlatformType;
  requirementsNotMetMessage: Scalars['String']['output'];
};

export enum PlatformType {
  /** Android mobile platform */
  Android = 'Android',
  /** iOS mobile platform */
  IOs = 'iOS'
}

export type Play = {
  __typename?: 'Play';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  diagramImageAssetKey?: Maybe<Scalars['String']['output']>;
  diagramImageUrl?: Maybe<Scalars['String']['output']>;
  /** Identifies which down-and-distance situations this play is applicable for */
  downAndDistance?: Maybe<PlayDownAndDistanceTags>;
  drop: QbDropType;
  formationType?: Maybe<FormationType>;
  formationTypeId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  liveCoverageTypes?: Maybe<Array<CoverageType>>;
  offensivePlayers: Array<OffensivePlayer>;
  playDrops?: Maybe<PlayPlayDropsConnection>;
  playDropsAggregate: Array<PlayPlayDropsAggregateResponse>;
  playName: Scalars['String']['output'];
  playbookSlides?: Maybe<Array<PlaybookSlide>>;
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  qbPlacement: QbAlignmentType;
  status: ContentStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantV2s: PlayVariantConnection;
  /** DEPRECATED use variantsV2s field instead */
  variants: Array<PlayVariant>;
  version: Scalars['Int']['output'];
  whiteboardImageAssetKey?: Maybe<Scalars['String']['output']>;
  whiteboardImageUrl?: Maybe<Scalars['String']['output']>;
};


export type PlayPlayDropsArgs = {
  filter?: DropFilter;
  paging?: CursorPaging;
  sorting?: Array<DropSort>;
};


export type PlayPlayDropsAggregateArgs = {
  filter?: InputMaybe<DropAggregateFilter>;
};


export type PlayVariantV2sArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};

export type PlayAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PlayAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  downAndDistance?: InputMaybe<PlayDownAndDistanceFilterComparison>;
  formationTypeId?: InputMaybe<IdFilterComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<PlayAggregateFilter>>;
  playName?: InputMaybe<StringFieldComparison>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  protection?: InputMaybe<ProtectionTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PlayAggregateGroupBy = {
  __typename?: 'PlayAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downAndDistance?: Maybe<PlayDownAndDistanceTags>;
  formationTypeId?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playName?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlayAggregateResponse = {
  __typename?: 'PlayAggregateResponse';
  count?: Maybe<PlayCountAggregate>;
  groupBy?: Maybe<PlayAggregateGroupBy>;
  max?: Maybe<PlayMaxAggregate>;
  min?: Maybe<PlayMinAggregate>;
};

export type PlayConnection = {
  __typename?: 'PlayConnection';
  /** Array of edges. */
  edges: Array<PlayEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PlayCountAggregate = {
  __typename?: 'PlayCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  downAndDistance?: Maybe<Scalars['Int']['output']>;
  formationTypeId?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  playName?: Maybe<Scalars['Int']['output']>;
  playerLevel?: Maybe<Scalars['Int']['output']>;
  protection?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type PlayCoverageTypeFilter = {
  and?: InputMaybe<Array<PlayCoverageTypeSearchResultFilter>>;
  coverageTypeCreatedAt?: InputMaybe<DateFieldComparison>;
  coverageTypeName?: InputMaybe<StringFieldComparison>;
  coverageTypeUpdatedAt?: InputMaybe<DateFieldComparison>;
  draftVariantCount?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<PlayCoverageTypeSearchResultFilter>>;
  publishedVariantCount?: InputMaybe<IntFieldComparison>;
  stagedVariantCount?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  totalVariantCount?: InputMaybe<IntFieldComparison>;
  variantDescriptions?: InputMaybe<PlayCoverageTypeSearchResultVariantDescriptionsFilterComparison>;
  variantTags?: InputMaybe<PlayCoverageTypeSearchResultVariantTagsFilterComparison>;
};

export type PlayCoverageTypeSearchResult = {
  __typename?: 'PlayCoverageTypeSearchResult';
  coverageTypeCreatedAt: Scalars['DateTime']['output'];
  coverageTypeId: Scalars['ID']['output'];
  coverageTypeName: Scalars['String']['output'];
  coverageTypeUpdatedAt: Scalars['DateTime']['output'];
  draftVariantCount: Scalars['Int']['output'];
  playId: Scalars['ID']['output'];
  playVariants: PlayVariantConnection;
  publishedVariantCount: Scalars['Int']['output'];
  stagedVariantCount: Scalars['Int']['output'];
  status: ContentStatus;
  totalVariantCount: Scalars['Int']['output'];
};


export type PlayCoverageTypeSearchResultPlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};

export type PlayCoverageTypeSearchResultFilter = {
  and?: InputMaybe<Array<PlayCoverageTypeSearchResultFilter>>;
  coverageTypeCreatedAt?: InputMaybe<DateFieldComparison>;
  coverageTypeName?: InputMaybe<StringFieldComparison>;
  coverageTypeUpdatedAt?: InputMaybe<DateFieldComparison>;
  draftVariantCount?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<PlayCoverageTypeSearchResultFilter>>;
  publishedVariantCount?: InputMaybe<IntFieldComparison>;
  stagedVariantCount?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  totalVariantCount?: InputMaybe<IntFieldComparison>;
  variantDescriptions?: InputMaybe<PlayCoverageTypeSearchResultVariantDescriptionsFilterComparison>;
  variantTags?: InputMaybe<PlayCoverageTypeSearchResultVariantTagsFilterComparison>;
};

export type PlayCoverageTypeSearchResultVariantDescriptionsFilterComparison = {
  like?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type PlayCoverageTypeSearchResultVariantTagsFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PlayCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  /** Identifies which down-and-distance situations this play is applicable for */
  downAndDistance?: InputMaybe<PlayDownAndDistanceTagsInput>;
  drop: QbDropType;
  formationTypeId?: InputMaybe<Scalars['ID']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offensivePlayers: Array<OffensivePlayerInput>;
  playName: Scalars['String']['input'];
  playbookSlides?: InputMaybe<Array<PlaybookSlideCreateInput>>;
  playerLevel?: InputMaybe<PlayerLevel>;
  protection?: InputMaybe<ProtectionType>;
  qbPlacement: QbAlignmentType;
  variants: Array<PlayVariantCreateWithPlayInput>;
  whiteboardImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

/** The type of outcome a play decision will result in */
export enum PlayDecisionOutcome {
  Incomplete = 'INCOMPLETE',
  Interception = 'INTERCEPTION',
  NoDecision = 'NO_DECISION',
  Optimal = 'OPTIMAL',
  Pending = 'PENDING',
  Sack = 'SACK',
  Suboptimal = 'SUBOPTIMAL'
}

export type PlayDownAndDistanceFilterComparison = {
  eq?: InputMaybe<PlayDownAndDistanceTagsInput>;
  in?: InputMaybe<Array<PlayDownAndDistanceTagsInput>>;
  notIn?: InputMaybe<Array<PlayDownAndDistanceTagsInput>>;
};

export type PlayDownAndDistanceTags = {
  __typename?: 'PlayDownAndDistanceTags';
  FirstDownAndTen: Scalars['Boolean']['output'];
  /** Second down with seven or more yards to go */
  SecondDownAndLong: Scalars['Boolean']['output'];
  /** Second down with three to six yards to go, inclusive */
  SecondDownAndMedium: Scalars['Boolean']['output'];
  /** Second down with under three yards to go */
  SecondDownAndShort: Scalars['Boolean']['output'];
  /** Third down with eleven or more yards to go */
  ThirdDownAndLong: Scalars['Boolean']['output'];
  /** Third down with five to seven yards to go, inclusive */
  ThirdDownAndMedium: Scalars['Boolean']['output'];
  /** Third down with eight to ten yards to go, inclusive */
  ThirdDownAndMediumLong: Scalars['Boolean']['output'];
  /** Third down with two to four yards to go, inclusive */
  ThirdDownAndMediumShort: Scalars['Boolean']['output'];
  /** Third down with less than two yards to go */
  ThirdDownAndShort: Scalars['Boolean']['output'];
};

export type PlayDownAndDistanceTagsInput = {
  FirstDownAndTen: Scalars['Boolean']['input'];
  /** Second down with seven or more yards to go */
  SecondDownAndLong: Scalars['Boolean']['input'];
  /** Second down with three to six yards to go, inclusive */
  SecondDownAndMedium: Scalars['Boolean']['input'];
  /** Second down with under three yards to go */
  SecondDownAndShort: Scalars['Boolean']['input'];
  /** Third down with eleven or more yards to go */
  ThirdDownAndLong: Scalars['Boolean']['input'];
  /** Third down with five to seven yards to go, inclusive */
  ThirdDownAndMedium: Scalars['Boolean']['input'];
  /** Third down with eight to ten yards to go, inclusive */
  ThirdDownAndMediumLong: Scalars['Boolean']['input'];
  /** Third down with two to four yards to go, inclusive */
  ThirdDownAndMediumShort: Scalars['Boolean']['input'];
  /** Third down with less than two yards to go */
  ThirdDownAndShort: Scalars['Boolean']['input'];
};

export type PlayEdge = {
  __typename?: 'PlayEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Play */
  node: Play;
};

export type PlayFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PlayFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  downAndDistance?: InputMaybe<PlayDownAndDistanceFilterComparison>;
  formationTypeId?: InputMaybe<IdFilterComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<PlayFilter>>;
  playName?: InputMaybe<StringFieldComparison>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  protection?: InputMaybe<ProtectionTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type PlayFilterExtraInput = {
  excludeCoverageTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeCoverageTypeIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type PlayFinalizeInput = {
  downAndDistance: PlayDownAndDistanceTagsInput;
  protection: ProtectionType;
};

export type PlayMaxAggregate = {
  __typename?: 'PlayMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downAndDistance?: Maybe<PlayDownAndDistanceTags>;
  formationTypeId?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playName?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlayMinAggregate = {
  __typename?: 'PlayMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  downAndDistance?: Maybe<PlayDownAndDistanceTags>;
  formationTypeId?: Maybe<Scalars['ID']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playName?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlayPlayDropsAggregateGroupBy = {
  __typename?: 'PlayPlayDropsAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlayPlayDropsAggregateResponse = {
  __typename?: 'PlayPlayDropsAggregateResponse';
  count?: Maybe<PlayPlayDropsCountAggregate>;
  groupBy?: Maybe<PlayPlayDropsAggregateGroupBy>;
  max?: Maybe<PlayPlayDropsMaxAggregate>;
  min?: Maybe<PlayPlayDropsMinAggregate>;
};

export type PlayPlayDropsConnection = {
  __typename?: 'PlayPlayDropsConnection';
  /** Array of edges. */
  edges: Array<DropEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PlayPlayDropsCountAggregate = {
  __typename?: 'PlayPlayDropsCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  dropType?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  launchDate?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  playerLevel?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type PlayPlayDropsMaxAggregate = {
  __typename?: 'PlayPlayDropsMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PlayPlayDropsMinAggregate = {
  __typename?: 'PlayPlayDropsMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dropType?: Maybe<DropType>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  launchDate?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerLevel?: Maybe<PlayerLevel>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

/** Common Player attributes */
export type PlayPlayer = {
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  pos: Point;
  positionType: PlayerPositionType;
  rot: Rotation;
};

export type PlayPublishOperationMessage = {
  __typename?: 'PlayPublishOperationMessage';
  code?: Maybe<PublishOperationMessageCode>;
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
  play: Play;
  playId: Scalars['ID']['output'];
};

export type PlayReference = {
  __typename?: 'PlayReference';
  playId: Scalars['ID']['output'];
  variantName: Scalars['String']['output'];
};

export type PlayReferenceInput = {
  playId: Scalars['ID']['input'];
  variantName: Scalars['String']['input'];
};

export type PlaySearchInput = {
  __typename?: 'PlaySearchInput';
  downAndDistance: DownAndDistanceType;
  draftVariantCount: Scalars['Int']['output'];
  formationName: Scalars['String']['output'];
  playCreatedAt: Scalars['DateTime']['output'];
  playName: Scalars['String']['output'];
  playUpdatedAt: Scalars['DateTime']['output'];
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  publishedVariantCount: Scalars['Int']['output'];
  stagedVariantCount: Scalars['Int']['output'];
  status: ContentStatus;
};

export type PlaySearchInputDownAndDistanceFilterComparison = {
  eq?: InputMaybe<DownAndDistanceType>;
  in?: InputMaybe<Array<DownAndDistanceType>>;
  neq?: InputMaybe<DownAndDistanceType>;
  notIn?: InputMaybe<Array<DownAndDistanceType>>;
};

export type PlaySearchInputEdge = {
  __typename?: 'PlaySearchInputEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PlaySearchInput */
  node: PlaySearchInput;
};

export type PlaySearchInputFilter = {
  and?: InputMaybe<Array<PlaySearchInputFilter>>;
  downAndDistance?: InputMaybe<PlaySearchInputDownAndDistanceFilterComparison>;
  draftVariantCount?: InputMaybe<IntFieldComparison>;
  formationName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PlaySearchInputFilter>>;
  playCreatedAt?: InputMaybe<DateFieldComparison>;
  playName?: InputMaybe<StringFieldComparison>;
  playUpdatedAt?: InputMaybe<DateFieldComparison>;
  playerLevel?: InputMaybe<PlayerLevelFilterComparison>;
  protection?: InputMaybe<ProtectionTypeFilterComparison>;
  publishedVariantCount?: InputMaybe<IntFieldComparison>;
  stagedVariantCount?: InputMaybe<IntFieldComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  variantDescriptions?: InputMaybe<PlaySearchInputVariantDescriptionsFilterComparison>;
  variantTags?: InputMaybe<PlaySearchInputVariantTagsFilterComparison>;
};

export type PlaySearchInputSort = {
  direction: SortDirection;
  field: PlaySearchInputSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlaySearchInputSortFields {
  DownAndDistance = 'downAndDistance',
  DraftVariantCount = 'draftVariantCount',
  FormationName = 'formationName',
  PlayCreatedAt = 'playCreatedAt',
  PlayName = 'playName',
  PlayUpdatedAt = 'playUpdatedAt',
  PlayerLevel = 'playerLevel',
  Protection = 'protection',
  PublishedVariantCount = 'publishedVariantCount',
  StagedVariantCount = 'stagedVariantCount',
  Status = 'status',
  VariantDescriptions = 'variantDescriptions',
  VariantTags = 'variantTags'
}

export type PlaySearchInputVariantDescriptionsFilterComparison = {
  like?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type PlaySearchInputVariantTagsFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PlaySearchResult = {
  __typename?: 'PlaySearchResult';
  coverageTypes: Array<PlayCoverageTypeSearchResult>;
  downAndDistance: Array<DownAndDistanceType>;
  draftVariantCount: Scalars['Int']['output'];
  formationName: Scalars['String']['output'];
  play: Play;
  playCreatedAt: Scalars['DateTime']['output'];
  playId: Scalars['ID']['output'];
  playName: Scalars['String']['output'];
  playUpdatedAt: Scalars['DateTime']['output'];
  playVariants: PlayVariantConnection;
  playerLevel?: Maybe<PlayerLevel>;
  protection?: Maybe<ProtectionType>;
  publishedVariantCount: Scalars['Int']['output'];
  stagedVariantCount: Scalars['Int']['output'];
  status: ContentStatus;
};


export type PlaySearchResultPlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};

export type PlaySearchResultConnection = {
  __typename?: 'PlaySearchResultConnection';
  /** Array of edges. */
  edges: Array<PlaySearchResultEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PlaySearchResultEdge = {
  __typename?: 'PlaySearchResultEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PlaySearchResult */
  node: PlaySearchResult;
};

export type PlaySelectionCriteria = {
  __typename?: 'PlaySelectionCriteria';
  tags: Array<Scalars['String']['output']>;
};

export type PlaySelectionCriteriaInput = {
  tags: Array<Scalars['String']['input']>;
};

export type PlaySort = {
  direction: SortDirection;
  field: PlaySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlaySortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  DownAndDistance = 'downAndDistance',
  FormationTypeId = 'formationTypeId',
  LastPublishedAt = 'lastPublishedAt',
  PlayName = 'playName',
  PlayerLevel = 'playerLevel',
  Protection = 'protection',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type PlayUpdateBaseInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  /** Identifies which down-and-distance situations this play is applicable for */
  downAndDistance?: InputMaybe<PlayDownAndDistanceTagsInput>;
  drop?: InputMaybe<QbDropType>;
  formationTypeId?: InputMaybe<Scalars['ID']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offensivePlayers?: InputMaybe<Array<OffensivePlayerInput>>;
  playName?: InputMaybe<Scalars['String']['input']>;
  playbookSlides?: InputMaybe<Array<PlaybookSlideCreateInput>>;
  playerLevel?: InputMaybe<PlayerLevel>;
  protection?: InputMaybe<ProtectionType>;
  qbPlacement?: InputMaybe<QbAlignmentType>;
  whiteboardImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type PlayUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  diagramImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  /** Identifies which down-and-distance situations this play is applicable for */
  downAndDistance?: InputMaybe<PlayDownAndDistanceTagsInput>;
  drop?: InputMaybe<QbDropType>;
  formationTypeId?: InputMaybe<Scalars['ID']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  offensivePlayers?: InputMaybe<Array<OffensivePlayerInput>>;
  playName?: InputMaybe<Scalars['String']['input']>;
  playbookSlides: Array<PlaybookSlideUpdateInput>;
  playerLevel?: InputMaybe<PlayerLevel>;
  protection?: InputMaybe<ProtectionType>;
  qbPlacement?: InputMaybe<QbAlignmentType>;
  variants: Array<PlayUpdatePlayVariantInput>;
  whiteboardImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type PlayUpdatePlayVariantInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  variant: PlayVariantUpdateInput;
};

export type PlayVariant = {
  __typename?: 'PlayVariant';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  catchTimeMS: Scalars['Int']['output'];
  coverageType?: Maybe<CoverageType>;
  coverageTypeId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  decisionTimeMS: Scalars['Int']['output'];
  defensivePlayers: Array<DefensivePlayer>;
  hasMirror: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  play: Play;
  playId: Scalars['ID']['output'];
  playVersion: Scalars['Int']['output'];
  playerRoutes: Array<PlayerRoute>;
  /** Variant-specific settings for the Players in the variant */
  playerSettings: Array<PlayerSettings>;
  predefinedTags: PlayVariantPredefinedTags;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results: Array<ReceiverOption>;
  sackTimeMS: Scalars['Int']['output'];
  status: ContentStatus;
  tags: Array<MetadataTag>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantDescription: Scalars['String']['output'];
  variantName: Scalars['String']['output'];
  variantNumber: Scalars['Int']['output'];
};

export type PlayVariantAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PlayVariantAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  coverageTypeId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<PlayVariantAggregateFilter>>;
  playId?: InputMaybe<IdFilterComparison>;
  predefinedTags?: InputMaybe<PlayVariantPredefinedTagsFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  variantName?: InputMaybe<StringFieldComparison>;
  variantNumber?: InputMaybe<IntFieldComparison>;
};

export type PlayVariantAggregateGroupBy = {
  __typename?: 'PlayVariantAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlayVariantAggregateResponse = {
  __typename?: 'PlayVariantAggregateResponse';
  avg?: Maybe<PlayVariantAvgAggregate>;
  count?: Maybe<PlayVariantCountAggregate>;
  groupBy?: Maybe<PlayVariantAggregateGroupBy>;
  max?: Maybe<PlayVariantMaxAggregate>;
  min?: Maybe<PlayVariantMinAggregate>;
  sum?: Maybe<PlayVariantSumAggregate>;
};

export type PlayVariantAvgAggregate = {
  __typename?: 'PlayVariantAvgAggregate';
  variantNumber?: Maybe<Scalars['Float']['output']>;
};

export type PlayVariantConnection = {
  __typename?: 'PlayVariantConnection';
  /** Array of edges. */
  edges: Array<PlayVariantEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PlayVariantCountAggregate = {
  __typename?: 'PlayVariantCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  coverageTypeId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  playId?: Maybe<Scalars['Int']['output']>;
  predefinedTags?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  variantName?: Maybe<Scalars['Int']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlayVariantCreateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  catchTimeMS: Scalars['Int']['input'];
  coverageTypeId: Scalars['ID']['input'];
  decisionTimeMS: Scalars['Int']['input'];
  defensivePlayers: Array<DefensivePlayerInput>;
  hasMirror: Scalars['Boolean']['input'];
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  playId: Scalars['ID']['input'];
  playVersion: Scalars['Int']['input'];
  playerRoutes: Array<PlayerRouteInput>;
  /** Variant-specific settings for the Players in the variant */
  playerSettings: Array<PlayerSettingsInput>;
  predefinedTags: PlayVariantPredefinedTagsInput;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results: Array<ReceiverOptionInput>;
  sackTimeMS: Scalars['Int']['input'];
  status: ContentStatus;
  tags: Array<MetadataTagInput>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  variantDescription: Scalars['String']['input'];
  variantName: Scalars['String']['input'];
  variantNumber: Scalars['Int']['input'];
};

export type PlayVariantCreateWithPlayInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  catchTimeMS: Scalars['Int']['input'];
  coverageTypeId: Scalars['ID']['input'];
  decisionTimeMS: Scalars['Int']['input'];
  defensivePlayers: Array<DefensivePlayerInput>;
  hasMirror: Scalars['Boolean']['input'];
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  playerRoutes: Array<PlayerRouteInput>;
  /** Variant-specific settings for the Players in the variant */
  playerSettings: Array<PlayerSettingsInput>;
  predefinedTags: PlayVariantPredefinedTagsInput;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results: Array<ReceiverOptionInput>;
  sackTimeMS: Scalars['Int']['input'];
  status: ContentStatus;
  tags: Array<MetadataTagInput>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  variantDescription: Scalars['String']['input'];
  variantName: Scalars['String']['input'];
  variantNumber: Scalars['Int']['input'];
};

export type PlayVariantEdge = {
  __typename?: 'PlayVariantEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PlayVariant */
  node: PlayVariant;
};

export type PlayVariantFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PlayVariantFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  coverageTypeId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<PlayVariantFilter>>;
  playId?: InputMaybe<IdFilterComparison>;
  predefinedTags?: InputMaybe<PlayVariantPredefinedTagsFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  variantName?: InputMaybe<StringFieldComparison>;
  variantNumber?: InputMaybe<IntFieldComparison>;
};

export type PlayVariantMaxAggregate = {
  __typename?: 'PlayVariantMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlayVariantMinAggregate = {
  __typename?: 'PlayVariantMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  coverageTypeId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  playId?: Maybe<Scalars['ID']['output']>;
  predefinedTags?: Maybe<PlayVariantPredefinedTags>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
  variantNumber?: Maybe<Scalars['Int']['output']>;
};

export type PlayVariantPredefinedTags = {
  __typename?: 'PlayVariantPredefinedTags';
  /** Predefined field automatically inferred by whether or not a defender exists in the variant with a "blitz" path type.  Can be manually overridden by the Play author. */
  blitz: Scalars['Boolean']['output'];
  /** An indicator that the automated blitz tag calculation has been overridden by the Play author.  When this value exists, it takes precedence over the blitz field */
  blitzOverride?: Maybe<Scalars['Boolean']['output']>;
  /** Predefined tag manually applied by the Play author to indicate the Play Variant represents a situation that requires the Quarterback to improvise because of the defensive behavior */
  offSchedule: Scalars['Boolean']['output'];
};

export type PlayVariantPredefinedTagsFilterComparison = {
  eq?: InputMaybe<PlayVariantPredefinedTagsInput>;
  gt?: InputMaybe<PlayVariantPredefinedTagsInput>;
  gte?: InputMaybe<PlayVariantPredefinedTagsInput>;
  iLike?: InputMaybe<PlayVariantPredefinedTagsInput>;
  in?: InputMaybe<Array<PlayVariantPredefinedTagsInput>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PlayVariantPredefinedTagsInput>;
  lt?: InputMaybe<PlayVariantPredefinedTagsInput>;
  lte?: InputMaybe<PlayVariantPredefinedTagsInput>;
  neq?: InputMaybe<PlayVariantPredefinedTagsInput>;
  notILike?: InputMaybe<PlayVariantPredefinedTagsInput>;
  notIn?: InputMaybe<Array<PlayVariantPredefinedTagsInput>>;
  notLike?: InputMaybe<PlayVariantPredefinedTagsInput>;
};

export type PlayVariantPredefinedTagsInput = {
  /** Predefined field automatically inferred by whether or not a defender exists in the variant with a "blitz" path type.  Can be manually overridden by the Play author. */
  blitz: Scalars['Boolean']['input'];
  /** An indicator that the automated blitz tag calculation has been overridden by the Play author.  When this value exists, it takes precedence over the blitz field */
  blitzOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Predefined tag manually applied by the Play author to indicate the Play Variant represents a situation that requires the Quarterback to improvise because of the defensive behavior */
  offSchedule: Scalars['Boolean']['input'];
};

export type PlayVariantPublishOperationMessage = {
  __typename?: 'PlayVariantPublishOperationMessage';
  code?: Maybe<PublishOperationMessageCode>;
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
  playVariant: PlayVariant;
  playVariantId: Scalars['ID']['output'];
};

export type PlayVariantSort = {
  direction: SortDirection;
  field: PlayVariantSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PlayVariantSortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CoverageTypeId = 'coverageTypeId',
  CreatedAt = 'createdAt',
  LastPublishedAt = 'lastPublishedAt',
  PlayId = 'playId',
  PredefinedTags = 'predefinedTags',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  VariantName = 'variantName',
  VariantNumber = 'variantNumber'
}

export type PlayVariantSumAggregate = {
  __typename?: 'PlayVariantSumAggregate';
  variantNumber?: Maybe<Scalars['Float']['output']>;
};

export type PlayVariantUpdateInput = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  catchTimeMS?: InputMaybe<Scalars['Int']['input']>;
  coverageTypeId?: InputMaybe<Scalars['ID']['input']>;
  decisionTimeMS?: InputMaybe<Scalars['Int']['input']>;
  defensivePlayers?: InputMaybe<Array<DefensivePlayerInput>>;
  hasMirror?: InputMaybe<Scalars['Boolean']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  playId?: InputMaybe<Scalars['ID']['input']>;
  playVersion?: InputMaybe<Scalars['Int']['input']>;
  playerRoutes?: InputMaybe<Array<PlayerRouteInput>>;
  /** Variant-specific settings for the Players in the variant */
  playerSettings?: InputMaybe<Array<PlayerSettingsInput>>;
  predefinedTags?: InputMaybe<PlayVariantPredefinedTagsInput>;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results?: InputMaybe<Array<ReceiverOptionInput>>;
  sackTimeMS?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentStatus>;
  tags?: InputMaybe<Array<MetadataTagInput>>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  variantDescription?: InputMaybe<Scalars['String']['input']>;
  variantName?: InputMaybe<Scalars['String']['input']>;
  variantNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type PlaybackLessonModule = LessonModule & {
  __typename?: 'PlaybackLessonModule';
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** @deprecated Field moduleType can be used to determine interactivity */
  interactive?: Maybe<Scalars['Boolean']['output']>;
  moduleType: LessonModuleType;
  order?: Maybe<Scalars['Int']['output']>;
  /** @deprecated Use sandboxPlayId field instead. */
  playId?: Maybe<Scalars['ID']['output']>;
  sandboxPlay?: Maybe<SandboxPlay>;
  sandboxPlayId: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** @deprecated Use sandboxPlayId field instead. */
  variantName?: Maybe<Scalars['String']['output']>;
};

export type PlaybackLessonModuleCreateInput = {
  description: Scalars['String']['input'];
  interactive?: InputMaybe<Scalars['Boolean']['input']>;
  lesson: Scalars['ID']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  playId?: InputMaybe<Scalars['ID']['input']>;
  sandboxPlayId: Scalars['ID']['input'];
  title: Scalars['String']['input'];
  variantName?: InputMaybe<Scalars['String']['input']>;
};

export type PlaybookSlide = {
  __typename?: 'PlaybookSlide';
  backgroundImageAssetKey: Scalars['String']['output'];
  backgroundImageUrl?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  title: Scalars['String']['output'];
};

export type PlaybookSlideCreateInput = {
  backgroundImageAssetKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type PlaybookSlideUpdateInput = {
  backgroundImageAssetKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  title: Scalars['String']['input'];
};

export type PlayerCustomization = {
  __typename?: 'PlayerCustomization';
  jerseyColor?: Maybe<JerseyColor>;
  jerseyNumber?: Maybe<Scalars['String']['output']>;
  nameplate?: Maybe<Scalars['String']['output']>;
  skinTone?: Maybe<SkinTone>;
};

export type PlayerCustomizationInput = {
  jerseyColor?: InputMaybe<JerseyColor>;
  jerseyNumber?: InputMaybe<Scalars['String']['input']>;
  nameplate?: InputMaybe<Scalars['String']['input']>;
  skinTone?: InputMaybe<SkinTone>;
};

/** Player difficulty level for game play */
export enum PlayerLevel {
  /** All Levels */
  All = 'All',
  JuniorVarsity = 'JuniorVarsity',
  /** Varsity */
  Varsity = 'Varsity'
}

export type PlayerLevelFilterComparison = {
  eq?: InputMaybe<PlayerLevel>;
  gt?: InputMaybe<PlayerLevel>;
  gte?: InputMaybe<PlayerLevel>;
  iLike?: InputMaybe<PlayerLevel>;
  in?: InputMaybe<Array<PlayerLevel>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PlayerLevel>;
  lt?: InputMaybe<PlayerLevel>;
  lte?: InputMaybe<PlayerLevel>;
  neq?: InputMaybe<PlayerLevel>;
  notILike?: InputMaybe<PlayerLevel>;
  notIn?: InputMaybe<Array<PlayerLevel>>;
  notLike?: InputMaybe<PlayerLevel>;
};

/** Type of position for a football player in the game. */
export enum PlayerPositionType {
  Center = 'Center',
  Cornerback = 'Cornerback',
  /** Generic defensive player position */
  Defender = 'Defender',
  DefensiveEnd = 'DefensiveEnd',
  /** Generic defensive lineman position */
  DefensiveLineman = 'DefensiveLineman',
  DefensiveTackle = 'DefensiveTackle',
  LeftGuard = 'LeftGuard',
  LeftTackle = 'LeftTackle',
  Linebacker = 'Linebacker',
  /** Generic Offensive Lineman position */
  OffensiveLineman = 'OffensiveLineman',
  OutsideLinebacker = 'OutsideLinebacker',
  Quarterback = 'Quarterback',
  /** Generic offensive receiver position (eligible receiver) */
  Receiver = 'Receiver',
  RightGuard = 'RightGuard',
  RightTackle = 'RightTackle',
  RunningBack = 'RunningBack',
  Safety = 'Safety',
  TightEnd = 'TightEnd',
  WideReceiver = 'WideReceiver'
}

export type PlayerRoute = {
  __typename?: 'PlayerRoute';
  endOffenseTrackPlayerId: Scalars['String']['output'];
  endType: RouteEndType;
  firstPointIndex: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  playerId: Scalars['String']['output'];
  routeItems: Array<RouteSegment>;
  speedOverride: Scalars['Float']['output'];
};

export type PlayerRouteInput = {
  endOffenseTrackPlayerId: Scalars['String']['input'];
  endType: RouteEndType;
  firstPointIndex: Scalars['Int']['input'];
  id: Scalars['String']['input'];
  playerId: Scalars['String']['input'];
  routeItems: Array<RouteSegmentInput>;
  speedOverride: Scalars['Float']['input'];
};

/** Player settings at the Play Variant level.  This allows setting different values for the same player across multiple variants */
export type PlayerSettings = {
  __typename?: 'PlayerSettings';
  /** The id of the Player the settings apply to */
  playerId: Scalars['String']['output'];
  /** The base speed for the player. May be adjusted by a speedOverride modifier on a route the player is running. */
  speed: Scalars['Float']['output'];
};

export type PlayerSettingsInput = {
  /** The id of the Player the settings apply to */
  playerId: Scalars['String']['input'];
  /** The base speed for the player. May be adjusted by a speedOverride modifier on a route the player is running. */
  speed: Scalars['Float']['input'];
};

export type PlayerStatsItem = {
  __typename?: 'PlayerStatsItem';
  /** Users total interceptions */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Users total attempts */
  att?: Maybe<Scalars['Float']['output']>;
  /** Users best Coverage */
  bestCoverage?: Maybe<BestCoverage>;
  /** Users best offense */
  bestOffense?: Maybe<BestOffense>;
  /** Users ci score */
  ci?: Maybe<Scalars['Float']['output']>;
  /** Users total checkdowns */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Users total checkdowns percent */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Users completions */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Users completion percentage */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** All coverages user has played against */
  coverage?: Maybe<Array<BestCoverage>>;
  /** Users email */
  email?: Maybe<Scalars['String']['output']>;
  /** Users engagement */
  eng?: Maybe<Scalars['Float']['output']>;
  /** Users first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Users total games played */
  g?: Maybe<Scalars['Float']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** Users last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Users late throws */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** Users late throw percentage */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Users ng */
  ng?: Maybe<Scalars['Float']['output']>;
  /** All offenses user has played against */
  offense?: Maybe<Array<BestOffense>>;
  /** Users optimal throws */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Users optimal throws percent */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** Users name */
  playerName?: Maybe<Scalars['String']['output']>;
  /** Users pos */
  pos?: Maybe<Scalars['Float']['output']>;
  /** Users rank */
  rank?: Maybe<Scalars['Float']['output']>;
  /** Users total sacks */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
  /** Users streak */
  strks?: Maybe<Scalars['Float']['output']>;
  /** Users streak percentage */
  strkspercent?: Maybe<Scalars['Float']['output']>;
  /** Users team(s) */
  team?: Maybe<Scalars['String']['output']>;
  /** Users username */
  username?: Maybe<Scalars['String']['output']>;
  /** All offenses user has played against */
  variants?: Maybe<Array<Variants>>;
  /** Users worst coverage */
  worstCoverage?: Maybe<BestCoverage>;
  /** Users worst offense */
  worstOffense?: Maybe<BestOffense>;
};

export type Point = {
  __typename?: 'Point';
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z?: Maybe<Scalars['Float']['output']>;
};

export type PointInput = {
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z?: InputMaybe<Scalars['Float']['input']>;
};

/** A protection type identifies how the offense will block rushing defenders to protect the quarterback and is often described by the number of blockers used. */
export enum ProtectionType {
  /** Five man protection type */
  FiveMan = 'FIVE_MAN',
  /** Seven man protection type */
  SevenMan = 'SEVEN_MAN',
  /** Six man protection type */
  SixMan = 'SIX_MAN'
}

export type ProtectionTypeFilterComparison = {
  eq?: InputMaybe<ProtectionType>;
  gt?: InputMaybe<ProtectionType>;
  gte?: InputMaybe<ProtectionType>;
  iLike?: InputMaybe<ProtectionType>;
  in?: InputMaybe<Array<ProtectionType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ProtectionType>;
  lt?: InputMaybe<ProtectionType>;
  lte?: InputMaybe<ProtectionType>;
  neq?: InputMaybe<ProtectionType>;
  notILike?: InputMaybe<ProtectionType>;
  notIn?: InputMaybe<Array<ProtectionType>>;
  notLike?: InputMaybe<ProtectionType>;
};

/** Severity of messages in a publish operation that provide a detailed description of the result, particularly in case of publish failure */
export enum PublishMessageLevel {
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export type PublishOperation = {
  __typename?: 'PublishOperation';
  createdAt: Scalars['DateTime']['output'];
  dryRun: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** The username of the user who initiated the publish operation */
  initiatedBy: Scalars['String']['output'];
  messages: Array<PublishOperationMessageUnion>;
  operationType: PublishOperationType;
  publishedCoverageTypes: CoverageTypeConnection;
  publishedFormationTypes: FormationTypeConnection;
  publishedPlayVariants: PlayVariantConnection;
  publishedPlays: PlayConnection;
  status: PublishOperationStatus;
  unPublishedCoverageTypes: CoverageTypeConnection;
  unPublishedFormationTypes: FormationTypeConnection;
  unPublishedPlayVariants: PlayVariantConnection;
  unPublishedPlays: PlayConnection;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type PublishOperationPublishedCoverageTypesArgs = {
  filter?: CoverageTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<CoverageTypeSort>;
};


export type PublishOperationPublishedFormationTypesArgs = {
  filter?: FormationTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<FormationTypeSort>;
};


export type PublishOperationPublishedPlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};


export type PublishOperationPublishedPlaysArgs = {
  filter?: PlayFilter;
  paging?: CursorPaging;
  sorting?: Array<PlaySort>;
};


export type PublishOperationUnPublishedCoverageTypesArgs = {
  filter?: CoverageTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<CoverageTypeSort>;
};


export type PublishOperationUnPublishedFormationTypesArgs = {
  filter?: FormationTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<FormationTypeSort>;
};


export type PublishOperationUnPublishedPlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};


export type PublishOperationUnPublishedPlaysArgs = {
  filter?: PlayFilter;
  paging?: CursorPaging;
  sorting?: Array<PlaySort>;
};

export type PublishOperationConnection = {
  __typename?: 'PublishOperationConnection';
  /** Array of edges. */
  edges: Array<PublishOperationEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type PublishOperationEdge = {
  __typename?: 'PublishOperationEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the PublishOperation */
  node: PublishOperation;
};

export type PublishOperationErrorMessage = {
  __typename?: 'PublishOperationErrorMessage';
  code?: Maybe<PublishOperationMessageCode>;
  level: PublishMessageLevel;
  message: Scalars['String']['output'];
};

export type PublishOperationFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<PublishOperationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  initiatedBy?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<PublishOperationFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

/** A code that may accompany a PublishOperationMessage to help categorize the type of error, warning, or information */
export enum PublishOperationMessageCode {
  /** The referenced entity cannot be demoted because it would cause a Lesson Quiz to have an insufficient count of Play Variants. */
  InsufficientQuizPlaySelection = 'INSUFFICIENT_QUIZ_PLAY_SELECTION',
  /** The referenced entity cannot be published because it is missing required learning materials. */
  MissingMaterials = 'MISSING_MATERIALS',
  /** The referenced entity cannot be published because it is not in the requisite status for publishing. */
  NotStaged = 'NOT_STAGED',
  /** The referenced entity was automatically unpublished because it no longer had any published child references.  e.g. Play without any published Play Variants. */
  NoPublishedChildren = 'NO_PUBLISHED_CHILDREN'
}

export type PublishOperationMessageUnion = CoverageTypePublishOperationMessage | FormationTypePublishOperationMessage | LessonPublishOperationMessage | PlayPublishOperationMessage | PlayVariantPublishOperationMessage | PublishOperationErrorMessage;

export type PublishOperationSort = {
  direction: SortDirection;
  field: PublishOperationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PublishOperationSortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  InitiatedBy = 'initiatedBy',
  UpdatedAt = 'updatedAt'
}

/** The outcome of a publish operation */
export enum PublishOperationStatus {
  /** One or more errors was encountered during a publish operation and the action was aborted. Check the messages field for more details. */
  Failed = 'FAILED',
  /** The publish operation is in progress */
  InProgress = 'IN_PROGRESS',
  /** The publish operation was successful */
  Success = 'SUCCESS'
}

/** The type of a publish operation */
export enum PublishOperationType {
  Publish = 'PUBLISH',
  UnPublish = 'UN_PUBLISH'
}

/** The alignment of a QB in an offensive formation with relation to the Center position */
export enum QbAlignmentType {
  Pistol = 'Pistol',
  Shotgun = 'Shotgun',
  UnderCenter = 'UnderCenter'
}

export type QbAlignmentTypeFilterComparison = {
  eq?: InputMaybe<QbAlignmentType>;
  gt?: InputMaybe<QbAlignmentType>;
  gte?: InputMaybe<QbAlignmentType>;
  iLike?: InputMaybe<QbAlignmentType>;
  in?: InputMaybe<Array<QbAlignmentType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<QbAlignmentType>;
  lt?: InputMaybe<QbAlignmentType>;
  lte?: InputMaybe<QbAlignmentType>;
  neq?: InputMaybe<QbAlignmentType>;
  notILike?: InputMaybe<QbAlignmentType>;
  notIn?: InputMaybe<Array<QbAlignmentType>>;
  notLike?: InputMaybe<QbAlignmentType>;
};

/** The number of backward steps the Quarterback will take after the snap and before setting his feet to throw */
export enum QbDropType {
  FiveStep = 'FiveStep',
  OneStep = 'OneStep',
  SevenStep = 'SevenStep',
  ThreeStep = 'ThreeStep'
}

export type QbDropTypeFilterComparison = {
  eq?: InputMaybe<QbDropType>;
  gt?: InputMaybe<QbDropType>;
  gte?: InputMaybe<QbDropType>;
  iLike?: InputMaybe<QbDropType>;
  in?: InputMaybe<Array<QbDropType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<QbDropType>;
  lt?: InputMaybe<QbDropType>;
  lte?: InputMaybe<QbDropType>;
  neq?: InputMaybe<QbDropType>;
  notILike?: InputMaybe<QbDropType>;
  notIn?: InputMaybe<Array<QbDropType>>;
  notLike?: InputMaybe<QbDropType>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieves the currently authenticated user */
  adminSelf: User;
  announcement: Announcement;
  announcements: AnnouncementConnection;
  checkClientRequirements: ClientRequirements;
  coverage: Coverage;
  coverageAggregate: Array<CoverageAggregateResponse>;
  coverageType: CoverageType;
  coverageTypeAggregate: Array<CoverageTypeAggregateResponse>;
  coverageTypes: CoverageTypeConnection;
  coverages: CoverageConnection;
  drop: Drop;
  dropAggregate: Array<DropAggregateResponse>;
  drops: DropConnection;
  emailTemplate: EmailTemplate;
  emailTemplates: EmailTemplateConnection;
  footlingoCategories: FootlingoCategoryConnection;
  footlingoCategory: FootlingoCategory;
  footlingoLesson: FootlingoLesson;
  footlingoLessons: FootlingoLessonConnection;
  formation: Formation;
  formationAggregate: Array<FormationAggregateResponse>;
  formationType: FormationType;
  formationTypes: FormationTypeConnection;
  formations: FormationConnection;
  getFootlingoLessons: Array<FootlingoLessonResponse>;
  getFootlingoLessonsWithImageUrls: Array<FootlingoLessonResponse>;
  getFootlingoTree: Array<FootlingoTreeResponse>;
  leaderboard: Leaderboard;
  leaderboardSummary: LeaderboardSummary;
  lesson: Lesson;
  lessonCategories: LessonCategoryConnection;
  lessonCategory: LessonCategory;
  lessons: LessonConnection;
  play: Play;
  playAggregate: Array<PlayAggregateResponse>;
  playSearch: PlaySearchResultConnection;
  playVariant: PlayVariant;
  playVariantAggregate: Array<PlayVariantAggregateResponse>;
  playVariants: PlayVariantConnection;
  player: Player;
  playerAnnouncements: AnnouncementConnection;
  /** DEPRECATED: use playsV2 query instead */
  plays: Array<Play>;
  playsV2: PlayConnection;
  publishOperation: PublishOperation;
  publishOperations: PublishOperationConnection;
  routeStencil: RouteStencil;
  routeStencilAggregate: Array<RouteStencilAggregateResponse>;
  routeStencilFolder: RouteStencilFolder;
  routeStencilFolders: RouteStencilFolderConnection;
  routeStencils: RouteStencilConnection;
  sampleGamePlays: Array<GameSessionPlay>;
  sandboxPlay: SandboxPlay;
  sandboxPlayAggregate: Array<SandboxPlayAggregateResponse>;
  sandboxPlays: SandboxPlayConnection;
  /** Retrieves the currently authenticated user */
  self: User;
  userInvitationCode: UserInvitationCode;
  userInvitationCodes: UserInvitationCodeConnection;
  userNameCheck: UserNameCheckResponse;
};


export type QueryAnnouncementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAnnouncementsArgs = {
  filter?: AnnouncementFilter;
  paging?: CursorPaging;
  sorting?: Array<AnnouncementSort>;
};


export type QueryCoverageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCoverageAggregateArgs = {
  filter?: InputMaybe<CoverageAggregateFilter>;
};


export type QueryCoverageTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCoverageTypeAggregateArgs = {
  filter?: InputMaybe<CoverageTypeAggregateFilter>;
};


export type QueryCoverageTypesArgs = {
  filter?: CoverageTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<CoverageTypeSort>;
};


export type QueryCoveragesArgs = {
  filter?: CoverageFilter;
  paging?: CursorPaging;
  sorting?: Array<CoverageSort>;
};


export type QueryDropArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDropAggregateArgs = {
  filter?: InputMaybe<DropAggregateFilter>;
};


export type QueryDropsArgs = {
  filter?: DropFilter;
  paging?: CursorPaging;
  sorting?: Array<DropSort>;
};


export type QueryEmailTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEmailTemplatesArgs = {
  filter?: EmailTemplateFilter;
  paging?: CursorPaging;
  sorting?: Array<EmailTemplateSort>;
};


export type QueryFootlingoCategoriesArgs = {
  filter?: FootlingoCategoryFilter;
  paging?: CursorPaging;
  sorting?: Array<FootlingoCategorySort>;
};


export type QueryFootlingoCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFootlingoLessonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFootlingoLessonsArgs = {
  filter?: FootlingoLessonFilter;
  paging?: CursorPaging;
  sorting?: Array<FootlingoLessonSort>;
};


export type QueryFormationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormationAggregateArgs = {
  filter?: InputMaybe<FormationAggregateFilter>;
};


export type QueryFormationTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormationTypesArgs = {
  filter?: FormationTypeFilter;
  paging?: CursorPaging;
  sorting?: Array<FormationTypeSort>;
};


export type QueryFormationsArgs = {
  filter?: FormationFilter;
  paging?: CursorPaging;
  sorting?: Array<FormationSort>;
};


export type QueryGetFootlingoTreeArgs = {
  statusType?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLeaderboardArgs = {
  contextId?: InputMaybe<Scalars['String']['input']>;
  mode: GamePlayMode;
};


export type QueryLessonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLessonCategoriesArgs = {
  filter?: LessonCategoryFilter;
  paging?: CursorPaging;
  sorting?: Array<LessonCategorySort>;
};


export type QueryLessonCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLessonsArgs = {
  filter?: LessonFilter;
  paging?: CursorPaging;
  sorting?: Array<LessonSort>;
};


export type QueryPlayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlayAggregateArgs = {
  filter?: InputMaybe<PlayAggregateFilter>;
};


export type QueryPlaySearchArgs = {
  coverageTypeFilter?: InputMaybe<PlayCoverageTypeFilter>;
  filter?: PlaySearchInputFilter;
  paging?: CursorPaging;
  sorting?: Array<PlaySearchInputSort>;
};


export type QueryPlayVariantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPlayVariantAggregateArgs = {
  filter?: InputMaybe<PlayVariantAggregateFilter>;
};


export type QueryPlayVariantsArgs = {
  filter?: PlayVariantFilter;
  paging?: CursorPaging;
  sorting?: Array<PlayVariantSort>;
};


export type QueryPlayerAnnouncementsArgs = {
  filter?: AnnouncementFilter;
  paging?: CursorPaging;
  sorting?: Array<AnnouncementSort>;
};


export type QueryPlaysV2Args = {
  filter?: PlayFilter;
  paging?: CursorPaging;
  playFilterExtra?: InputMaybe<PlayFilterExtraInput>;
  sorting?: Array<PlaySort>;
};


export type QueryPublishOperationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPublishOperationsArgs = {
  filter?: PublishOperationFilter;
  paging?: CursorPaging;
  sorting?: Array<PublishOperationSort>;
};


export type QueryRouteStencilArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteStencilAggregateArgs = {
  filter?: InputMaybe<RouteStencilAggregateFilter>;
};


export type QueryRouteStencilFolderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRouteStencilFoldersArgs = {
  filter?: RouteStencilFolderFilter;
  paging?: CursorPaging;
  sorting?: Array<RouteStencilFolderSort>;
};


export type QueryRouteStencilsArgs = {
  filter?: RouteStencilFilter;
  paging?: CursorPaging;
  sorting?: Array<RouteStencilSort>;
};


export type QuerySandboxPlayArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySandboxPlayAggregateArgs = {
  filter?: InputMaybe<SandboxPlayAggregateFilter>;
};


export type QuerySandboxPlaysArgs = {
  filter?: SandboxPlayFilter;
  paging?: CursorPaging;
  sorting?: Array<SandboxPlaySort>;
};


export type QueryUserInvitationCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserInvitationCodesArgs = {
  filter?: UserInvitationCodeFilter;
  paging?: CursorPaging;
  sorting?: Array<UserInvitationCodeSort>;
};


export type QueryUserNameCheckArgs = {
  username: Scalars['String']['input'];
};

/** Describes the quiz availability states. */
export enum QuizAvailability {
  /** Quiz is available for progression. */
  Available = 'Available',
  /** Quiz progression is locked due to uncompleted materials requirements. */
  LockedMaterialsRequired = 'LockedMaterialsRequired',
  /** Quiz progression is locked due to failing too many times. */
  LockedMaxAttempts = 'LockedMaxAttempts',
  /** No quiz is available. */
  None = 'None'
}

export type QuizScoringCriteria = {
  __typename?: 'QuizScoringCriteria';
  starOneRequiredOptimalDecisions: Scalars['Int']['output'];
  starThreeRequiredOptimalDecisions: Scalars['Int']['output'];
  starThreeRequiredPerfectDecisions: Scalars['Int']['output'];
  starTwoRequiredOptimalDecisions: Scalars['Int']['output'];
  starTwoRequiredPerfectDecisions: Scalars['Int']['output'];
};

export type QuizSession = {
  __typename?: 'QuizSession';
  createdAt: Scalars['DateTime']['output'];
  endedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  mode: GamePlayMode;
  modeArgs?: Maybe<GameModeArgs>;
  plays: Array<GameSessionPlay>;
  score?: Maybe<Scalars['Float']['output']>;
  speedDifficulty?: Maybe<Scalars['Float']['output']>;
  status: GameSessionStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
};

export type RankOffsetInfo = {
  __typename?: 'RankOffsetInfo';
  flankingCount: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  maxOffset: Scalars['Int']['output'];
  startOffset: Scalars['Int']['output'];
  userOffset: Scalars['Int']['output'];
};

export type RankSnapshot = {
  __typename?: 'RankSnapshot';
  contextId?: Maybe<Scalars['String']['output']>;
  leaders: Array<UserRankDetails>;
  mode: GamePlayMode;
  totalRanks: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: UserRankDetails;
};

export type ReceiverOption = {
  __typename?: 'ReceiverOption';
  outcome: PlayDecisionOutcome;
  perfectTimeMS?: Maybe<Scalars['Int']['output']>;
  receiverId: Scalars['String']['output'];
};

export type ReceiverOptionInput = {
  outcome: PlayDecisionOutcome;
  perfectTimeMS?: InputMaybe<Scalars['Int']['input']>;
  receiverId: Scalars['String']['input'];
};

export type ReportPlayerInput = {
  gameSessionId?: InputMaybe<Scalars['String']['input']>;
  offensivePlayerName: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};

export type ResultResponse = {
  __typename?: 'ResultResponse';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type Rotation = {
  __typename?: 'Rotation';
  w: Scalars['Float']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
  z: Scalars['Float']['output'];
};

export type RotationInput = {
  w: Scalars['Float']['input'];
  x: Scalars['Float']['input'];
  y: Scalars['Float']['input'];
  z: Scalars['Float']['input'];
};

/** The type of behavior a player will carry out once completing movement through the route segments */
export enum RouteEndType {
  /** Applicable only to defensive players: Upon completing any defined route segments, the player will run a direct path toward the quarterback. */
  Blitz = 'Blitz',
  /** Applicable only to offensive players: Upon completing any defined route segments, the player will move to intercept a designated defender and block them from reaching the quarterback */
  Block = 'Block',
  /** Applicable only to defensive players: Upon completing any defined route segments the defender will move to intercept and remain alongside a designated offensive player */
  ManTracking = 'ManTracking',
  /** The player will continue running in the direction of the last route segment until reaching a field boundary */
  Run = 'Run',
  /** The player will run until reaching the end of the last route segment, at which point he will stop and turn toward the quarterback */
  RunAndStop = 'RunAndStop'
}

export type RouteSegment = {
  __typename?: 'RouteSegment';
  curveMidpoint: Point;
  movementType: Scalars['Int']['output'];
  routeType: Scalars['Int']['output'];
  targetPoint: Point;
  targetSpeedModifier: Scalars['Float']['output'];
  throttle: Scalars['Boolean']['output'];
  waitTime: Scalars['Float']['output'];
};

export type RouteSegmentInput = {
  curveMidpoint: PointInput;
  movementType: Scalars['Int']['input'];
  routeType: Scalars['Int']['input'];
  targetPoint: PointInput;
  targetSpeedModifier: Scalars['Float']['input'];
  throttle: Scalars['Boolean']['input'];
  waitTime: Scalars['Float']['input'];
};

export type RouteStencil = {
  __typename?: 'RouteStencil';
  createdAt: Scalars['DateTime']['output'];
  folder: RouteStencilFolder;
  folderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  points: Array<RouteSegment>;
  routeEndType: RouteEndType;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<RouteStencilAggregateFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  folderId?: InputMaybe<RouteStencilFolderIdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RouteStencilAggregateFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type RouteStencilAggregateGroupBy = {
  __typename?: 'RouteStencilAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  folderId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilAggregateResponse = {
  __typename?: 'RouteStencilAggregateResponse';
  count?: Maybe<RouteStencilCountAggregate>;
  groupBy?: Maybe<RouteStencilAggregateGroupBy>;
  max?: Maybe<RouteStencilMaxAggregate>;
  min?: Maybe<RouteStencilMinAggregate>;
};

export type RouteStencilConnection = {
  __typename?: 'RouteStencilConnection';
  /** Array of edges. */
  edges: Array<RouteStencilEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type RouteStencilCountAggregate = {
  __typename?: 'RouteStencilCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  folderId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type RouteStencilCreateInput = {
  /** The record to create */
  routeStencil: RouteStencilCreateInputType;
};

export type RouteStencilCreateInputType = {
  folderId: Scalars['ID']['input'];
  /** Specify a name for the new stencil, or omit this field to have the server generate a unique name */
  name?: InputMaybe<Scalars['String']['input']>;
  points: Array<RouteSegmentInput>;
  routeEndType: RouteEndType;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type RouteStencilDeleteResponse = {
  __typename?: 'RouteStencilDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  folderId?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  points?: Maybe<Array<RouteSegment>>;
  routeEndType?: Maybe<RouteEndType>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilEdge = {
  __typename?: 'RouteStencilEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the RouteStencil */
  node: RouteStencil;
};

export type RouteStencilFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<RouteStencilFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  folderId?: InputMaybe<RouteStencilFolderIdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RouteStencilFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type RouteStencilFolder = {
  __typename?: 'RouteStencilFolder';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  routeStencils: RouteStencilFolderRouteStencilsConnection;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type RouteStencilFolderRouteStencilsArgs = {
  filter?: RouteStencilFilter;
  paging?: CursorPaging;
  sorting?: Array<RouteStencilSort>;
};

export type RouteStencilFolderConnection = {
  __typename?: 'RouteStencilFolderConnection';
  /** Array of edges. */
  edges: Array<RouteStencilFolderEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type RouteStencilFolderCreateInput = {
  /** The record to create */
  routeStencilFolder: RouteStencilFolderCreateInputType;
};

export type RouteStencilFolderCreateInputType = {
  name: Scalars['String']['input'];
};

export type RouteStencilFolderDeleteResponse = {
  __typename?: 'RouteStencilFolderDeleteResponse';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilFolderEdge = {
  __typename?: 'RouteStencilFolderEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the RouteStencilFolder */
  node: RouteStencilFolder;
};

export type RouteStencilFolderFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<RouteStencilFolderFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RouteStencilFolderFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type RouteStencilFolderIdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type RouteStencilFolderRouteStencilsConnection = {
  __typename?: 'RouteStencilFolderRouteStencilsConnection';
  /** Array of edges. */
  edges: Array<RouteStencilEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type RouteStencilFolderSort = {
  direction: SortDirection;
  field: RouteStencilFolderSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RouteStencilFolderSortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type RouteStencilFolderUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: RouteStencilFolderUpdateInputType;
};

export type RouteStencilFolderUpdateInputType = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type RouteStencilMaxAggregate = {
  __typename?: 'RouteStencilMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  folderId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilMinAggregate = {
  __typename?: 'RouteStencilMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  folderId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RouteStencilSort = {
  direction: SortDirection;
  field: RouteStencilSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RouteStencilSortFields {
  Id = '_id',
  CreatedAt = 'createdAt',
  FolderId = 'folderId',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type RouteStencilUpdateInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: RouteStencilUpdateInputType;
};

export type RouteStencilUpdateInputType = {
  folderId?: InputMaybe<Scalars['ID']['input']>;
  /** Specify a name for the new stencil, or omit this field to have the server generate a unique name */
  name?: InputMaybe<Scalars['String']['input']>;
  points?: InputMaybe<Array<RouteSegmentInput>>;
  routeEndType?: InputMaybe<RouteEndType>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
};

export type SandboxPlay = {
  __typename?: 'SandboxPlay';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  catchTimeMS: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  decisionTimeMS: Scalars['Int']['output'];
  defensivePlayers: Array<DefensivePlayer>;
  description: Scalars['String']['output'];
  /** Indicates whether or not the QB can be sacked in the Sandbox Play */
  enableSacks: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /** A field indicating whether or not this Sandbox Play can be interacted with or is playback-only */
  interactive: Scalars['Boolean']['output'];
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  offensivePlayers: Array<OffensivePlayer>;
  playerRoutes: Array<PlayerRoute>;
  /** Settings for the Players in the Sandbox Play */
  playerSettings: Array<PlayerSettings>;
  qbDrop: QbDropType;
  qbPlacement: QbAlignmentType;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results: Array<ReceiverOption>;
  sackTimeMS: Scalars['Int']['output'];
  status: ContentStatus;
  tags: Array<MetadataTag>;
  thumbnailImageAssetKey?: Maybe<Scalars['String']['output']>;
  thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SandboxPlayAggregateFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<SandboxPlayAggregateFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  enableSacks?: InputMaybe<BooleanFieldComparison>;
  interactive?: InputMaybe<BooleanFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SandboxPlayAggregateFilter>>;
  qbDrop?: InputMaybe<QbDropTypeFilterComparison>;
  qbPlacement?: InputMaybe<QbAlignmentTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SandboxPlayAggregateGroupBy = {
  __typename?: 'SandboxPlayAggregateGroupBy';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableSacks?: Maybe<Scalars['Boolean']['output']>;
  interactive?: Maybe<Scalars['Boolean']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbDrop?: Maybe<QbDropType>;
  qbPlacement?: Maybe<QbAlignmentType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SandboxPlayAggregateResponse = {
  __typename?: 'SandboxPlayAggregateResponse';
  count?: Maybe<SandboxPlayCountAggregate>;
  groupBy?: Maybe<SandboxPlayAggregateGroupBy>;
  max?: Maybe<SandboxPlayMaxAggregate>;
  min?: Maybe<SandboxPlayMinAggregate>;
};

export type SandboxPlayConnection = {
  __typename?: 'SandboxPlayConnection';
  /** Array of edges. */
  edges: Array<SandboxPlayEdge>;
  /** Paging information */
  pageInfo: PageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SandboxPlayCountAggregate = {
  __typename?: 'SandboxPlayCountAggregate';
  _id?: Maybe<Scalars['Int']['output']>;
  archivedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['Int']['output']>;
  enableSacks?: Maybe<Scalars['Int']['output']>;
  interactive?: Maybe<Scalars['Int']['output']>;
  lastPublishedAt?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  qbDrop?: Maybe<Scalars['Int']['output']>;
  qbPlacement?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type SandboxPlayEdge = {
  __typename?: 'SandboxPlayEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the SandboxPlay */
  node: SandboxPlay;
};

export type SandboxPlayFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<SandboxPlayFilter>>;
  archivedAt?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  enableSacks?: InputMaybe<BooleanFieldComparison>;
  interactive?: InputMaybe<BooleanFieldComparison>;
  lastPublishedAt?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SandboxPlayFilter>>;
  qbDrop?: InputMaybe<QbDropTypeFilterComparison>;
  qbPlacement?: InputMaybe<QbAlignmentTypeFilterComparison>;
  status?: InputMaybe<ContentStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type SandboxPlayMaxAggregate = {
  __typename?: 'SandboxPlayMaxAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbDrop?: Maybe<QbDropType>;
  qbPlacement?: Maybe<QbAlignmentType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SandboxPlayMinAggregate = {
  __typename?: 'SandboxPlayMinAggregate';
  _id?: Maybe<Scalars['ID']['output']>;
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  lastPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  qbDrop?: Maybe<QbDropType>;
  qbPlacement?: Maybe<QbAlignmentType>;
  status?: Maybe<ContentStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type SandboxPlaySort = {
  direction: SortDirection;
  field: SandboxPlaySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SandboxPlaySortFields {
  Id = '_id',
  ArchivedAt = 'archivedAt',
  CreatedAt = 'createdAt',
  Description = 'description',
  EnableSacks = 'enableSacks',
  Interactive = 'interactive',
  LastPublishedAt = 'lastPublishedAt',
  Name = 'name',
  QbDrop = 'qbDrop',
  QbPlacement = 'qbPlacement',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

export type ScoringModifiers = {
  __typename?: 'ScoringModifiers';
  pointsForCompletion: Scalars['Int']['output'];
  pointsForOptimalDecision: Scalars['Int']['output'];
  pointsForPerfectPlay: Scalars['Int']['output'];
  pointsForPerfectTime: Scalars['Int']['output'];
  pointsForTimeMax: Scalars['Int']['output'];
  pointsForTimeMin: Scalars['Int']['output'];
  pointsPerDecisionStreak: Scalars['Int']['output'];
};

export type SendMailInput = {
  platform: Scalars['String']['input'];
  registrationCode: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  to: Scalars['String']['input'];
};

export type SetFolderOnRouteStencilInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetFootlingoLessonsOnFootlingoCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetLessonCategoryOnLessonInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetLessonsOnLessonCategoryInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetPlayVariantsOnCoverageTypeInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetPlaysOnFormationTypeInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

export type SetReferenceCoverageOnCoverageInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetReferenceCoverageOnFormationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetReferenceFormationOnCoverageInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetReferenceFormationOnFormationInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The id of relation. */
  relationId: Scalars['ID']['input'];
};

export type SetRouteStencilsOnRouteStencilFolderInput = {
  /** The id of the record. */
  id: Scalars['ID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['ID']['input']>;
};

/** Skin tone options for player customization */
export enum SkinTone {
  Darker_10 = 'DARKER_10',
  Darker_20 = 'DARKER_20',
  Darker_30 = 'DARKER_30',
  Light10 = 'LIGHT10',
  Light20 = 'LIGHT20',
  Light30 = 'LIGHT30',
  Medium_10 = 'MEDIUM_10',
  Medium_20 = 'MEDIUM_20',
  Medium_30 = 'MEDIUM_30'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

/** The types you can query on the stats leaderboard. */
export enum StatPlayType {
  /** All Time Scores */
  AllTime = 'ALL_TIME',
  /** Scores from just the last 15 games */
  Last_15 = 'LAST_15',
  /** Scores of players with less than 15 games */
  NoCss = 'NO_CSS'
}

export type StatsItem = {
  __typename?: 'StatsItem';
  /** Users total interceptions */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Users total attempts */
  att?: Maybe<Scalars['Float']['output']>;
  /** Users ci score */
  ci?: Maybe<Scalars['Float']['output']>;
  /** Users total checkdowns */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Users total checkdowns percent */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Users completions */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Users completion percentage */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** Users email */
  email?: Maybe<Scalars['String']['output']>;
  /** Users engagement */
  eng?: Maybe<Scalars['Float']['output']>;
  /** Users first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Users total games played */
  g?: Maybe<Scalars['Float']['output']>;
  /** Number of games player has quit */
  gamesQuit?: Maybe<Scalars['Float']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** Players jersey number */
  jerseyNumber?: Maybe<Scalars['Float']['output']>;
  /** Users last name */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Last Game Played */
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** Users late throws */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** Users late throw percentage */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Users ng */
  ng?: Maybe<Scalars['Float']['output']>;
  /** Users optimal throws */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Users optimal throws percent */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** Users name */
  playerName?: Maybe<Scalars['String']['output']>;
  /** Users pos */
  pos?: Maybe<Scalars['Float']['output']>;
  /** Users rank */
  rank?: Maybe<Scalars['Float']['output']>;
  /** Users total sacks */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
  /** Users streak */
  strks?: Maybe<Scalars['Float']['output']>;
  /** Users streak percentage */
  strkspercent?: Maybe<Scalars['Float']['output']>;
  /** Users team(s) */
  team?: Maybe<Scalars['String']['output']>;
  /** Users username */
  username?: Maybe<Scalars['String']['output']>;
};

export type StatsResponse = {
  __typename?: 'StatsResponse';
  /** List of statistics items */
  stats?: Maybe<Array<StatsItem>>;
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type StripeSessionResponse = {
  __typename?: 'StripeSessionResponse';
  customerEmail?: Maybe<Scalars['String']['output']>;
  didPurchase?: Maybe<Scalars['Boolean']['output']>;
  metaData?: Maybe<MetaDataObject>;
  paymentStatus?: Maybe<Scalars['String']['output']>;
  purchasedPlan?: Maybe<Scalars['String']['output']>;
  sessionId?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo';
  /** The Stripe/Apple/Google customer ID associated with the subscription */
  customerId?: Maybe<Scalars['String']['output']>;
  /** The subscription plan of the user */
  plan: Plan;
  /** The type of subscription provider */
  type?: Maybe<SubscriptionType>;
};

/** The type of subscription provider */
export enum SubscriptionType {
  Apple = 'APPLE',
  Google = 'GOOGLE',
  Stripe = 'STRIPE'
}

export type TeamItems = {
  __typename?: 'TeamItems';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TeamPlayerStats = {
  __typename?: 'TeamPlayerStats';
  /** Date that the player was added to the team. */
  accountAdded?: Maybe<Scalars['DateTime']['output']>;
  /** Player's contact name */
  contactName?: Maybe<Scalars['String']['output']>;
  /** Player's contact number */
  contactNumber?: Maybe<Scalars['String']['output']>;
  contacts: Array<UserContacts>;
  /** Player's css */
  css?: Maybe<Scalars['Float']['output']>;
  /** Player's email */
  email?: Maybe<Scalars['String']['output']>;
  /** Player's games played */
  gamesPlayed?: Maybe<Scalars['Float']['output']>;
  /** Player's games quit */
  gamesQuit?: Maybe<Scalars['Float']['output']>;
  /** Player's interceptions */
  int?: Maybe<Scalars['Float']['output']>;
  /** Player's last login */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** Player's number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Player's name */
  playerName?: Maybe<Scalars['String']['output']>;
  /** Player's subscription */
  subscription?: Maybe<Scalars['String']['output']>;
  /** Player's username */
  username?: Maybe<Scalars['String']['output']>;
};

export type TeamResponse = {
  __typename?: 'TeamResponse';
  teams: Array<TeamItems>;
};

export type TeamStatsResponse = {
  __typename?: 'TeamStatsResponse';
  /** List of playerStats */
  players?: Maybe<Array<TeamPlayerStats>>;
  /** Team code */
  teamCode?: Maybe<Scalars['String']['output']>;
  /** Team name */
  teamName?: Maybe<Scalars['String']['output']>;
};

export type TemplateVariable = {
  __typename?: 'TemplateVariable';
  dataType: TemplateVariableDataType;
  name: Scalars['String']['output'];
  required: Scalars['Boolean']['output'];
};

export enum TemplateVariableDataType {
  Date = 'DATE',
  Datetime = 'DATETIME',
  Float = 'FLOAT',
  Int = 'INT',
  String = 'STRING'
}

export type TemplateVariableInput = {
  dataType: TemplateVariableDataType;
  name: Scalars['String']['input'];
  required: Scalars['Boolean']['input'];
};

export type UpdateOnePlayVariantInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: PlayVariantUpdateInput;
};

export type UpdateOneSandboxPlayInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateSandboxPlay;
};

export type UpdateOneUserInvitationCodeInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UserInvitationCodeUpdateInputType;
};

export type UpdateSandboxPlay = {
  archivedAt?: InputMaybe<Scalars['DateTime']['input']>;
  catchTimeMS?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  decisionTimeMS?: InputMaybe<Scalars['Int']['input']>;
  defensivePlayers?: InputMaybe<Array<DefensivePlayerInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  /** Indicates whether or not the QB can be sacked in the Sandbox Play */
  enableSacks?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** A field indicating whether or not this Sandbox Play can be interacted with or is playback-only */
  interactive?: InputMaybe<Scalars['Boolean']['input']>;
  lastPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offensivePlayers?: InputMaybe<Array<OffensivePlayerInput>>;
  playerRoutes?: InputMaybe<Array<PlayerRouteInput>>;
  /** Settings for the Players in the Sandbox Play */
  playerSettings?: InputMaybe<Array<PlayerSettingsInput>>;
  qbDrop?: InputMaybe<QbDropType>;
  qbPlacement?: InputMaybe<QbAlignmentType>;
  /** Results is an array that represents the outcomes based on the given receiver option.  Results are optimal, sub-optimal, incomplete, or interception. */
  results?: InputMaybe<Array<ReceiverOptionInput>>;
  sackTimeMS?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContentStatus>;
  tags?: InputMaybe<Array<MetadataTagInput>>;
  thumbnailImageAssetKey?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type User = {
  __typename?: 'User';
  clientRequirements: ClientRequirements;
  contacts?: Maybe<Array<UserContacts>>;
  displayName?: Maybe<Scalars['String']['output']>;
  displayNamePreference?: Maybe<Scalars['Float']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  groups: Array<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  onboarding: OnboardingStatus;
  preferredUsername?: Maybe<Scalars['String']['output']>;
  registrationCode: Scalars['String']['output'];
  speedDifficulty?: Maybe<Scalars['Float']['output']>;
  standardRank: RankSnapshot;
  sub: Scalars['String']['output'];
  subscription?: Maybe<SubscriptionInfo>;
  success?: Maybe<Scalars['String']['output']>;
  teams: Array<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

/** A reason why a user account has been disabled */
export enum UserAccountDisabledReason {
  /** The user's account access has expired */
  AccountExpired = 'ACCOUNT_EXPIRED',
  /** The user's account has been suspended */
  AccountSuspended = 'ACCOUNT_SUSPENDED',
  /** The user's access to a beta program has ended */
  BetaAccessEnded = 'BETA_ACCESS_ENDED'
}

export type UserContacts = {
  __typename?: 'UserContacts';
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type UserContactsInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreateInput = {
  birthdate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  groups: Array<AuthUserGroups>;
  lastName: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<PlatformType>;
  registrationCode: Scalars['String']['input'];
  /** Send an email to the user with a welcome message after creation.  Default=true */
  sendWelcomeNotification?: InputMaybe<Scalars['Boolean']['input']>;
  shouldResetExistingUser: Scalars['Boolean']['input'];
  speedDifficulty: Scalars['Float']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Array of team IDs */
  teams: Array<Scalars['String']['input']>;
  /** Specify the initial, temporary password for the user */
  temporaryPassword?: InputMaybe<Scalars['String']['input']>;
};

export type UserInvitationCode = {
  __typename?: 'UserInvitationCode';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserInvitationCodeConnection = {
  __typename?: 'UserInvitationCodeConnection';
  /** Array of edges. */
  edges: Array<UserInvitationCodeEdge>;
  /** Paging information */
  pageInfo: PageInfo;
};

export type UserInvitationCodeCreateInputType = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
};

export type UserInvitationCodeDeleteResponse = {
  __typename?: 'UserInvitationCodeDeleteResponse';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type UserInvitationCodeEdge = {
  __typename?: 'UserInvitationCodeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the UserInvitationCode */
  node: UserInvitationCode;
};

export type UserInvitationCodeFilter = {
  _id?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<UserInvitationCodeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  enabled?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<UserInvitationCodeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type UserInvitationCodeSort = {
  direction: SortDirection;
  field: UserInvitationCodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserInvitationCodeSortFields {
  Id = '_id',
  Code = 'code',
  CreatedAt = 'createdAt',
  Enabled = 'enabled',
  UpdatedAt = 'updatedAt'
}

export type UserInvitationCodeUpdateInputType = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserLeaderboardStanding = {
  __typename?: 'UserLeaderboardStanding';
  contextId?: Maybe<Scalars['ID']['output']>;
  firstPlaceLeaderName?: Maybe<Scalars['String']['output']>;
  mode: GamePlayMode;
  rank?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type UserNameCheckResponse = {
  __typename?: 'UserNameCheckResponse';
  available: Scalars['Boolean']['output'];
};

export type UserRankDetails = {
  __typename?: 'UserRankDetails';
  displayName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  playerCustomization?: Maybe<PlayerCustomization>;
  rank?: Maybe<Scalars['Int']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type UserRegisterPlayerInput = {
  deviceType: PlatformType;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  invitationCode?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  registrationCode?: InputMaybe<Scalars['String']['input']>;
};

export type UserStatsHistory = {
  __typename?: 'UserStatsHistory';
  /** History for year in 1 day intervals */
  daily?: Maybe<Array<UserStatsHistoryItem>>;
  /** History for day in 5 minute intervals */
  fiveMinute?: Maybe<Array<UserStatsHistoryItem>>;
  /** History for month in 4 hour intervals */
  fourHour?: Maybe<Array<UserStatsHistoryItem>>;
  /** History for week in 1 hour intervals */
  hourly?: Maybe<Array<UserStatsHistoryItem>>;
};

export type UserStatsHistoryItem = {
  __typename?: 'UserStatsHistoryItem';
  /** Interceptions */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Attempts */
  att?: Maybe<Scalars['Float']['output']>;
  /** Users Current Score */
  averageScore?: Maybe<Scalars['Float']['output']>;
  /** Checkdowns */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Checkdown Percent */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Completions */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Completion Percent */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** Date of Score */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Index of Score */
  index?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** Late Throws */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** Lat Throw Percent */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Users old Score */
  oldScore?: Maybe<Scalars['Float']['output']>;
  /** Optimal Throws */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Optimal Throw Percent */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** Sacks */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
};

export type UsernamePolicy = {
  __typename?: 'UsernamePolicy';
  maximumLength?: Maybe<Scalars['Int']['output']>;
  minimumLength?: Maybe<Scalars['Int']['output']>;
};

export type ValidateSubscriptionInput = {
  appleOriginalTransactionId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  googlePackageName?: InputMaybe<Scalars['String']['input']>;
  googlePurchaseToken?: InputMaybe<Scalars['String']['input']>;
  googleSubscriptionId?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<Scalars['String']['input']>;
  platform: Scalars['String']['input'];
};

export type ValidateSubscriptionOutput = {
  __typename?: 'ValidateSubscriptionOutput';
  expirationDate?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type BestCoverage = {
  __typename?: 'bestCoverage';
  /** Interception count */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Attempt count */
  att?: Maybe<Scalars['Float']['output']>;
  /** Crucible Intellect Score */
  ci?: Maybe<Scalars['Float']['output']>;
  /** Check down count */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Check down percentage */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Completion count */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Completion percentage */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** Name of coverage */
  coverageName?: Maybe<Scalars['String']['output']>;
  /** Id of coverage */
  coverageTypeId?: Maybe<Scalars['String']['output']>;
  /** Games played */
  g?: Maybe<Scalars['Float']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** late-throw count */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** late-throw percentage */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Optimal throw count */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Optimal throw percentage */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** Sack count */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
};

export type BestOffense = {
  __typename?: 'bestOffense';
  /** Interception count */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Attempt count */
  att?: Maybe<Scalars['Float']['output']>;
  /** Crucible Intellect Score */
  ci?: Maybe<Scalars['Float']['output']>;
  /** Check down count */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Check down percentage */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Completion count */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Completion percentage */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** Games played */
  g?: Maybe<Scalars['Float']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** late-throw count */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** late-throw percentage */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Name of play */
  offenseName?: Maybe<Scalars['String']['output']>;
  /** Optimal throw count */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Optimal throw percentage */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** Id of play */
  playId?: Maybe<Scalars['String']['output']>;
  /** Sack count */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
};

export type Player = {
  __typename?: 'player';
  customization?: Maybe<PlayerCustomization>;
};

export type Variants = {
  __typename?: 'variants';
  /** Interception count */
  _int?: Maybe<Scalars['Float']['output']>;
  /** Attempt count */
  att?: Maybe<Scalars['Float']['output']>;
  /** Crucible Intellect Score */
  ci?: Maybe<Scalars['Float']['output']>;
  /** Check down count */
  ckdn?: Maybe<Scalars['Float']['output']>;
  /** Check down percentage */
  ckdnPercent?: Maybe<Scalars['Float']['output']>;
  /** Completion count */
  comm?: Maybe<Scalars['Float']['output']>;
  /** Completion percentage */
  commPercent?: Maybe<Scalars['Float']['output']>;
  /** Name of coverage */
  coverageName?: Maybe<Scalars['String']['output']>;
  /** Id of coverage */
  coverageTypeId?: Maybe<Scalars['String']['output']>;
  /** Games played */
  g?: Maybe<Scalars['Float']['output']>;
  /** total incompletions */
  inc?: Maybe<Scalars['Float']['output']>;
  /** Incomplete  percentage */
  incPercent?: Maybe<Scalars['Float']['output']>;
  /** Interception percentage */
  intPercent?: Maybe<Scalars['Float']['output']>;
  /** late-throw count */
  ltt?: Maybe<Scalars['Float']['output']>;
  /** late-throw percentage */
  lttPercent?: Maybe<Scalars['Float']['output']>;
  /** Name of play */
  offenseName?: Maybe<Scalars['String']['output']>;
  /** Optimal throw count */
  optt?: Maybe<Scalars['Float']['output']>;
  /** Optimal throw percentage */
  opttPercent?: Maybe<Scalars['Float']['output']>;
  /** id of play */
  playId?: Maybe<Scalars['String']['output']>;
  /** Version of play */
  playVersion?: Maybe<Scalars['Float']['output']>;
  /** Sack count */
  sk?: Maybe<Scalars['Float']['output']>;
  /** Sack percentage */
  skPercent?: Maybe<Scalars['Float']['output']>;
  /** Name of variant */
  variantName?: Maybe<Scalars['String']['output']>;
};

export type CoachRequestForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type CoachRequestForgotPasswordMutation = { __typename?: 'Mutation', coachRequestForgotPassword: { __typename?: 'ResultResponse', success: boolean, message: string } };

export type CoachSetNewPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type CoachSetNewPasswordMutation = { __typename?: 'Mutation', coachSetNewPassword: { __typename?: 'ResultResponse', success: boolean, message: string } };

export type CoachSignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type CoachSignInMutation = { __typename?: 'Mutation', coachSignIn: { __typename?: 'AuthSignInResponse', status: AuthenticationResult, message?: string | null, challengeType?: string | null, session?: string | null, accessToken?: string | null, refreshToken?: string | null, expiresAt?: number | null } };

export type GetSelfQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSelfQuery = { __typename?: 'Query', adminSelf: { __typename?: 'User', email: string, username: string, groups: Array<string> } };

export type RefreshAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAccessTokenMutation = { __typename?: 'Mutation', adminUserTokenRefresh: { __typename?: 'AuthRefreshTokenResponse', status: AuthenticationResult, message?: string | null, accessToken?: string | null, challengeType?: string | null, session?: string | null, expiresAt?: number | null } };


export const CoachRequestForgotPasswordDocument = gql`
    mutation coachRequestForgotPassword($email: String!) {
  coachRequestForgotPassword(email: $email) {
    success
    message
  }
}
    `;
export type CoachRequestForgotPasswordMutationFn = Apollo.MutationFunction<CoachRequestForgotPasswordMutation, CoachRequestForgotPasswordMutationVariables>;

/**
 * __useCoachRequestForgotPasswordMutation__
 *
 * To run a mutation, you first call `useCoachRequestForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachRequestForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachRequestForgotPasswordMutation, { data, loading, error }] = useCoachRequestForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCoachRequestForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CoachRequestForgotPasswordMutation, CoachRequestForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachRequestForgotPasswordMutation, CoachRequestForgotPasswordMutationVariables>(CoachRequestForgotPasswordDocument, options);
      }
export type CoachRequestForgotPasswordMutationHookResult = ReturnType<typeof useCoachRequestForgotPasswordMutation>;
export type CoachRequestForgotPasswordMutationResult = Apollo.MutationResult<CoachRequestForgotPasswordMutation>;
export type CoachRequestForgotPasswordMutationOptions = Apollo.BaseMutationOptions<CoachRequestForgotPasswordMutation, CoachRequestForgotPasswordMutationVariables>;
export const CoachSetNewPasswordDocument = gql`
    mutation coachSetNewPassword($email: String!, $password: String!, $code: String!) {
  coachSetNewPassword(email: $email, password: $password, code: $code) {
    success
    message
  }
}
    `;
export type CoachSetNewPasswordMutationFn = Apollo.MutationFunction<CoachSetNewPasswordMutation, CoachSetNewPasswordMutationVariables>;

/**
 * __useCoachSetNewPasswordMutation__
 *
 * To run a mutation, you first call `useCoachSetNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachSetNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachSetNewPasswordMutation, { data, loading, error }] = useCoachSetNewPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useCoachSetNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CoachSetNewPasswordMutation, CoachSetNewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachSetNewPasswordMutation, CoachSetNewPasswordMutationVariables>(CoachSetNewPasswordDocument, options);
      }
export type CoachSetNewPasswordMutationHookResult = ReturnType<typeof useCoachSetNewPasswordMutation>;
export type CoachSetNewPasswordMutationResult = Apollo.MutationResult<CoachSetNewPasswordMutation>;
export type CoachSetNewPasswordMutationOptions = Apollo.BaseMutationOptions<CoachSetNewPasswordMutation, CoachSetNewPasswordMutationVariables>;
export const CoachSignInDocument = gql`
    mutation coachSignIn($email: String!, $password: String!) {
  coachSignIn(email: $email, password: $password) {
    status
    message
    challengeType
    session
    accessToken
    refreshToken
    expiresAt
  }
}
    `;
export type CoachSignInMutationFn = Apollo.MutationFunction<CoachSignInMutation, CoachSignInMutationVariables>;

/**
 * __useCoachSignInMutation__
 *
 * To run a mutation, you first call `useCoachSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachSignInMutation, { data, loading, error }] = useCoachSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCoachSignInMutation(baseOptions?: Apollo.MutationHookOptions<CoachSignInMutation, CoachSignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachSignInMutation, CoachSignInMutationVariables>(CoachSignInDocument, options);
      }
export type CoachSignInMutationHookResult = ReturnType<typeof useCoachSignInMutation>;
export type CoachSignInMutationResult = Apollo.MutationResult<CoachSignInMutation>;
export type CoachSignInMutationOptions = Apollo.BaseMutationOptions<CoachSignInMutation, CoachSignInMutationVariables>;
export const GetSelfDocument = gql`
    query getSelf {
  adminSelf {
    email
    username
    groups
  }
}
    `;

/**
 * __useGetSelfQuery__
 *
 * To run a query within a React component, call `useGetSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSelfQuery(baseOptions?: Apollo.QueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
      }
export function useGetSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export function useGetSelfSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSelfQuery, GetSelfQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSelfQuery, GetSelfQueryVariables>(GetSelfDocument, options);
        }
export type GetSelfQueryHookResult = ReturnType<typeof useGetSelfQuery>;
export type GetSelfLazyQueryHookResult = ReturnType<typeof useGetSelfLazyQuery>;
export type GetSelfSuspenseQueryHookResult = ReturnType<typeof useGetSelfSuspenseQuery>;
export type GetSelfQueryResult = Apollo.QueryResult<GetSelfQuery, GetSelfQueryVariables>;
export function refetchGetSelfQuery(variables?: GetSelfQueryVariables) {
      return { query: GetSelfDocument, variables: variables }
    }
export const RefreshAccessTokenDocument = gql`
    mutation refreshAccessToken {
  adminUserTokenRefresh {
    status
    message
    accessToken
    challengeType
    session
    expiresAt
  }
}
    `;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, options);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;