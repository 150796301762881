import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useMutation, gql } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useTransition, animated } from 'react-spring';
import photo from './photo.png';
import './styles.css';
import { cloneDeep, isEqual } from 'lodash'
import { Tabs, Tab, Box, Button, FormControl, InputLabel, Modal, IconButton, Snackbar, Alert, Divider } from '@mui/material';
import { ParentSize } from '@visx/responsive';
import { useNavigate } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import { ArrowDownward, ArrowLeft, ArrowUpward, CheckCircle, Dangerous, Delete, Edit, QrCode, West } from '@mui/icons-material';
import QRCode from 'react-qr-code';
import PlayerCellRenderer from '../PlayerCellRenderer';
import moment from 'moment';
import printJS from "print-js";
import { createRoot } from 'react-dom/client';

const GET_TEAM_STATS_BY_ID = gql`
  mutation GetTeamStatsByID($teamID: String!) {
    getTeamStatsByID(teamID: $teamID) {
      teamName
      teamCode
      players {
        playerName
        phoneNumber
        email
        subscription
        contactName
        accountAdded
        contactNumber
        css
        lastLogin
        int
        gamesPlayed
        gamesQuit
        username
        contacts {
          name
          phoneNumber
          email
        }
      }
    }
  }
`;

const EDIT_PLAYER_CONTACTS = gql`
mutation EditPlayerContacts($username: String!, $teamID: String!, $contacts: [UserContactsInput!]!) {
  editPlayerContacts(username: $username, teamID: $teamID, contacts: $contacts) {
    success
    message
  }
}
`;

const GET_TEAMS = gql`
  mutation GetTeams {
    getTeams {
      teams {
        id
        name
        teamDifficulty
      }
    }
  }
`;

const EDIT_TEAM_NAME = gql`
  mutation EditTeamName($teamID: String!, $teamName: String!) {
    editTeamName(teamID: $teamID, teamName: $teamName) {
      success
      message
    }
  }
`;

const DELETE_USER_FROM_TEAM = gql`
  mutation DeleteUserFromTeam($teamID: String!, $username: String!) {
    deleteUserFromTeam(teamID: $teamID, username: $username) {
      success
      message
    }
  }
`;

const CREATE_TEAM = gql`
  mutation createTeam($teamName: String!, $teamDifficulty: Float!) {
    createTeam(teamName: $teamName, teamDifficulty: $teamDifficulty) {
      id
      name
    }
  }
`;

const DELETE_TEAM = gql`
  mutation DeleteTeam($teamID: String!) {
    deleteTeam(teamID: $teamID) {
      success
      message
    }
  }
`;

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
};

interface TeamsProps {
  searchTerm?: any;
}

const TeamsPage: React.FC<TeamsProps> = ({
  searchTerm,
}) => {
  const [sortField, setSortField] = useState('playerName');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeamID, setSelectedTeamID] = useState<string | null>(null);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<any | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTextFieldName, setDeleteTextFieldName] = useState('');
  const [newTeamName, setNewTeamName] = useState('');
  const [newTeamModal, setNewTeamModal] = useState<boolean>(false);
  const [startingContact, setStartingContact] = useState<Array<any>>([]);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1000);
  const [isSmallMobile, setIsSmallMobile] = useState<boolean>(window.innerWidth < 450);
  const [deletePlayerModal, setDeletePlayerModal] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editTeamName, setEditTeamName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [newDifficulty, setNewDifficulty] = useState<string>('0');
  const lastUpdate = useRef(isMobile);
  const widths:any = {
    'playerName': isMobile ? 130 : 160,
    'edit': isMobile ? 30 : 100
    }
  const handleResize = () => {
    const mobile = window.innerWidth < 1000;
    const smallMobile = window.innerWidth < 450;
    setIsMobile(mobile);
    setIsSmallMobile(smallMobile);
    if (gridRef.current?.api && lastUpdate.current !== mobile) {
      lastUpdate.current = mobile;
      // Get current column state
      const allColumnState = gridRef.current.api.getColumnState();
  
      // Update column properties to ensure specific columns are pinned
      const updatedColumnState = allColumnState.map((colState:any) => {
        if (( ['playerName', 'edit']).includes(colState.colId)) {
          console.log('here', colState.colId, widths[colState.colId], colState)
          return { 
            ...colState, 
            pinned: colState.colId === 'edit' ? 'right' : 'left', 
            width: widths[colState.colId] 
          };
        }
        return colState;
      });
      console.log(cloneDeep(updatedColumnState))
      gridRef.current.api.applyColumnState({
        state: updatedColumnState,
        applyOrder: true,
      });
    }
  };



React.useEffect(() => {
  if (error) {
    setSnackbarOpen(true);
  }
}, [error]);

const handleClose = (event:any, reason:any) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackbarOpen(false);
};

const openDeleteModal = () => {
  setIsDeleteModalOpen(true);
  setDeleteTextFieldName('')
};

// Function to open the edit modal and set the team name
const openEditModal = () => {
  console.log('here', data)
  setEditTeamName(teamName);
  setIsEditModalOpen(true);
};

const openNewTeamModal = () => {
  setNewTeamName('');
  setNewDifficulty('0')
  setNewTeamModal(true);
};

const [getTeams] = useMutation(GET_TEAMS);
const [getTeamStatsByID] = useMutation(GET_TEAM_STATS_BY_ID);
const [editTeamNameMutation] = useMutation(EDIT_TEAM_NAME);
const [deleteTeam] = useMutation(DELETE_TEAM);
const [createTeam] = useMutation(CREATE_TEAM);
const [deleteUserFromTeam] = useMutation(DELETE_USER_FROM_TEAM);
const [editPlayerContacts] = useMutation(EDIT_PLAYER_CONTACTS);

const handleSaveNewTeam = async () => {
  setLoading(true);
  try {
    const res = await createTeam({ variables: { teamName: newTeamName, teamDifficulty: parseInt(newDifficulty) } });
    setNewTeamModal(false);
    fetchTeams(false, res.data.createTeam.id)
    setLoading(false)
  } catch(error: any) {
    setError(`Error creating team: ${error.message}`);
    setLoading(false)
    console.log(error)
  }
}

const handleDeletePlayer = async () => {
  setLoading(true);
  try {
    const res = await deleteUserFromTeam({ variables: { teamID: selectedTeamID, username: selectedPlayer?.username } });
    setDeletePlayerModal(false);
    setSelectedPlayer(null)
    setStartingContact([{}])
    getStatsForTeam(selectedTeamID ?? '')
    setLoading(false)
  } catch(error: any) {
    setError(`Error deleting user from team: ${error.message}`);
    setLoading(false)
    console.log(error)
  }
}
// Function to handle delete confirmation
const handleDeleteTeam = async () => {
  setLoading(true);
  try {
    const res = await deleteTeam({ variables: { teamID: selectedTeamID } });
    setIsDeleteModalOpen(false);
    fetchTeams()
    setLoading(false)
  } catch(error: any) {
    setError(`Error deleting team: ${error.message}`);
    setLoading(false)
    console.log(error)
  }
};

async function fetchTeams(dontSetTeamID?: boolean, teamIDOverride?: string) {
  setLoading(true);
  try {
    const { data } = await getTeams();
    const teams = data?.getTeams?.teams ?? [];
    if (teams.length > 0) {
      const isFromTeam = JSON.parse(window.localStorage.getItem('navigatedFromTeam') ?? '{}');
      setTeams(teams);
      let teamSelected:any = selectedTeamID
      if(!dontSetTeamID) {
        if(teamIDOverride) {
          if(teams.find((team:any) => team.id === teamIDOverride)) {
            setSelectedTeamID(teamIDOverride);
            teamSelected = teamIDOverride
          } else {
            setSelectedTeamID(teams[0].id);
            teamSelected = teams[0].id
          }
        } else {
          teamSelected = isFromTeam?.teamID || teams[0].id
          setSelectedTeamID(teamSelected); 
        }
      }
      const index = teams.findIndex((team:any) => team.id === teamSelected)
      setSelectedTabIndex(index);
      setError(null)
      window.localStorage.setItem('navigatedFromTeam', '{}');
    } else {
      setError('No teams found');
      setData(null);
      setSelectedTeamID(null);
      setTeams([]);
      setLoading(false)
    }
  } catch (error: any) {
    setError(`Error fetching teams: ${error.message}`);
    console.error('Error fetching teams:', error);
    setLoading(false);

  }
}

const handleSaveTeamName = async () => {
  setLoading(true);
  try {
    const res = await editTeamNameMutation({ variables: { teamID: selectedTeamID, teamName: editTeamName } });
    const newData = {...data}
    data.teamName = editTeamName
    setIsEditModalOpen(false);
    setEditTeamName('')
    fetchTeams(true)
    setLoading(false)
    setData(data)
  } catch(error: any) {
    setError(`Error editing team name: ${error.message}`);
    setLoading(false)
    console.log(error)
  }
};

// Function to handle the Edit button click
const handleEditClick = (player: any) => {
  setSelectedPlayer(player);
  const contacts = player.contacts ?? [{}];
  if(contacts.length === 0) {
    contacts.push({})
  }
  setStartingContact(contacts)
};

  const gridRef = useRef<any>(null);


  const getStatsForTeam = async (teamID: string) => {
    try {
      setLoading(true);
      const { data } = await getTeamStatsByID({ variables: { teamID } });
      setData(data.getTeamStatsByID);
      setSelectedTabIndex(teams.findIndex((team) => team.id === teamID));
      setError(null)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error:any) {
      setLoading(false);
      setError(error)
      setError(`Error fetching team stats: ${error?.message}`);
    }
  };

  useEffect(() => {
    if (selectedTeamID) {
      setData(null)
      getStatsForTeam(selectedTeamID);
    }
  }, [selectedTeamID]);



  useEffect(() => {
    fetchTeams();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getTeams]);

  const handleTabChange = (event:any, newValue: number) => {
    setSelectedTabIndex(newValue);
    setSelectedTeamID(teams[newValue].id);
  };

  const playerData = data?.players ?? [];
  const teamName = data?.teamName ?? '';
  const teamCode = data?.teamCode ?? '';
  const filteredPlayers = playerData.map((item:any) => {
    return {
      ...item,
      lastLogin: item.lastLogin ? moment(item.lastLogin)?.format('M/DD/YYYY h:mm A'):  null,
    }
  }).filter((player: any) =>
    player.playerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

 const sortedPlayers = useMemo(() => {
    return filteredPlayers.sort((a: any, b: any) => {
      if (!sortField) return 0;
      const isDateField = (field: any) => !isNaN(Date.parse(field));
      if (isDateField(a[sortField]) && isDateField(b[sortField])) {
        const dateA = new Date(a[sortField]);
        const dateB = new Date(b[sortField]);
        // @ts-ignore
        return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
      }
      if (sortDirection === 'asc') {
        return a[sortField] < b[sortField] ? -1 : 1;
      } else {
        return a[sortField] > b[sortField] ? -1 : 1;
      }
    });
  }, [filteredPlayers, sortField, sortDirection]);

  // const transitions = useTransition(sortedPlayers, {
  //   keys: (player: any) => player.username,
  //   from: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  //   enter: { opacity: 1, transform: 'translate3d(0,0,0)' },
  //   leave: { opacity: 0, transform: 'translate3d(-100%,0,0)' },
  // });

  const getColumnDefs = (isMobile: boolean) => [
    {
      headerName: 'Name',
      field: 'playerName',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Name',
      pinned: 'left' as 'left',
      lockPosition: 'left' as 'left',
      cellRenderer: PlayerCellRenderer,
      width: isMobile ? 120 : 160,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Name',
    },
    {
      headerName: 'Phone Number',
      field: 'phoneNumber',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Phone Number',
    },
    {
      headerName: 'Last Log In',
      field: 'lastLogin',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Last Play Time',
    },
    {
      headerName: 'Subscription',
      field: 'subscription',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Subscription Level',
      cellRenderer: (params:any) =>  params.value?.charAt(0)?.toUpperCase() + params?.value?.slice(1)
    },
    {
      headerName: 'Contact Name',
      field: 'contactName',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Contact Name',
    },
    {
      headerName: 'Contact Phone',
      field: 'contactNumber',
      sortable: true,
      filter: !isMobile,
      headerTooltip: 'Players Contact Phone',
    },
    {
      headerName: ' ',
      minWidth: 30,
      maxWidth: isMobile ? 30 : 100,
      field: 'edit',
      pinned: 'right' as 'right',
      lockPosition: 'right' as 'right',
      cellRenderer: (params: any) => (
        <Button
          color="primary"
          size={isMobile ? "small" : undefined}
          sx={{paddingLeft: '5px', paddingRight: '5px', minWidth: '0px'}}
          fullWidth
          onClick={() => handleEditClick(params.data)}
        >
          {isMobile ? <Edit fontSize={'small'} /> : 'Edit'}
        </Button>
      ),
    },
  ];

  const deleteContact = (index: number) => {
    let newContacts = [...startingContact];
    newContacts = newContacts.filter((_, i) => i !== index);
    if(newContacts.length === 0) {
      newContacts.push({})
    }
    setStartingContact(newContacts);
  }

  const handleContactUpdate = (value: string, index: number, key: string) => {
    setStartingContact((prev) => {
      const newContacts:any = [...prev];
      newContacts[index][key] = value;
      return newContacts;
    });
  }

  const handleSaveContacts = async () => {
    try {
      setLoading(true);
      const contactsToSave = startingContact?.map((item:any) => {
        return {
          name: item.name,
          phoneNumber: item.phoneNumber,
          email: item.email
        }
      })
      await editPlayerContacts({ variables: { teamID: selectedTeamID, username: selectedPlayer?.username, contacts: contactsToSave } });
      const curPlayer = selectedPlayer;
      curPlayer.contacts = startingContact;
      setSelectedPlayer(curPlayer);
      setError(null)
      setLoading(false)
    } catch (error:any) {
      setLoading(false);
      setError(error)
      setError(`Error editing player name: ${error?.message}`);
    }
  }

  console.log(startingContact)

  const handlePrint2 = async (teamCode:any) => {
    const printJS = (await import('print-js')).default;
    const tempDiv = document.createElement('div');
    tempDiv.id = 'print-section';
    tempDiv.style.textAlign = 'center';
    tempDiv.style.padding = '50px';
    tempDiv.style.fontSize = '24px';
    tempDiv.style.fontWeight = 'bold';

    const codeWrapper = document.createElement('p');
    codeWrapper.style.fontSize = '32px';
    codeWrapper.style.marginBottom = '20px';

    const teamCodeLabel = document.createElement('span');
    teamCodeLabel.innerText = 'Registration Code: ';
    teamCodeLabel.style.color = 'black';

    const teamCodeText = document.createElement('span');
    teamCodeText.innerText = teamCode;
    teamCodeText.style.color = 'red';

    codeWrapper.appendChild(teamCodeLabel);
    codeWrapper.appendChild(teamCodeText);
    tempDiv.appendChild(codeWrapper);

    const qrCodeWrapper = document.createElement('div');
    qrCodeWrapper.id = 'qr-code-container';
    tempDiv.appendChild(qrCodeWrapper);

    document.body.appendChild(tempDiv);

    const qrCodeContainer = document.getElementById('qr-code-container');
    if (qrCodeContainer) {
      const root = createRoot(qrCodeContainer);
      root.render(
        <QRCode
          className="qr_code_item"
          value={process.env.NEXT_PUBLIC_IOS_LINK ?? ''}
          style={{ width: '350px', height: '350px' }}
        />
      );
    }

    // Inject Print Styles
    const printStyle = document.createElement('style');
    printStyle.innerHTML = `
      @media print {
        @page { size: auto; margin: 0; } 
        body { text-align: center; font-family: Arial, sans-serif; }
      }
    `;
    document.head.appendChild(printStyle);

    setTimeout(() => {
      printJS({
        printable: 'print-section',
        type: 'html',
        scanStyles: false
      });
        if (tempDiv.parentNode) {
          document.body.removeChild(tempDiv);
        }
        if (printStyle.parentNode) {
          document.head.removeChild(printStyle);
        }
    }, 500);
};

  const handlePrint = () => {
    //fix this 
    
    // Create a temporary printable div
    const printableDiv = document.createElement("div");
    printableDiv.style.display = "flex";
    printableDiv.style.flexDirection = "column";
    printableDiv.style.alignItems = "center";
    printableDiv.style.justifyContent = "center";
    printableDiv.style.height = "100dvh"; // Center vertically in the page
    printableDiv.style.textAlign = "center";
  
    // Clone the existing QR Code element
    const qrCodeElement:any = document.querySelector(".qr_code_item")?.cloneNode(true);
    qrCodeElement.style.cursor = "default"; // Remove pointer cursor for printing
    qrCodeElement.style.marginBottom = "20px"; // Add spacing below QR code
  
    // Add the QR code to the printable div
    if (qrCodeElement) {
      printableDiv.appendChild(qrCodeElement);
    }
  
    // Add the team code text below the QR code
    const teamCodeElement = document.createElement("div");
    teamCodeElement.style.marginTop = "20px";
    teamCodeElement.style.fontSize = "24px"; // Larger text for team code
    teamCodeElement.style.fontFamily = "JostRegular";
    teamCodeElement.style.letterSpacing = "2px";
    teamCodeElement.innerHTML = `
      <h6 style="margin: 0;font-size: 26px;">
        <strong style="font-family: JostBold;">TEAM C ODE:</strong> ${teamCode?.toUpperCase()}
      </h6>
    `;
    printableDiv.appendChild(teamCodeElement);
  
    // Append the printable div to the document body
    document.body.appendChild(printableDiv);
    console.log('HERHEHRHERHRE')
    // Use Print.js to print the content
    printJS({
      printable: printableDiv,
      type: "html",
      scanStyles: false,
    });
  
    // Clean up the DOM
    document.body.removeChild(printableDiv);
  };

  const getLowerItems = (isMobile: boolean) => (
    <>
     <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: isMobile ?  'center' : undefined}}>
          {!!teamCode?.trim() && <Typography variant="h6" sx={{ fontFamily: 'JostRegular', letterSpacing: '2px' }}><span style={{fontFamily: 'JostBold'}}>TEAM CODE:</span> {teamCode?.toUpperCase()}</Typography>}
      </Box>
      <Button onClick={() => handlePrint2(teamCode?.toUpperCase())} size='large' startIcon={<QrCode/>} variant="contained" color="primary">DOWNLOAD QR CODE</Button>
    </>
  )

  const getPlayerInfo = (isMobile: boolean) => {
   
    return (
      <>
        <Box sx={{ ml: isMobile ? undefined : 'auto',width:isMobile ? undefined :'33.33%',  display: 'flex', flexDirection: 'column', gap: 1 }}>
          {!isMobile && <Typography><span style={{ fontWeight: 'bold' }}>Account Added:</span>{selectedPlayer.accountAdded ?? ''}</Typography>}
          {!isMobile && <Typography><span style={{ fontWeight: 'bold' }}>Subscription Type:</span> {selectedPlayer.subscription.charAt(0).toUpperCase() + selectedPlayer.subscription.slice(1)}</Typography>}
          {!isMobile && <Typography color="textSecondary">Athletes must have Premium+ account for you to see stats.</Typography>}
        </Box>
        <Box sx={{ mt:  isMobile ? undefined : 2, width:isMobile ? undefined :'33.33%', gap: '5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
          {!isMobile &&<Typography variant="body2" color="textSecondary">
            Players who use your code in the App will automatically be added to your team list.
          </Typography>}
         {!isMobile && getLowerItems(isMobile)}
        </Box>
        </>
    )
  }
  const activeTeam = teams[selectedTabIndex]?.id
  const activeDifficulty = `${teams[selectedTabIndex]?.teamDifficulty ?? 0}`
  return (
    <div style={{ overflowY: 'hidden' }}>
       <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      {loading  ? (
        <div className={selectedPlayer ? "teams_player_loading_container": "teams_loading_container"}>
          <CircularProgress style={{ color: '#1A283A' }} size={isMobile ? '60px' : '100px'} />
        </div>
      ) : null}
      <>
                {selectedPlayer && 
                  <div className="teams-page_player" style={{ overflowY: loading ? 'hidden' : undefined }}>
                    <div className="search-sort-bar">
                      <div style={{paddingLeft: '10px', display: 'flex',paddingTop: '10px', gap: '10px', alignItems: 'center', width: isMobile ? undefined : 'calc(100%)', justifyContent: 'space-between' }}>
                        <Typography className="player_account_text" variant="h5" sx={{ letterSpacing: '2px', fontFamily: 'JostRegular', fontWeight: 'bold' }}>
                          PLAYER ACCOUNT
                        </Typography>
                        <Button sx={{fontWeight: 'bold'}} size="large" onClick={() => setSelectedPlayer(null)} variant="text" startIcon={<West />}>
                          {isMobile ? '' : 'BACK TO ALL PLAYERS'}
                        </Button>
                      </div>
                    </div>
                    <Card sx={{ p: 3, mt: 2, display: 'flex',flexDirection: isMobile ? 'column' : undefined, gap: 2, alignItems: isSmallMobile ? 'flex-start' :  'center',  }}>
                      <div style={{width:isMobile ? '100%' : '33.33%', display: 'flex', gap: '20px'}}>
                      <Avatar style={{ alignSelf: 'start' }} src={photo} alt="Player Avatar" sx={{ width: 100, height: 100 }} />
                      <Box sx={{ display: 'flex',gap: '5px', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }}>
                        <Typography variant="h5" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                        {selectedPlayer.playerName} <CheckCircle style={{ marginLeft: '10px' }} color="success" />
                          <span style={{ fontSize: '14px', color:  'green', marginLeft: '5px' }}>{'Active'}</span>
                        </Typography>
                        {!isSmallMobile && <Typography><span style={{ fontWeight: 'bold' }}>User Name:</span> {selectedPlayer.playerName}</Typography>}
                        {!isSmallMobile && <Typography><span style={{ fontWeight: 'bold' }}>Email:</span> {selectedPlayer.email}</Typography>}
                        {!isSmallMobile && <Typography><span style={{ fontWeight: 'bold' }}>Phone Number:</span> {selectedPlayer.phoneNumber}</Typography>}
                        {isMobile && !isSmallMobile && <Typography><span style={{ fontWeight: 'bold' }}>Account Added:</span>{selectedPlayer.accountAdded ?? ''}</Typography>}
                        {isMobile && !isSmallMobile && <Typography><span style={{ fontWeight: 'bold' }}>Subscription Type:</span> {selectedPlayer.subscription.charAt(0).toUpperCase() + selectedPlayer.subscription.slice(1)}</Typography>}
                      </Box>
                      </div>
                      {isSmallMobile && <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                       <Typography><span className="small_player_header">User Name:</span> {selectedPlayer.playerName}</Typography>
                       <Typography><span  className="small_player_header" >Email:</span> {selectedPlayer.email}</Typography>
                        <Typography><span className="small_player_header" >Phone Number:</span> {selectedPlayer.phoneNumber}</Typography>
                        <Typography><span className="small_player_header" >Account Added:</span>{selectedPlayer.accountAdded ?? ''}</Typography>
                        <Typography><span  className="small_player_header" >Subscription Type:</span> {selectedPlayer.subscription.charAt(0).toUpperCase() + selectedPlayer.subscription.slice(1)}</Typography>
                        </div>}
                    {isMobile && <div style={{display: 'flex', gap: '10px'}}>
                    {getPlayerInfo(isMobile)}
                    </div>}
                    {!isMobile && getPlayerInfo(isMobile)}
                    </Card>
                    {isMobile && <div style={{display: 'flex', gap: '10px', padding: '10px'}}>
                     <Typography color="textSecondary">Athletes must have Premium+ account for you to see stats.</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Players who use your code in the App will automatically be added to your team list.
                      </Typography>
                      </div>}
                    {isMobile && getLowerItems(isMobile)}
                    {isMobile && <Divider style={{marginTop: '10px'}}/>}
                    <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', paddingBottom: '20px'}}>
                    <div className="guardian_container">
                      <Typography className="guardian_text" variant="h6" sx={{ fontWeight: 'bold', letterSpacing: '1px' }}>
                        GUARDIAN'S CONTACT INFORMATION
                      </Typography>
                      {startingContact.map((item:any, index) => (
                        <Card key={index} sx={{ p: isMobile ? 1 : 2, mt: 2, display: 'flex', gap: isMobile ? '5px' : 2, alignItems: 'center' }}>
                          <TextField size={isMobile ? "small" : undefined} onChange={(e:any) => handleContactUpdate(e.target.value, index, 'name')} label="Name" variant="outlined" fullWidth value={item.name ?? ''} />
                          <TextField size={isMobile ? "small" : undefined} onChange={(e:any) => handleContactUpdate(e.target.value, index, 'phoneNumber')} value={item.phoneNumber ?? ''} label={isMobile ? 'Phone' : "Phone Number"} variant="outlined" fullWidth placeholder="(000) 000-0000" />
                          <TextField size={isMobile ? "small" : undefined} onChange={(e:any) => handleContactUpdate(e.target.value, index, 'email')} value={item.email ?? ''} label="Email" variant="outlined" fullWidth placeholder="EmailAddress@email.com" />
                          <div style={{width: isMobile ? '40px' : undefined}}>
                          <IconButton onClick={() => deleteContact(index)} color="error" sx={{ ml: isMobile ? undefined : 2 }}>
                            <Delete  />
                          </IconButton>
                          </div>
                        </Card>
                      ))}
                      <Button onClick={() => setStartingContact([...startingContact, {}])} variant="text" color="primary" sx={{ mt: 2 }}>+ ADD NEW</Button>
                    </div>
                    <div style={{ padding: '10px', display: 'flex', alignItems: 'center', width: 'calc(100% - 20px)'}}>
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                      <Button size={isMobile ? 'small' : undefined} variant="contained" color="error" onClick={() => setDeletePlayerModal(true)}>{isMobile ? 'DELETE' : 'DELETE PLAYER'}</Button>
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button size={isMobile ? 'small' : undefined} disabled={isEqual(selectedPlayer?.contacts, startingContact)}  onClick={() => setSelectedPlayer(null)} variant="outlined" color="error">{isMobile ? 'DISCARD' : 'DISCARD CHANGES'}</Button>
                        <Button size={isMobile ? 'small' : undefined} disabled={isEqual(selectedPlayer?.contacts, startingContact)} onClick={() => handleSaveContacts()} variant="contained" color="primary">SAVE</Button>
                      </Box>
                    </Box>
                    </div>
                    </div>
                  </div>
                }
                {!selectedPlayer && <div className="teams-page" style={{overflowY: loading ? 'hidden' : undefined}}>
                <div className="search-sort-bar">
                  <div className="search_bar_main">
                 <div style={{display: 'flex', marginTop: isMobile ? '5px' : undefined, gap: isMobile ? '3px' : '8px', alignItems: 'center',paddingLeft: isMobile ? '10px' : '0px', paddingRight: isMobile ? '10px' : '0px',  width: '100%'}}>
                  <div className="teams_title" style={{letterSpacing: '2px',fontFamily: 'JostRegular', fontWeight: 'bold'}}>TEAMS</div>
                 {!activeTeam && isMobile && !loading && <div>-</div>}
                 {isMobile && activeTeam && <Select 
                  style={{minWidth: '150px',width: 'calc(40vw)', marginRight: '0px', marginLeft: '0px'}}
                  size="small"
                  value={activeTeam}
                  onChange={(e) => {
                    const index = teams.findIndex((team) => team.id === e.target.value);
                    setSelectedTabIndex(index);
                    setSelectedTeamID(e.target.value);
                  }}
                 >
                  {teams.map((team, index) => (
                    <MenuItem key={team.id} value={team.id}>{team.name}</MenuItem>
                  ))}
                  </Select>}
                  {isMobile && activeTeam && <FormControl>
                    <InputLabel>Difficulty</InputLabel>
                    <Select 
                    sx={{'& .MuiSvgIcon-root': {display: 'none'},
                    '& .MuiSelect-select': {paddingRight: '0px !important', paddingLeft: '0px !important'}}
                  }
                    label="Difficulty"
                  size="small"
                  style={{width: '75px', position: 'relative', left: '-5px', pointerEvents: 'none'}}
                  value={activeDifficulty}
                  readOnly
                 >
                  <MenuItem  value={"0"}>{'Youth'}</MenuItem>
                  <MenuItem  value={"1"}>{'HS'}</MenuItem>
                  <MenuItem  value={"2"}>{'College'}</MenuItem>
                  <MenuItem  value={"3"}>{'Pro'}</MenuItem>
                  </Select>
                    </FormControl>}
                 {!isMobile &&  <div className="teams_horizontal">
                  <div style={{display: 'flex', gap: '0px'}}>
                    {
                      teams.map((team, index) => (
                        <Button 
                          onClick={() => handleTabChange(undefined, index)} 
                          variant={selectedTabIndex === index ? 'contained' : 'outlined'} 
                          style={{
                            width: '200px',
                            fontFamily: 'KhandSemiBold',
                            letterSpacing: '2px',
                            fontSize: '18px',
                            borderRadius: '0px'
                            }}>
                            {team.name}
                          </Button>
                        ))
                    }
                    </div>
                  </div>}
                 { !isMobile && <Button className="button_text_teams" onClick={() => openNewTeamModal()} size={isMobile ? "small" : undefined} style={{minWidth: '150px', margin:'1px',fontFamily: 'KhandSemiBold', letterSpacing: '2px',height: '50px', fontSize: '18px'}} variant='contained'>New Team</Button>}
                  </div>
                  </div>
                  {!!selectedTeamID  && isMobile ? 
                  <div>
                    <div style={{height: isMobile ? '35px' : undefined, display: 'flex', alignSelf: 'flex-start', marginTop: '10px', gap: '5px', paddingLeft: '5px'}}>
                    <Button className="team_button" size={isMobile ? "small" : undefined} onClick={openEditModal} style={{ margin: '1px', fontFamily: 'KhandBold', letterSpacing: '1px' }}>EDIT TEAM NAME</Button>
                    <Button className="team_button" size={isMobile ? "small" : undefined} color="error" onClick={openDeleteModal} style={{ margin: '1px', marginRight: '20px', fontFamily: 'KhandBold', letterSpacing: '1px' }}>DELETE TEAM</Button>
                    <Button className="team_button" onClick={() => openNewTeamModal()} size={isMobile ? "small" : undefined} style={{width: '115px', margin:'1px',fontFamily: 'KhandSemiBold', letterSpacing: '2px'}} variant='contained'>New Team</Button>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                    {!!teamCode?.trim() &&<div className="team_code_text" style={{ flex: '1', position: 'relative', letterSpacing: '4px'}}><span style={{fontFamily: 'KhandBold'}}>TEAM CODE:</span><span style={{fontFamily: 'KhandRegular', textTransform: 'uppercase', marginLeft: '10px'}}>{teamCode}</span></div>}
                     </div>
                  </div>
                  : 
                  !!selectedTeamID  && <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
                    <div style={{display: 'flex', alignSelf: 'flex-start'}}>
                    <Button className="team_button" onClick={openEditModal} style={{ margin: '1px', fontFamily: 'KhandBold', letterSpacing: '1px' }}>EDIT TEAM NAME</Button>
                    <Button className="team_button" color="error" onClick={openDeleteModal} style={{ margin: '1px', marginRight: '20px',textAlign: 'left', fontFamily: 'KhandBold', letterSpacing: '1px' }}>DELETE TEAM</Button>
                    </div>
                    <div className="team_code_text" style={{ flex: '1', position: 'relative', letterSpacing: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                      <div className="text_team" style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                      <span style={{fontFamily: 'KhandBold'}}>TEAM CODE:</span><span style={{fontFamily: 'KhandRegular', textTransform: 'uppercase', marginLeft: '10px'}}>{teamCode}</span>
                      </div>
                    </div>
                    <FormControl>
                    <InputLabel>Difficulty</InputLabel>
                    <Select 
                    sx={{'& .MuiSvgIcon-root': {display: 'none'},
                  '& .MuiSelect-select': {paddingRight: '0px !important', paddingLeft: '0px !important'}}}
                    label="Difficulty"
                  size="small"
                  style={{width: '120px', position: 'relative', left: '-5px', pointerEvents: 'none'}}
                  value={activeDifficulty}
                  readOnly
                 >
                  <MenuItem  value={"0"}>{'Youth'}</MenuItem>
                  <MenuItem  value={"1"}>{'High School'}</MenuItem>
                  <MenuItem  value={"2"}>{'College'}</MenuItem>
                  <MenuItem  value={"3"}>{'Pro'}</MenuItem>
                  </Select>
                    </FormControl>
                  </div>
                  }
                  </div>
                  <div className="ag-theme-alpine custom-ag-grid loader-item" style={{ width: '100%', height: '100%' }}>
                  {!loading && sortedPlayers?.length > 0 &&<AgGridReact
                    ref={gridRef}
                    key={isMobile ? 'mobile-grid' : 'desktop-grid'} 
                    className={`ag-theme-alpine${isMobile ? ' mobile_grid' : ''}`}
                    columnDefs={getColumnDefs(isMobile)}
                    rowData={sortedPlayers}
                    loading={loading}
                    defaultColDef={{
                      flex: 1,
                      minWidth: 120, 
                      sortable: true,
                      resizable: true,
                      headerClass: 'ag-header-cell-label'
                    }}
                    tooltipShowDelay={0}
                    suppressDragLeaveHidesColumns={true}
                    suppressMovableColumns={true}
                    onGridReady={handleResize}
                    onColumnResized={handleResize}
                />}
                {!loading && (sortedPlayers?.length === 0 || !sortedPlayers) && 
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                {!!selectedTeamID && <div style={{marginBottom: '100px',maxWidth: '800px',alignItems: 'center', display: 'flex', flexDirection:"column", gap: '40px', fontSize: '28px', fontFamily: 'JostMedium', letterSpacing:'2px', textTransform: 'uppercase'}}>
                  <div>Welcome to {teamName}!</div>
                  <div style={{fontFamily: 'JostRegular', fontSize: '22px', textTransform: 'uppercase', letterSpacing: '2px'}}>Player accounts will show here once they have joined with your team code</div>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '400px'}}>
                    <div style={{fontFamily: 'KhandRegular', fontSize: '20px'}} >Players who use your code in the app will automatically be added to your team list.</div>
                    <div style={{display: 'flex', gap: '30px', marginTop: '10px'}}>
                      <div style={{fontFamily: 'KhandBold', fontSize: '28px', letterSpacing: '3px'}}>TEAM CODE:</div>
                      <div style={{fontSize: '28px', fontFamily: 'KhandRegular'}}>{teamCode}</div>
                    </div>
                    <div>
                    <QRCode   
                        className="qr_code_item"
                        style={{cursor: 'pointer', marginTop: '10px', width: '200px'}} 
                        onClick={() =>  window?.open?.('https://apps.apple.com/us/app/testflight/id899247664', '_blank')?.focus()} 
                        value={'https://apps.apple.com/us/app/testflight/id899247664'} 
                      />
                       <Button onClick={() => handlePrint()} size='large' startIcon={<QrCode/>} variant="contained" color="primary">DOWNLOAD QR CODE</Button>
                    </div>
                  </div>
                </div>}
                {!selectedTeamID && 
                <div>
                   <div style={{fontFamily: 'JostRegular', paddingBottom: '20px', fontSize: '22px', textTransform: 'uppercase', letterSpacing: '2px'}}>You have no teams yet!</div> 
                   <Button onClick={() => openNewTeamModal()} style={{ margin:'1px',fontFamily: 'KhandSemiBold', letterSpacing: '2px', fontSize: '18px'}} variant='contained'>Create New Team</Button>
                </div>}
                </div>
                }
                  </div>
                </div>}
      </>
      <Modal open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
  <Box sx={modalStyle}>
    <Typography variant="h5" component="h2" style={{fontFamily: 'JostBold'}}>Delete Team</Typography>
    <Typography variant="body1">
      Are you sure you want to delete this team permanently? All players will be removed from the team.
    </Typography>
    <Typography variant="body1">
      Type <span style={{fontWeight: 'bold'}}>{teamName}</span> to confirm. This action cannot be undone.
    </Typography>
    <TextField
      autoFocus
      margin="dense"
      label="Team Name"
      type="text"
      fullWidth
      variant="outlined"
      value={deleteTextFieldName}
      onChange={(e) => setDeleteTextFieldName(e.target.value)}
    />
    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
      <Button onClick={() => setIsDeleteModalOpen(false)} color="primary">Cancel</Button>
      <Button disabled ={teamName?.toLowerCase() !== deleteTextFieldName?.toLocaleLowerCase()} onClick={handleDeleteTeam} color="error" variant="contained">Delete</Button>
    </Box>
  </Box>
</Modal>
<Modal open={deletePlayerModal} onClose={() => setDeletePlayerModal(false)}>
  <Box sx={modalStyle}>
    <Typography variant="h5" component="h2" style={{fontFamily: 'JostBold'}}>Delete Player</Typography>
    <Typography variant="body1">
      Are you sure you want to permanently remove this player from your team?
    </Typography>
    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
      <Button onClick={() => setDeletePlayerModal(false)} color="primary">Cancel</Button>
      <Button  onClick={handleDeletePlayer} color="error" variant="contained">Delete</Button>
    </Box>
  </Box>
</Modal>
<Modal open={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
  <Box sx={modalStyle}>
    <Typography className="button_text_teams" variant={"h5"} component="h2" style={{fontFamily: 'JostBold'}}>Edit Team Name</Typography>
    <TextField
      autoFocus
      margin="dense"
      label="Team Name"
      type="text"
      fullWidth
      variant="outlined"
      value={editTeamName}
      onChange={(e) => setEditTeamName(e.target.value)}
    />
    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
      <Button onClick={() => setIsEditModalOpen(false)} color="primary">Cancel</Button>
      <Button onClick={handleSaveTeamName} color="primary" variant="contained">Save</Button>
    </Box>
  </Box>
</Modal>
<Modal open={newTeamModal} onClose={() => setNewTeamModal(false)}>
  <Box sx={modalStyle}>
    <Typography className="button_text_teams" variant="h5" component="h2" style={{fontFamily: 'JostBold'}}>Create New Team</Typography>
    <TextField
      autoFocus
      margin="dense"
      label="Team Name"
      type="text"
      fullWidth
      variant="outlined"
      value={newTeamName}
      onChange={(e) => setNewTeamName(e.target.value)}
    />
     <FormControl
      >
        <InputLabel
          shrink
        >
          Team Difficulty
        </InputLabel>
     <Select
          style={{ width: '100%' }} 
          label="Team Difficulty"
          value={newDifficulty}
          onChange={(e) => setNewDifficulty(e.target.value)}
        >
          <MenuItem value="0">Youth</MenuItem>
          <MenuItem value="1">High School</MenuItem>
          <MenuItem value="2">College</MenuItem>
          <MenuItem value="3">Pro</MenuItem>
      </Select>
    </FormControl>
    <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
      <Button onClick={() => setNewTeamModal(false)} color="primary">Cancel</Button>
      <Button disabled={!newTeamName?.trim()} onClick={handleSaveNewTeam} color="primary" variant="contained">Create</Button>
    </Box>
  </Box>
</Modal>
    </div>
  );
};

export default TeamsPage;
