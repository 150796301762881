import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Link, Snackbar, Alert, Slide, IconButton, InputAdornment, SnackbarCloseReason, CircularProgress, Tooltip } from '@mui/material';
import { ArrowBack, Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.css';
import { useCoachRequestForgotPasswordMutation, useCoachSetNewPasswordMutation, useCoachSignInMutation, useGetSelfLazyQuery } from '../../services/omahaApi/__generated__/graphql';
import { SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

// Slide transition function
function SlideTransition(props: any) {
  return <Slide {...props} direction="up" />;
}

const LoginPage = () => {
  const [signIn, { loading: signInLoading }] = useCoachSignInMutation();
  const [coachRequestForgotPassword, { loading: forgotLoading }] = useCoachRequestForgotPasswordMutation();
  const [coachSetNewPassword, { loading: newLoading }] = useCoachSetNewPasswordMutation();
  const [getSelf, { data: selfData, loading: selfLoading, error: selfError }] = useGetSelfLazyQuery();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [codeScreen, setCodeScreen] = useState(false);
  const [sentEmail, setSentEmail] = useState('');
  const loading = signInLoading || forgotLoading || newLoading;
  const navigate = useNavigate();
  const typeRef = React.useRef('error');

  // State for managing Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // State for password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  // Validation schema for form
  const validationSchema = Yup.object().shape({
    code_input: (!codeScreen) ? Yup.string().optional() : Yup.string()
    .min(6, 'Code must be at least 6 characters long')
    .required('Code is required'),
    email: codeScreen ? Yup.string().optional() : Yup.string()
      .email('Invalid email format')
      .matches(/^[\w\-\.]+(\+[\w\-\.]+)?@([\w\-]+\.)+[\w\-]{2,4}$/, 'Enter a valid email')
      .required('Email is required'),
    password: (forgotPassword && !codeScreen) ? Yup.string().optional() : Yup.string()
      .min(6, 'Password must be at least 6 characters long')
      .required('Password is required'),
    confirmPassword: (!codeScreen) ? Yup.string().optional() : Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match') 
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      code_input: ''
    },
    validationSchema,
    onSubmit: (values) => {
      if(forgotPassword) {
        if(codeScreen) {
          if(values.code_input && values.password && values.confirmPassword) {
            setNewPassword(values.code_input, values.password, values.confirmPassword);
          }
        } else {
          if(values.email) {
            requestForgotPassword(values.email);
          }
        }
      } else {
        if (values.email && values.password) {
          coachSignIn(values.email, values.password);
        }
      }
     
    },
  });

  const setNewPassword = async (code: string, password: string, confirmPassword: string) => {
    try {
      const result = await coachSetNewPassword({
        variables: {
          email: sentEmail,
          code,
          password
        },
      });
      if(result.data?.coachSetNewPassword?.success) {
        typeRef.current = 'success';
        setErrorMessage('Password changed successfully');
        setSnackbarOpen(true);
        formik.resetForm({
          values: { email: '', code_input: '', password: '', confirmPassword: '' }, 
        });
        formik.setTouched({ email: false, code_input: false, password: false, confirmPassword: false });
        setCodeScreen(false);
        setForgotPassword(false);
      } else {
        typeRef.current = 'error';
        setErrorMessage(result?.data?.coachSetNewPassword?.message || 'Failed to change password');
        setSnackbarOpen(true);
      }
    } catch(error: any) {
      console.error(error);
      typeRef.current = 'error';
      setErrorMessage(error?.message || 'Failed to change password');
      setSnackbarOpen(true);
    }
  }

  const requestForgotPassword = async (email: string) => {
    try {
      const result = await coachRequestForgotPassword({
        variables: {
          email,
        },
      });
      if(result.data?.coachRequestForgotPassword?.success) {
        setSentEmail(email);
        setErrorMessage('Password reset code sent to your email');
        typeRef.current = 'success';
        setSnackbarOpen(true); 
        formik.resetForm({
          values: { email, code_input: '', password: '', confirmPassword: '' }, 
        });
        // setTimeout(() => {
        //   formik.setValues({ email: '', code: '', password: '', confirmPassword: '' });
        // }, 25)
        formik.setTouched({ email: false, code_input: false, password: false, confirmPassword: false });
        setCodeScreen(true);
      } else {
        setErrorMessage(result?.data?.coachRequestForgotPassword?.message || 'Failed to request password reset');
        typeRef.current = 'error';
        setSnackbarOpen(true); 
      }
     
    } catch(error:any) {
      console.error(error);
      typeRef.current = 'error';
      setErrorMessage(error?.message || 'Failed to request password reset');
      setSnackbarOpen(true); 
    }
  }

  const coachSignIn = async (email: string, password: string) => {
    try {
      const res = await signIn({
        variables: {
          email,
          password,
        },
      });
      window.localStorage.setItem('user', JSON.stringify(res.data?.coachSignIn ?? '{}'));
      window.location.href = '/';
    } catch (error: any) {
      console.error(error);
      typeRef.current = 'error';
      setErrorMessage(error.message || 'Failed to sign in');
      setSnackbarOpen(true); // Open the Snackbar with the error message
    }
  };

  // Snackbar onClose handler with two parameters (event and reason)
  const handleSnackbarClose = (
    event: Event | SyntheticEvent<any, Event>, // Allow both Event and SyntheticEvent
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  // Alert onClose handler
  const handleAlertClose = (event: SyntheticEvent<any, Event>) => {
    setSnackbarOpen(false);
  };

  // Auto-login using the getSelf query if token exists
  useEffect(() => {
    const storedUser = JSON.parse(window.localStorage.getItem('user') || '{}');
    const accessToken = storedUser?.accessToken;
    if (accessToken) {
      getSelf(); // Trigger getSelf query
    }
  }, [getSelf]);

  // If self query succeeds, redirect to homepage
  useEffect(() => {
    if (selfData && selfData.adminSelf) {
      navigate('/');
    }
  }, [selfData, navigate]);

  // Handle query failure
  useEffect(() => {
    if (selfError) {
      console.error('Auto login failed:', selfError);
    }
  }, [selfError]);

  return (
    <Box className="login_container">
      <Box
        sx={{
          width: '400px',
          maxWidth: 'calc(95% - 40px)',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px'}}>
        {forgotPassword && <Tooltip placement='top' title="Back to Login"><IconButton size='small' onClick={() => {
          setForgotPassword(false)
          setCodeScreen(false)
          formik.setValues({ email: '', password: '', confirmPassword: '', code_input: '' });
          formik.setTouched({ email: false, password: false });
          setShowPassword(false)
        }}>
          <ArrowBack />
        </IconButton>
        </Tooltip>
      }
        <Typography className="login_header_item" variant="h4" sx={{  fontWeight: 500 }}>
          {forgotPassword ? 'Forgot Password' : 'Account Log In'}
        </Typography>
        {forgotPassword && <div style={{width: '40px'}}></div>}
        </div>
        <Box component="form" sx={{ mt: 2 }} onSubmit={formik.handleSubmit}>
          {codeScreen && <>
            <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            Code
          </Typography>
          <TextField
            fullWidth
            id="code_input"
            autoComplete='code_input'
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            name="code_input"
            variant="outlined"
            placeholder="enter code"
            value={formik.values.code_input}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.code_input && Boolean(formik.errors.code_input)}
            helperText={formik.touched.code_input && formik.errors.code_input}
            sx={{ mb: 3 }}
          />
          </>}
          {!codeScreen && 
          <><Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            Email
          </Typography>
          <TextField
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            placeholder="enter email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            autoComplete='email'
            sx={{ mb: 3 }}
          /></>}
         {(!forgotPassword || codeScreen) && <> <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            New Password
          </Typography>
          <TextField
            fullWidth
            id="password"
            inputProps={codeScreen ? {
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            } : undefined}
            name="password"
            autoComplete={codeScreen ? 'off' : 'password'}
            variant="outlined"
            type={showPassword ? 'text' : 'password'} // Toggle between text and password
            placeholder="******"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            sx={{ mb: 4 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          /> </>}
           {(codeScreen) && <> <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
            Confirm Password
          </Typography>
          <TextField
            fullWidth
            id="confirmPassword"
            autoComplete={'off'}
            name="confirmPassword"
            variant="outlined"
            type={showPassword ? 'text' : 'password'} // Toggle between text and password
            placeholder="******"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
            helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
            sx={{ mb: 4 }}
          /> </>}
          <Button
            fullWidth
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || selfLoading} // Disable the button when loading
            sx={{
              height: '50px',
              fontSize: '18px',
              backgroundColor: '#1e73be',
              '&:hover': { backgroundColor: '#155a8f' },
            }}
          >
            {loading || selfLoading ? <CircularProgress size={24} /> : forgotPassword ? codeScreen ? 'Reset Password' : 'Request Code' : 'LOG IN'}
          </Button>
          {!forgotPassword && <Box sx={{ marginTop: '16px' }}>
            <Link href="#" variant="body2" sx={{ color: '#1e73be' }} onClick={() => {
              setForgotPassword(true)
              formik.setFieldValue('password', '');
              setShowPassword(false)
              }}>
              Forgot Password?
            </Link>
          </Box>}
        </Box>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        TransitionComponent={SlideTransition}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {/* @ts-ignore */}
        <Alert onClose={handleAlertClose} severity={typeRef.current ?? 'error'} sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
