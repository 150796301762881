import React, { act, ReactNode, useEffect, useState } from 'react';
import { Tabs, Tab, Box, Typography, Drawer, IconButton, Button, Select, MenuItem, ThemeProvider, createTheme, InputLabel, FormControl, TextField, InputAdornment, Tooltip } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, ArrowBack, Refresh, Search, ArrowDownward, ArrowUpward, ExitToApp, InfoOutlined, Close } from '@mui/icons-material';
import { ParentSize } from '@visx/responsive';
import { useSpring, animated } from 'react-spring';
import './styles.css';
import { StatPlayType } from '../../helper';

const stats = [
    { abbreviation: "CSS", description: "Crucible Sim Score is our patent-pending tabulation of decisions you made within 8 attempts (ATT) per Game (Gms). This is an average score that is weighted, with your last 15 Games having a greater value than 16 and on." },
    { abbreviation: "Gms", description: "Number of games in which the Quarterback initiated. A Game consists of 8 attempts (ATT)." },
    { abbreviation: "COMM", description: "Cumulative completed passes per attempt (ATT). Measured over a time period." },
    { abbreviation: "ATT", description: "Attempts. Measured over a time period." },
    { abbreviation: "COM%", description: "Completions in Percentage format thrown over Attempts. They can be Optimal throws, Late throws, or Checkdowns. Measured over a time period." },
    { abbreviation: "INT", description: "Cumulative Interceptions. Measured over a time period." },
    { abbreviation: "INT%", description: "Percent of Interceptions thrown over Attempts (ATT). Measured over a time period." },
    { abbreviation: "SK", description: "Cumulative Sacks. Measured over a time period." },
    { abbreviation: "SK%", description: "Sack Percentages over Attempts (ATT). Measured over a time period." },
    { abbreviation: "OptT", description: "Cumulative Optimal Throws. An Optimal Throw means it went to the correct receiver in that play vs defense coverage. Measured over a time period." },
    { abbreviation: "OptT%", description: "Optimal throw percentage vs Attempts (ATT). Measured over a time period." },
    { abbreviation: "LtT", description: "Late throws. Although the pass was to the correct receiver in that play vs defense scenario, the pass was late and outside of the Optimal time frame." },
    { abbreviation: "LtTT%", description: "Late throw Percentage. Late throws over Attempts. Measured over a time period." },
    { abbreviation: "INC", description: "Incomplete passes." },
    { abbreviation: "INC%", description: "Incomplete pass Percentage. Incomplete passes over total attempts. Measured over a time period." },
    { abbreviation: "CkDn", description: "Checkdown throw." },
    { abbreviation: "CkDn%", description: "Checkdown throw Percentage vs Attempts. Measured over a time period." },
    { abbreviation: "Strks", description: "Streaks. The number of Plays where the pass was caught. (Deprecated soon)." }
  ];

interface PageContainerProps {
  children: ReactNode;
  timeframe: string;
  setTimeframe: any;
  activeTab?: number;
  setActiveTab?: any;
  onTeamPage?:boolean;
  difficulty: number,
  setDifficulty: any,
  sortDirection?: string;
  handleSortDirectionChange?: any;
  sortField?: any;
  searchTerm?: any;
  handleSearchChange?:any;
  handleSortChange?:any
  disabledTimeframe?:any
  setDisabledTimeframe?:any
}

const PageContainer: React.FC<PageContainerProps> = ({ 
  onTeamPage, 
  children, 
  timeframe, 
  setTimeframe, 
  difficulty,
  setDifficulty,
  activeTab, 
  setActiveTab,
  sortDirection,
  handleSortDirectionChange,
  sortField,
  searchTerm,
  handleSearchChange,
  handleSortChange,
  disabledTimeframe,
  setDisabledTimeframe
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);  
  const [rerenderChild, setRerenderChild] = useState(false);
  const [infoPage, setInfoPage] = useState(false);
  const statPlayTypes = Object.values(StatPlayType);

  const slideInStyle = useSpring({
    transform: infoPage ? 'translateX(0%)' : 'translateX(100%)',
    opacity: infoPage ? 1 : 0,
    config: { tension: 250, friction: 25 }, // Smooth animation
  });

  React.useEffect(() => {
    setTimeout(() => {
      setRerenderChild(false)
    })
  }, [rerenderChild]);

  const handleChange = (event: any, newValue: any) => {
    if(newValue === activeTab ) {
      return
    }
    setActiveTab(newValue);
    if(isDrawerOpen) {
      setIsDrawerOpen(false)
    }
  };

  const tabLabels = ['Team Summary', 'Main Stats'];

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const isFromTeam = JSON.parse(window.localStorage.getItem('navigatedFromTeam') ?? '{}');

  useEffect(() => {
    if(location.pathname?.includes('player') && timeframe === StatPlayType.NO_CSS) {
      setTimeframe(StatPlayType.ALL_TIME)
    } else if (disabledTimeframe) {
      setDisabledTimeframe(false)
    }
  }, [location.pathname?.includes('player') ])
                    
  const logout = () => {
    window.localStorage.removeItem('user');
    navigate('/login')
  }

  const getPageHeight = (isMobile:any, mobileView:any) => {
    if(isMobile) {
      if(activeTab === 1 || location.pathname?.includes('player')) {
        return 'calc(100dvh - 105px)'
      } else {
        return 'calc(100dvh - 50px)'
      }
    } else {
      return `calc(100dvh - ${80 - (mobileView ? 30 : 0)}px)`
  }
}

const getPadding = (isMobile:any) => {
  if(isMobile) {
   return '50px'
  } else {
     return  '80px'
  }
}

  const getHeaderContenat = (isMobile: any, width: any) => {
    if(activeTab === 0 && !location.pathname?.includes('player')) {
      return null
    }
    const isSuperSmallMobile = width < 440
    const difficulties = [
      {key: 'Youth', value: 0},
      {key: isSuperSmallMobile ? 'HS' : 'High School', value: 1},
      {key: 'College', value: 2},
      {key: 'Pro', value: 3},
    ]
    const onTeamPage = activeTab === 0 && !location.pathname?.includes('player')
    return(<ThemeProvider theme={darkTheme}>
            {infoPage && <animated.div style={slideInStyle} className="info_page">
              <div style={{width: '100%', display: 'flex', justifyContent: 'flex-start',flexDirection: 'column', alignItems: 'flex-end'}}>
                <Tooltip placement="bottom" title="Close Stat Info">
                <IconButton size="small" onClick={() => setInfoPage(false)}>
                    <Close fontSize={isMobile ? 'small' : undefined}  style={{color: 'black'}} />
                </IconButton>
                </Tooltip>
                <div className="stat-table-container">
                  <table className="stat-table">
                    <thead>
                      <tr>
                        <th>{isMobile ? 'Abr' : 'Abbreviation'}</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stats.map((stat, index) => (
                        <tr key={index}>
                          <td>{stat.abbreviation}</td>
                          <td>{stat.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              </animated.div>}
              <div style={{display: 'flex',marginLeft: '5px', gap: isMobile ? '1px' : '10px', left: isMobile ? '0px' : '-20px', position: 'relative', top: isMobile ? '0px' : undefined}}>
               {((location.pathname === '/' && activeTab === 1) || location.pathname?.includes('player')) ?
               <Tooltip placement="bottom" title="Stat Info">
               <IconButton onClick={() => setInfoPage(!infoPage)}>
                <InfoOutlined />
                </IconButton>
                </Tooltip>
                : null}
               {(location.pathname === '/' || location.pathname?.includes('player')) ? 
               <div style={{position: 'relative', top: isMobile ? location.pathname?.includes('player') ? '4px' : '8px' : '0px'}}>
               <ThemeProvider theme={darkTheme}>
                <div style={{display: 'flex', gap: '5px'}}>
               <FormControl >
                  <InputLabel style={{fontSize: isMobile ? '13px' : undefined}} id="demo-simple-select-label">Difficulty</InputLabel>
                  <Select 
                  sx={{
                    '& .MuiSvgIcon-root': {
                      display: isSuperSmallMobile ? 'none' : undefined
                    },
                    '& .MuiInputBase-input': {
                      paddingRight: isSuperSmallMobile ? '0px !important' : undefined,
                      paddingLeft: isSuperSmallMobile ? '0px !important' : undefined
                    }
                  }}
                  style={{height: isMobile ? '30px' : '36.5px',width: isMobile ? isSuperSmallMobile ? '80px' :  '125px' : undefined, fontSize:   isMobile ? '13px' : undefined}} labelId='demo-simple-select-label' label='Time Frame' size="small" value={difficulty} onChange={(e:any) => {
                    setDifficulty(e.target.value);
                    window.localStorage.setItem('difficulty', e.target.value);
                  }}>
                 {difficulties.map((type) => (
                  <MenuItem key={type.key} value={type.value}>
                    {type.key}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>
              {!disabledTimeframe &&  <FormControl >
                  <InputLabel style={{fontSize: isMobile ? '13px' : undefined}} id="demo-simple-select-label">Time Frame</InputLabel>
                  <Select
                   sx={{
                    '& .MuiSvgIcon-root': {
                      display: isSuperSmallMobile ? 'none' : undefined
                    },
                    '& .MuiInputBase-input': {
                      paddingRight: isSuperSmallMobile ? '0px !important' : undefined,
                      paddingLeft: isSuperSmallMobile ? '0px !important' : undefined
                    }
                  }}
                   style={{height: isMobile ? '30px' : '36.5px',width: isMobile ? isSuperSmallMobile ? '80px' : '95px' : undefined, fontSize:   isMobile ? '13px' : undefined}} labelId='demo-simple-select-label' label='Time Frame' size="small" value={timeframe} onChange={(e:any) => {
                    setTimeframe(e.target.value);
                    window.localStorage.setItem('timeframe', e.target.value);
                  }}>
                 {statPlayTypes?.filter((e:StatPlayType) => {
                  if(location.pathname?.includes('player') ) {
                    return e !== StatPlayType.NO_CSS
                  } else {
                    return true
                  }
                 }).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
                </Select>
                </FormControl>}
                </div>
                </ThemeProvider>
                </div> : null}
              {location.pathname?.includes('player') ? (
                 width < 1000 ? <IconButton
                  size={isMobile ? 'small' : undefined}
                  style={{ top: '2px', }}
                  color="info"
                  onClick={() => {
                    navigate('/')
                    if(onTeamPage && isFromTeam?.teamID) {
                      setActiveTab(0)
                    } else {
                      setActiveTab(1)
                    }
                  }}
                >
                  <ArrowBack style={{color: 'white'}}/>
                </IconButton>
                : 
                <Button
                  className="back-button"
                  variant="contained"
                  color="info"
                  onClick={() => {
                    navigate('/')
                    if(onTeamPage && isFromTeam?.teamID) {
                      setActiveTab(0)
                    } else {
                      setActiveTab(1)
                    }
                  }}
                  startIcon={<ArrowBack style={{ position: 'relative', top: '-2px' }} />}
                >
                 {onTeamPage && isFromTeam?.teamID ? `Back to ${isFromTeam?.teamName}`: ' Back To All Players'}
                </Button>

              ) : location.pathname === '/' ? (width < 1000  ? (
                <IconButton
                  style={{ top: '2px',}}
                  color="info"
                  onClick={() => setRerenderChild(true)}
                >
                  <Refresh fontSize={isMobile ? 'small' : undefined} />
                </IconButton>
              ) : (
                <Button
                  className="back-button"
                  variant="contained"
                  color="info"
                  onClick={() => setRerenderChild(true)}
                  startIcon={<Refresh style={{ position: 'relative', top: '-2px' }} />}
                >
               { 'Refresh'}
                </Button>
              )) : location?.pathname?.includes('teams') ? (
                  <div style={{ marginRight: '10px', display: 'flex', gap: '10px' }}>
                  <TextField
                    variant="outlined"
                    style={{ width: isMobile ? '100px' : undefined, padding: isMobile ? '0px' : undefined }}
                    size={isMobile ? 'small' : 'medium'}
                    sx={{
                      padding: isMobile ? '0px' : undefined,
                      '& .MuiInputBase-root': {
                        paddingLeft: isMobile ? '5px' : undefined,
                      }
                    }}
                    placeholder={isMobile ? "Search" : "Search Players"}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search fontSize={isMobile ? 'small' : undefined} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ display: 'flex' }}>
                    <FormControl size={isMobile ? 'small' : 'medium'}>
                      <InputLabel id="sort-by">Sort By</InputLabel>
                      <Select
                        size={isMobile ? 'small' : 'medium'}
                        label="Sort By"
                        labelId="sort-by"
                        value={sortField}
                        onChange={handleSortChange}
                        displayEmpty
                        variant="outlined"
                      >
                        <MenuItem value="" disabled>
                          Sort by
                        </MenuItem>
                        <MenuItem value="playerName">Name</MenuItem>
                        <MenuItem value="playerNumber">Number</MenuItem>
                        <MenuItem value="playerPosition">Position</MenuItem>
                        <MenuItem value="css">CSS</MenuItem>
                        <MenuItem value="lastLogin">{isMobile ? 'Active' : 'Last Played'}</MenuItem>
                        <MenuItem value="int">INT</MenuItem>
                        <MenuItem value="gamesPlayed"># Played</MenuItem>
                        <MenuItem value="gamesQuit"># Quit</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                    className="sort_button"
                      sx={{
                        minWidth: '0px',
                        padding: '0px', 
                        borderColor: 'rgba(255,255,255,.25)', 
                        color: 'white', 
                        '&:hover': {
                          borderColor: 'white',
                          backgroundColor: 'rgba(255, 255, 255, 0.08)'
                        }
                      }}
                      variant='outlined'
                      onClick={handleSortDirectionChange}
                    >
                      {sortDirection === 'asc' ? (
                        <ArrowDownward fontSize={isMobile ? 'small' : 'medium'} />
                      ) : (
                        <ArrowUpward fontSize={isMobile ? 'small' : 'medium'} />
                      )}
                    </Button>
                  </div>
                </div>
              ) : null}
              </div>
            </ThemeProvider>)
  }
  return (
    <ParentSize>
      {({ width }) => {
        const onTeamPage = activeTab === 0 && !location.pathname?.includes('player')
        const isMobile = width < 1000;
        const changeHeader = (onTeamPage) ? false : width < 550
        const isSecondBar = false
        return (
          <div>
            <div className="top_page_banner">
              <div style={{display: 'flex', gap: '5px'}}>
              {isMobile && !window.location.href?.includes('login') && (
                <IconButton size={isMobile ? 'small' : undefined} onClick={handleDrawerToggle} style={{ color: 'white', marginLeft: 5 }}>
                  <Menu fontSize={isMobile ? 'small' : undefined}/>
                </IconButton>
              )}
              <div style={{
                display: 'flex',
                 gap: changeHeader ? undefined : '20px', 
                 alignItems: changeHeader ? 'flex-start' : 'center', 
                 flexDirection: changeHeader ? 'column' : 'row',
                 marginLeft: (onTeamPage && width < 500) ? '10px' : undefined,
                 }}>
                <img
                  onClick={() => !window.location.href?.includes('login') ? navigate('/') : undefined}
                  src={`${process.env.PUBLIC_URL}/Crucible_QB_logo_white.png`}
                  alt="Logo"
                  className="main_logo"
                  style={{paddingLeft: !isMobile ? '20px' : undefined, height:  (onTeamPage  && width < 500) ? '29px' : undefined}}
                />
                <div 
                  className="coach_text" 
                  style={{
                      fontFamily: 'JostRegular', 
                      color: 'white',
                      textTransform: 'uppercase', 
                      position: 'relative',
                      fontSize: (onTeamPage  && width < 500) ? '15px' : undefined,
                      }}>
                  Coach Portal
                </div>
              </div>
              </div>
              {!isSecondBar && getHeaderContenat(isMobile, width)}
            </div>
            {isSecondBar && !location.pathname?.includes('login') && (!onTeamPage) && <div style={{position: 'relative',alignItems: 'center', top: '40px', height: '65px',display: 'flex', width: '100%', justifyContent: 'flex-end', backgroundColor: '#262626'}}>
            <div style={{position: 'relative', top: '5px'}}>
             {getHeaderContenat(isMobile, width)}
            </div>
            </div>}
            <div style={{paddingTop: getPadding( isMobile)}}>
              <div style={{height: getPageHeight(isSecondBar, isMobile)}} className={"main_page_container"}>
                {(!isMobile && !window.location.href?.includes('login')) && (
                  <div className="side_panel">
                    <Tabs
                      orientation="vertical"
                      value={activeTab}
                      onChange={handleChange}
                      TabIndicatorProps={{ style: { display: 'none' } }}
                      className="tabs_selector"
                      sx={{
                        '& .MuiTab-root': {
                          color: 'white',
                          fontSize: '24px',
                          textTransform: 'none',
                        },
                        '& .Mui-selected': {
                          backgroundColor: 'red',
                          color: 'white',
                        },
                        '& .Mui-disabled': {
                          color: 'grey',
                        },
                      }}
                    >
                      {tabLabels.map((label, index) => (
                        <Tab key={index} label={label} disabled={index > 1} 
                        onClick={() => {
                          if(location.pathname?.includes('player')) {
                            navigate('/')
                          } 
                          setActiveTab(index)
                        }}/>
                      ))}
                    </Tabs>
                    <ThemeProvider theme={darkTheme}>
                      <Button endIcon={<ExitToApp/>} variant='outlined' onClick={logout}>Logout</Button>
                    </ThemeProvider>
                  </div>
                )}
                {!rerenderChild && children}
              </div>
            </div>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerToggle}>
              <Box
                sx={{
                  width: 220,
                  backgroundColor: '#1A283A',
                  height: '100%',
                  display: 'flex',
                  paddingTop: '20px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                role="presentation"
              >
                <Tabs
                  orientation="vertical"
                  value={activeTab}
                  onChange={handleChange}
                  className='drawer-tabs tabs_selector'
                  TabIndicatorProps={{ style: { display: 'none' } }}
                  sx={{
                    '& .MuiTab-root': {
                      color: 'white',
                      fontSize: '20px',
                      textTransform: 'none',
                    },
                    '& .Mui-selected': {
                      backgroundColor: 'red',
                      color: 'white',
                    },
                    '& .Mui-disabled': {
                      color: 'grey',
                    },
                  }}
                >
                  {tabLabels.map((label, index) => (
                    <Tab 
                    key={index} 
                    label={label} 
                    disabled={index > 1} 
                    onClick={() => {
                      if(location.pathname?.includes('player')) {
                        setIsDrawerOpen(false)
                        navigate('/')
                      } 
                      setActiveTab(index)
                    }}
                    />
                  ))}
                </Tabs>
                <ThemeProvider theme={darkTheme}>
                    <Button fullWidth endIcon={<ExitToApp/>} variant='outlined' onClick={() => {
                      setIsDrawerOpen(false)
                      logout()
                    }
                      }>Logout</Button>
                  </ThemeProvider>
              </Box>
            </Drawer>
          </div>
        );
      }}
    </ParentSize>
  );
};

export default PageContainer;
